import {
  Card,
  CardBody,
  Col,
  Container,
  Button,
  NavLink,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  CardHeader,
  UncontrolledTooltip,
  UncontrolledDropdown,
  DropdownToggle,
  Nav,
  NavItem,
  Alert,
  DropdownMenu,
  Badge,
  Table,
  DropdownItem,
} from "reactstrap";
import React, { useEffect, useState, useCallback, useMemo } from "react";
import logoPurple from "../../../assets/images/logopurple.png";
import PropTypes from "prop-types";

export const Header = ({ toggle }) => {
  const businessLink = "https://getrostered.app/business";
  const workforceLink = "https://getrostered.app/workforce";
  const signupLink = "https://business.getrostered.app";
  return (
    <div className="card-header container justify-content-between align-items-center p-2 d-flex">
      <img
        src={logoPurple}
        alt=""
        className="text-center align-items-center "
        height={50}
      />
      <Nav tabs className="nav ps-5  nav-tabs-custom rounded  border-bottom-0">
        <NavItem>
          <NavLink
            style={{ cursor: "pointer", fontSize: "18px" }}
            className=""
            onClick={() => window.open(workforceLink, "_blank")}
          >
            Workforce
          </NavLink>
        </NavItem>
        <NavItem>
          <Button className="btn btn-pink btn-lg pt-2" onClick={toggle}>
            Join Free
          </Button>
        </NavItem>
        <NavItem>
          <NavLink
            className=""
            style={{ cursor: "pointer", fontSize: "18px" }}
            onClick={() => window.open(businessLink, "_blank")}
          >
            Business
          </NavLink>
        </NavItem>
        <NavItem>
          <Button
            className="btn btn-primary btn-lg pt-2"
            onClick={() => window.open(signupLink, "_blank")}
          >
            Sign up
          </Button>
        </NavItem>
      </Nav>
    </div>
  );
};
Header.propTypes = {
  toggle: PropTypes.func,
};
