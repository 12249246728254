import { displayName } from "cleave.js/react";

export const SettingsTabPages = {
    Profile: {
        id: 1,
        name:"profile",
        displayText: 'Profile',
        iconClass: 'bx bxs-user-rectangle'
    },
    SystemUsers: {
        id: 2,
        name:"system_users",
        displayText: 'System Users',
        iconClass: 'user'
    },
    Roles: {
        id: 3,
        name:"roles",
        displayText: 'Roles',
        iconClass: 'bx bx-list-ol'
    },
    Permissions: {
        id: 4,
        name:"permissions",
        displayText: 'Permissions',
        iconClass: 'cog'
    },
    General: {
        id: 5,
        name:"general",
        displayText: 'General',
        iconClass: 'cog'
    },
    Sites: {
        id: 6,
        name:"sites",
        displayText: 'Sites',
        iconClass: 'buildings'
    },
    Workorder:{
        id: 7,
        name:"work_order",
        displayText:'Work Order Template Groups',
        iconClass: 'file'
    },
    WorkorderTemplates:{
        id: 8,
        name:"work_order_templates",
        displayText:'Work Order Templates',
        iconClass: 'file'
    },
    Roster: {
        id: 9,
        name:"roster",
        displayText: 'Roster',
        iconClass: 'chevron-right'
    },
    Timesheets: {
        id: 10,
        name:"time_sheets",
        displayText: 'Timesheets',
        iconClass: 'file'
    },
    Leave: {
        id: 11,
        name:"leave",
        displayText: 'Leave',
        iconClass: 'user'
    },
    Notifications: {
        id: 12,
        name:"notifications",
        displayText: 'Notifications',
        iconClass: 'bx bxs-notification'
    },
    Xero: {
        id: 13,
        name:"Xero",
        displayText: 'Connect Xero',
        iconClass: 'chevron-right'
    },
    InstantPayout: {
        id: 14,
        name:"instant_payout",
        displayText: 'Instant Payout',
        iconClass: 'chevron-right'
    },

   
   
}

