import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Accordion,
  AccordionItem,
  Collapse,
  Label,
  Input,
  Button,
  ButtonGroup,
  PopoverBody,
  UncontrolledPopover,
  Popover,
  Table,
  Nav,
  NavItem,
  NavLink,
  TabPane,
  TabContent,
} from "reactstrap";
import classnames from "classnames";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import React, {
  useState,
  useRef,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import { get_cookie } from "../../../helpers/get_cookie";
import moment from "moment";
import axios from "axios";
import Select from "react-select";
import { toast, ToastContainer } from "react-toastify";
import userDummy from "../../../assets/images/users/user-dummy-img.jpg";
import Loader from "../../../Components/Common/Loader";
import Flatpickr from "react-flatpickr";
import { Link } from "react-router-dom";
import Cleave from "cleave.js/react";
import img13 from "../../../assets/images/small/userimage.png";
import img14 from "../../../assets/images/small/Frame.png";

const ApproveTimesheets = () => {
  document.title = "Approve Timesheets | Get Rostered";
  const authUser_id = JSON.parse(get_cookie("workauthUser")).user_id;
  const business_id = JSON.parse(get_cookie("authUser")).business_id;
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [fullname, setfullname] = useState("");
  const [userimageurl, setuserimageurl] = useState([]);
  const [workerName, setWorkerName] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [view, setView] = useState("day");
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [toTime, setToTime] = useState("");
  const [toTimeError, setToTimeError] = useState("");
  const [fromTimeError, setFromTimeError] = useState("");
  const [fromTime, setFromTime] = useState("");
  const [zoneId, setZoneId] = useState("");
  const [zoneIdError, setZoneIdError] = useState("");
  const [zones, setZones] = useState([]);
  const [close_date_error, setCloseDateError] = useState("");
  const [close_date, setCloseDate] = useState(new Date());
  const [timeFormat, setTimeFormat] = useState("");
  const [memberId, setMemberId] = useState("");
  const [memberIdError, setMemberIdError] = useState("");
  const user_id = JSON.parse(get_cookie("authUser")).user_id;
  const [firstLetter, setFirstLetter] = useState("");
  const [shiftMembers, setShiftMembers] = useState([]);
  const [siteId, setSiteId] = useState({ value: "all", label: "All sites" });
  const [siteIdError, setSiteIdError] = useState("");
  const [sites, setSites] = useState([]);
  const [timesheetData, setTimesheetData] = useState([]);
  const [status, setStatus] = useState("");
  const [timesheetId, setTimesheetId] = useState("");
  const [site_id, set_site_id] = useState("");
  const [shiftId, setShiftId] = useState("");
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [approvalStatus, setApprovalStatus] = useState("");
  const [progressStatus, setProgressStatus] = useState("");
  const [editData, setEditData] = useState("");
  const [totalBreaksError, setTotalBreaksError] = useState("");

  useEffect(() => {
    getTimesheetData();
    resetFormFields();
  }, [siteId]);

  const resetFormFields = () => {
    setCloseDate("");
    setZoneId("");
    setStatus("");
    setFromTime("");
    setToTime("");
    setTimeFormat("");
    setTimesheetId("");
    setShiftId("");
    set_site_id("");
  };
  const getTimesheetData = () => {
    setIsLoading(true);
    const formData = {
      site_id: siteId.value,
      user_id: user_id,
      business_id: business_id,
      start_date: startDate ? startDate : moment().format("YYYY-MM-DD"),
      end_date: endDate ? endDate : moment().format("YYYY-MM-DD"),
    };
    axios
      .post("/list-sites-workers", formData)
      .then((res) => {
        console.log(res);
        setSites(res.sites);
        setShiftMembers(res.workers);
        setTimesheetData(res.timesheets);
        // setApprovalStatus(res.timesheets[0].Approval_Status);
        // setStatus(res.timesheets[0].Approval_Status);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const getEditData = (index, shift_id, site_id) => {
    setSelectedRowIndex(index);
    setIsLoading(true);
    const formData = {
      user_id: user_id,
      business_id: business_id,
      site_id: site_id,
      shift_id: shift_id,
    };
    axios
      .post("/load-timesheet-table-data", formData)
      .then((res) => {
        setEditData(res.timesheet_data);
        console.log(res);
        setZones(res.zones);
        setCloseDate(moment(res.timesheet_data.Date).format("DD-MMM-YYYY"));
        let startTime, endTime;
        if (res.timesheet_data && res.timesheet_data.Time) {
          [startTime, endTime] = res.timesheet_data.Time.split(" - ");
        } else {
          // Handle the case where res.timesheet_data.Time is null or undefined
          // For example, you can set default values for startTime and endTime
          startTime = "N/A";
          endTime = "N/A";
        }
        const selectedWareOption = res.zones.find(
          (option) => option.zone_name === res.timesheet_data.zone_name
        );
        setZoneId({
          value: selectedWareOption.id,
          label: res.timesheet_data.zone_name,
        });
        setStatus(res.timesheet_data.Approval_Status);
        setApprovalStatus(res.timesheet_data.Approval_Status);
        setProgressStatus(res.timesheet_data.Progress_Status);
        setFromTime(startTime);
        setToTime(endTime);
        setTimeFormat(res.timesheet_data.break);
        setTimesheetId(res.timesheet_data.timesheet_id);
        setShiftId(res.timesheet_data.shift_id);
        set_site_id(res.timesheet_data.site_id);
        toggleCustom("1");
        setFromTimeError("");
        setToTimeError("");
        setTotalBreaksError("");
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const UpdateTimesheet = (type) => {
    if (type !== "undo") {
      if (
        fromTime === "" ||
        fromTime === null ||
        fromTime.length === 0 ||
        fromTime === "N/A"
      ) {
        setFromTimeError("Please select from time.");
      } else {
        setFromTimeError("");
      }
      if (
        toTime === "" ||
        toTime === null ||
        toTime.length === 0 ||
        toTime === "N/A"
      ) {
        setToTimeError("Please select to time.");
      } else {
        setToTimeError("");
      }
      if (
        timeFormat === "" ||
        timeFormat === null ||
        timeFormat.length === 0 ||
        timeFormat === "00:00" ||
        timeFormat === 0
      ) {
        setTotalBreaksError("Please enter total breaks.");
      } else {
        setTotalBreaksError("");
      }
      if (
        fromTime === "" ||
        fromTime === null ||
        fromTime.length === 0 ||
        fromTime === "N/A" ||
        toTime === "" ||
        toTime === null ||
        toTime.length === 0 ||
        toTime === "N/A" ||
        timeFormat === "" ||
        timeFormat === null ||
        timeFormat.length === 0 ||
        timeFormat === "00:00" ||
        timeFormat === 0
      ) {
        return;
      }
    }
    const formatTotalBreaks = (breaks) => {
      // Remove any colons
      let sanitizedBreaks = breaks.replace(":", "");

      // Ensure the string is 4 characters long by padding with zeros if necessary
      sanitizedBreaks = sanitizedBreaks.padStart(4, "0");

      // Extract hours and minutes
      const hours = sanitizedBreaks.slice(0, 2);
      const minutes = sanitizedBreaks.slice(2, 4);

      return `${hours}:${minutes}`;
    };

    var formData = {
      user_id: user_id,
      business_id: business_id,
      timesheet_id: timesheetId,
      shift_date: close_date,
      timesheet_start_time: fromTime,
      timesheet_finish_time: toTime,
      total_breaks: formatTotalBreaks(timeFormat),
      site_id: site_id,
      type: type,
    };
    console.log(formData, "formData123");
    setIsLoading(true);
    axios
      .post("/update-timesheet-table", formData)
      .then((response) => {
        if (response.error === 1) {
          toast.success(response.message, { theme: "light" });
          getTimesheetData();
          setApprovalStatus(response.approval_status);
          setProgressStatus(response.progress_status);
          setStatus(response.approval_status);
        } else {
          toast.error(response.message);
        }
      })
      .catch((error) => {
        toast.error("Something went wrong while editing shift...");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  function handleMember(selectedOption) {
    setMemberId(selectedOption);
  }
  function handleSite(selectedOption) {
    setSiteId(selectedOption);
    getTimesheetData();
  }
  function handleZone(selectedOption) {
    setZoneId(selectedOption);
  }
  function onTimeFormatChange(e) {
    setTimeFormat(e.target.rawValue);
    setTotalBreaksError("");
  }
  //calendar
  const flatpickrRef = useRef(null);
  const handleDateChange = (selectedDate) => {
    setCurrentDate(new Date(selectedDate));
    const date = new Date(selectedDate);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    let newStartDate, newEndDate;
    setSelectedDate(date);
    newStartDate = formattedDate;
    newEndDate = formattedDate;
    setStartDate(newStartDate); // Wait for the completion of setStartDate
    setEndDate(newEndDate);
    if (flatpickrRef.current !== null) {
      flatpickrRef.current.flatpickr.close();
    }
  };
  useEffect(() => {
    if (!selectedDate) {
      setSelectedDate(new Date());
    }
  }, []);
  const handleViewChange = (newView) => {
    let newSelectedDate = new Date();
    if (newView === "day") {
      // For day view, no need to change the selected date
    } else if (newView === "week") {
      // For week view, add 7 days to the selected date
      let currentDate = new Date();
      let currentDay = currentDate.getDay();
      let diff =
        currentDate.getDate() - currentDay + (currentDay == 0 ? -6 : 1); // Adjust to Monday if current day is Sunday
      newSelectedDate = new Date(currentDate.setDate(diff));
    } else {
      // For month view, set the selected date to the first day of the next month
      newSelectedDate.setDate(1);
    }

    // Set the selected date
    setSelectedDate(newSelectedDate);
    // Calculate start and end dates based on the selected view
    let newStartDate, newEndDate;
    if (newView === "day") {
      newStartDate = moment(newSelectedDate).format("YYYY-MM-DD");
      newEndDate = moment(newSelectedDate).format("YYYY-MM-DD");
    } else if (newView === "week") {
      newStartDate = moment(newSelectedDate)
        .startOf("week")
        .format("YYYY-MM-DD");
      newEndDate = moment(newSelectedDate).endOf("week").format("YYYY-MM-DD");
    } else {
      newStartDate = moment(newSelectedDate)
        .startOf("month")
        .format("YYYY-MM-DD");
      newEndDate = moment(newSelectedDate).endOf("month").format("YYYY-MM-DD");
    }

    // Call getTimesheetData with the calculated start and end dates
    setStartDate(newStartDate); // Wait for the completion of setStartDate
    setEndDate(newEndDate);

    // Update the view state
    setView(newView);
    //getTimesheetData();
  };
  const handleNext = async () => {
    let newSelectedDate;
    if (view === "day") {
      newSelectedDate = new Date(selectedDate.getTime() + 24 * 60 * 60 * 1000); // Next day
    } else if (view === "week") {
      newSelectedDate = new Date(
        selectedDate.getTime() + 7 * 24 * 60 * 60 * 1000
      ); // Next week
    } else if (view === "month") {
      newSelectedDate = new Date(
        selectedDate.getFullYear(),
        selectedDate.getMonth() + 1,
        1
      ); // Next month
    }
    setSelectedDate(newSelectedDate);
    updateStartAndEndDate(newSelectedDate);
  };
  const handlePrevious = async () => {
    let newSelectedDate;
    if (view === "day") {
      newSelectedDate = new Date(selectedDate.getTime() - 24 * 60 * 60 * 1000); // Previous day
    } else if (view === "week") {
      newSelectedDate = new Date(
        selectedDate.getTime() - 7 * 24 * 60 * 60 * 1000
      ); // Previous week
    } else if (view === "month") {
      newSelectedDate = new Date(
        selectedDate.getFullYear(),
        selectedDate.getMonth() - 1,
        1
      ); // Previous month
    }
    setSelectedDate(newSelectedDate);
    updateStartAndEndDate(newSelectedDate);
  };

  const updateStartAndEndDate = async (newSelectedDate) => {
    let newStartDate, newEndDate;
    if (view === "day") {
      newStartDate = moment(newSelectedDate).format("YYYY-MM-DD");
      newEndDate = moment(newSelectedDate).format("YYYY-MM-DD");
    } else if (view === "week") {
      newStartDate = moment(newSelectedDate)
        .startOf("week")
        .format("YYYY-MM-DD");
      newEndDate = moment(newSelectedDate).endOf("week").format("YYYY-MM-DD");
    } else if (view === "month") {
      newStartDate = moment(newSelectedDate)
        .startOf("month")
        .format("YYYY-MM-DD");
      newEndDate = moment(newSelectedDate).endOf("month").format("YYYY-MM-DD");
    }
    setStartDate(newStartDate); // Wait for the completion of setStartDate
    setEndDate(newEndDate);
  };

  useEffect(() => {
    // Ensure both startDate and endDate are not empty before sending data to the backend
    if (startDate && endDate) {
      // Call your backend function here, passing startDate and endDate
      getTimesheetData();
    }
  }, [startDate, endDate]);

  // Custom Tabs Bordered
  const [customActiveTab, setcustomActiveTab] = useState("1");
  const toggleCustom = (tab) => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };
  // Arrow Nav tabs
  const [arrowNavTab, setarrowNavTab] = useState("1");
  const arrowNavToggle = (tab) => {
    if (arrowNavTab !== tab) {
      setarrowNavTab(tab);
    }
  };
  const handleSelectChange = (selectedDates, dateStr, instance) => {
    const formattedTime = `${dateStr}:00`;
    setToTime(formattedTime); // Update state
    setToTimeError("");
  };
  const handleStartTime = (selectedDates, dateStr, instance) => {
    const formattedTime = `${dateStr}:00`;
    setFromTime(formattedTime);
    setFromTimeError("");
  };
  const generateTimeOptions = () => {
    const options = [];
    for (let h = 0; h < 24; h++) {
      for (let m = 0; m < 60; m += 15) {
        const hour = h < 10 ? `0${h}` : `${h}`;
        const minute = m === 0 ? "00" : `${m}`;
        const time = `${hour}:${minute}:00`;
        options.push({ label: formatTime(time), value: time });
      }
    }
    return options;
  };
  // Function to format time as AM/PM
  const formatTime = (time) => {
    const [hour, minute] = time.split(":");
    const formattedHour = parseInt(hour, 10) % 12 || 12;
    const period = parseInt(hour, 10) < 12 ? "AM" : "PM";
    return `${formattedHour}:${minute} ${period}`;
  };
  const pastelColors = [
    "#fd99a8", // Plum
    "#FFA07A", // LightSalmon
    "#FFD700", // Gold
    "#90df90", // PaleGreen
    "#8bdddd", // PaleTurquoise
    "#af91e3", // Lavender
    "#f791eb", // LightPink
  ];
  const buttonClass = (buttonView) => {
    return view === buttonView
      ? "btn btn-soft-primary selected"
      : "btn btn-ghost-primary";
  };
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderColor: siteIdError ? "red" : provided.borderColor,
      "&:hover": {
        borderColor: siteIdError ? "red" : provided.borderColor,
      },
      backgroundColor: "#F6F6F6",
      minHeight: "50px",
      border: "none",
      borderRadius: "40px",
      boxShadow: state.isFocused
        ? siteIdError
          ? "0 0 0 1px red"
          : "0 0 0 1px #7F4BDA"
        : provided.boxShadow,
      padding: "2px 10px",
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#7F4BDA" : provided.backgroundColor,
      color: state.isSelected ? "white" : provided.color,
      "&:hover": {
        backgroundColor: "#e8e6f7",
        color: "black",
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "black",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      paddingRight: "10px",
      padding: 0,
      color: "#7F4BDA", // Set your desired color here
      "&:hover": {
        color: "#7527CE", // Optional: change color on hover
      },
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
  };
  const timeOptions = generateTimeOptions();
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row className="mt-3">
            <Col lg={12}>
              <Row>
                <Col md="3">
                  <Card
                    className="p-3"
                    style={{ borderRadius: "11px", boxShadow: "none" }}
                  >
                    <Row style={{ marginTop: "14px" }}>
                      <Col md="12">
                        <div className="mb-3">
                          <Select
                            value={siteId}
                            className={`${siteIdError ? "is-invalid" : ""}`}
                            onChange={(selectedOption) => {
                              handleSite(selectedOption);
                              setSiteIdError("");
                            }}
                            options={[
                              { value: "all", label: "All sites" }, // Add the "All sites" option
                              ...(sites && Array.isArray(sites)
                                ? sites.map((item) => ({
                                    value: item.id,
                                    label: item.site_name,
                                  }))
                                : []),
                            ]}
                            styles={customStyles}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        {shiftMembers && Array.isArray(shiftMembers)
                          ? shiftMembers.map(
                              (
                                item,
                                index // added index argument for the key and color calculation
                              ) => (
                                <div key={index}>
                                  {" "}
                                  {/* added key attribute */}
                                  <div
                                    className="mb-2 d-flex mt-2 p-2 profile-card-cal cursor-pointer"
                                    style={{ backgroundColor: "#F6F6F6" }}
                                  >
                                    <div
                                      className="avatar-xxs"
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        height: "2.5rem",
                                        width: "2.5rem",
                                      }}
                                    >
                                      {item.s3url ? (
                                        <img
                                          src={item.s3url}
                                          alt=""
                                          style={{
                                            height: "100%",
                                            width: "100%",
                                          }}
                                          className="avatar-xxs rounded-circle"
                                        />
                                      ) : (
                                        <span
                                          className="avatar-title rounded-circle text-white"
                                          style={{
                                            padding: "4px",
                                            backgroundColor:
                                              pastelColors[
                                                index % pastelColors.length
                                              ],
                                          }}
                                        >
                                          {item.initials}
                                        </span>
                                      )}
                                    </div>
                                    <div className="ms-2">
                                      <div className="emp_name">
                                        {item.full_name}
                                      </div>
                                      <div className="emp_det text-muted">
                                        {item.timesheet_count +
                                          " " +
                                          "time sheet "}{" "}
                                        | {item.total_hours + " " + "hrs"}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )
                            )
                          : null}
                      </Col>
                    </Row>
                  </Card>
                </Col>
                <Col md="9">
                  <div className="ps-0 align-items-start pb-0">
                    <div className="week-view-calendar overflow-hidden w-100">
                      <div
                        className="navigation mb-4"
                        style={{ justifyContent: "space-between" }}
                      >
                        <div className="timesheet-container">
                          <div className="timesheetheader">
                            <button
                              className="btn buttonstyle"
                              onClick={handlePrevious}
                            >
                              <i className="ri-arrow-left-s-line align-bottom fw-bold"></i>
                            </button>
                            <span className="btn btn" id="overviewCalendar">
                              {view === "day" &&
                                selectedDate instanceof Date &&
                                selectedDate.toDateString()}
                              {view === "week" && selectedDate && (
                                <>
                                  {selectedDate.toDateString()} -{" "}
                                  {new Date(
                                    selectedDate.getTime() +
                                      6 * 24 * 60 * 60 * 1000
                                  ).toDateString()}
                                </>
                              )}
                              {view === "month" &&
                                selectedDate &&
                                selectedDate.toLocaleString("default", {
                                  month: "long",
                                })}
                              <UncontrolledPopover
                                placement="bottom"
                                target="overviewCalendar"
                                trigger="legacy"
                              >
                                <PopoverBody>
                                  <Flatpickr
                                    ref={flatpickrRef}
                                    name="unavailabilityDates"
                                    className="form-control"
                                    id="datepicker-overview-input"
                                    placeholder="Select date(s)"
                                    options={{
                                      mode: "single",
                                      enableTime: false,
                                      dateFormat:
                                        "D M d Y H:i:s \\G\\M\\TO (T)",
                                      // dateFormat: "d/m/Y", // Set the date format to dd/mm/yyyy
                                      // defaultDate: "2024-02-21",
                                      inline: true, // Use inline mode
                                      // onChange: handleDateChange,
                                      onClose: () =>
                                        console.log("Calendar closed"),
                                    }}
                                    onChange={handleDateChange}
                                    value={selectedDate || ""}
                                  />
                                </PopoverBody>
                              </UncontrolledPopover>
                            </span>
                            <button
                              className="btn buttonstyle"
                              onClick={handleNext}
                            >
                              <i
                                className="ri-arrow-right-s-line align-bottom fw-bold"
                                style={{ fontSize: "large" }}
                              ></i>
                            </button>
                          </div>
                          <div className="timesheet-actions">
                            <ButtonGroup
                              style={{
                                backgroundColor: "white",
                                borderRadius: "12px",
                              }}
                            >
                              <button
                                className={buttonClass("day")}
                                style={{
                                  fontWeight: "bold",
                                  color: "#7f4bda",
                                  borderRadius: "11px",
                                }}
                                onClick={() => handleViewChange("day")}
                              >
                                Day
                              </button>
                              <button
                                className={buttonClass("week")}
                                style={{
                                  fontWeight: "bold",
                                  color: "#7f4bda",
                                  borderRadius: "11px",
                                }}
                                onClick={() => handleViewChange("week")}
                              >
                                Week
                              </button>
                              <button
                                className={buttonClass("month")}
                                style={{
                                  fontWeight: "bold",
                                  color: "#7f4bda",
                                  borderRadius: "11px",
                                }}
                                onClick={() => handleViewChange("month")}
                              >
                                Month
                              </button>
                            </ButtonGroup>

                            <button
                              className="btn btn-white p-2 ms-3"
                              style={{ color: "#7f4bda" }}
                              onClick={() => getTimesheetData()}
                            >
                              <i className="bx bx-refresh align-inherit fs-16"></i>
                            </button>

                            <button
                              className="btn btn-soft-primary ms-3 timesheetbutton"
                              style={{ fontWeight: "bold" }}
                              onClick={() => getTimesheetData()}
                            >
                              Add Timesheet
                            </button>

                            <button
                              className="btn btn-soft-primary ms-3 timesheetbutton"
                              style={{ fontWeight: "bold" }}
                              onClick={() => getTimesheetData()}
                            >
                              Approve All & Next
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="table-responsive">
                    <Table className="table-striped table-nowrap align-middle mb-0">
                      <thead style={{ backgroundColor: "#e7ebee" }}>
                        <tr>
                          <th scope="col">Name</th>
                          <th scope="col">Date</th>
                          <th scope="col">Status</th>
                          <th scope="col">Progress</th>
                          <th scope="col">Zone</th>
                          <th scope="col">Time</th>
                          <th scope="col">Hours</th>
                        </tr>
                      </thead>
                      {timesheetData && timesheetData.length > 0 ? (
                        timesheetData.map((item, index) => (
                          <tbody key={item.id} style={{ cursor: "pointer" }}>
                            <tr
                              onClick={() => {
                                getEditData(index, item.shift_id, item.site_id);
                              }}
                              style={
                                selectedRowIndex === index
                                  ? { backgroundColor: "#e8e6f7" }
                                  : {}
                              }
                            >
                              <td>{item.full_name}</td>
                              <td>{item.Date}</td>
                              <td className="fw-medium">
                                <span
                                  className={`badge ${
                                    item.Approval_Status === "Pending"
                                      ? "bg-warning"
                                      : "bg-success"
                                  }`}
                                >
                                  {item.Approval_Status}
                                </span>
                              </td>
                              <td>{item.Progress_Status}</td>
                              <td>{item.Zone}</td>
                              <td>{item.Time}</td>
                              <td>{item.Hours}</td>
                            </tr>
                          </tbody>
                        ))
                      ) : (
                        <tbody>
                          <tr>
                            <td colSpan="6" style={{ textAlign: "center" }}>
                              No data found
                            </td>
                          </tr>
                        </tbody>
                      )}
                    </Table>
                  </div>
                  <Row style={{ marginTop: "37px" }}>
                    <Col xxl={8}>
                      <Card style={{ borderRadius: "20px" }}>
                        <CardBody>
                          <Nav
                            tabs
                            className="nav nav-tabs nav-tabs-custom nav nav-justified mb-3"
                          >
                            <NavItem>
                              <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                  active: customActiveTab === "1",
                                })}
                                onClick={() => {
                                  toggleCustom("1");
                                }}
                              >
                                Time sheet
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                  active: customActiveTab === "2",
                                })}
                                onClick={() => {
                                  toggleCustom("2");
                                }}
                              >
                                History
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                  active: customActiveTab === "3",
                                })}
                                onClick={() => {
                                  toggleCustom("3");
                                }}
                              >
                                Comments
                              </NavLink>
                            </NavItem>
                          </Nav>
                          <TabContent
                            activeTab={customActiveTab}
                            className="text-muted"
                          >
                            <TabPane tabId="1" id="home1">
                              <div className="d-flex">
                                <div className="flex-grow-1 ms-2">
                                  <Row>
                                    <Card
                                      className=" borderd shadow cursor-pointer text-left p-3 align-items-left d-flex justify-content-center"
                                      style={{
                                        height: "37px",
                                        transition: "all 0.3s",
                                        borderRadius: "4px",
                                        backgroundColor: "#e4e2f3",
                                      }}
                                    >
                                      {timesheetData &&
                                      timesheetData.length > 0 ? (
                                        timesheetData.map((item, index) =>
                                          item.shift_id === shiftId ? (
                                            <span key={item.id}>
                                              <b className="labelstyle">
                                                Scheduled Details :{" "}
                                              </b>
                                              <span>
                                                {item.Date} - {item.Zone} |{" "}
                                                {item.Time}
                                              </span>
                                            </span>
                                          ) : null
                                        )
                                      ) : (
                                        <span>No timesheet data available</span>
                                      )}
                                    </Card>
                                    <Col xl={10}>
                                      <Row style={{ marginTop: "20px" }}>
                                        <Col md={3}>
                                          <div
                                            className="mb-3"
                                            style={{ textAlign: "right" }}
                                          >
                                            <Label
                                              for="birthdayidInput"
                                              className="form-label labelstyle mt-2"
                                            >
                                              Date
                                            </Label>
                                          </div>
                                        </Col>
                                        <Col md={9}>
                                          <div className="mb-3">
                                            <Flatpickr
                                              className={`form-control inputstyle ${
                                                close_date_error
                                                  ? "is-invalid"
                                                  : ""
                                              }`}
                                              options={{
                                                dateFormat: "d M, Y",
                                                readOnly:
                                                  approvalStatus !== "Pending",
                                                // readOnly: true,
                                                allowInput: false,
                                                clickOpens: false,
                                              }}
                                              onChange={(selectedDates) => {
                                                setCloseDate(
                                                  moment(
                                                    selectedDates[0]
                                                  ).format("DD MMM, YYYY")
                                                );
                                                setCloseDateError("");
                                              }}
                                              value={close_date}
                                              style={
                                                approvalStatus !== "Pending"
                                                  ? {
                                                      backgroundColor:
                                                        "#f0f0f0",
                                                    }
                                                  : {}
                                              }
                                              placeholder="Select Date"
                                            />
                                            {close_date_error && (
                                              <div className="invalid-feedback">
                                                {close_date_error}
                                              </div>
                                            )}
                                          </div>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col md={3}>
                                          <div
                                            className="mb-3"
                                            style={{ textAlign: "right" }}
                                          >
                                            <Label
                                              for="birthdayidInput"
                                              className="form-label labelstyle mt-2"
                                            >
                                              Zone
                                            </Label>
                                          </div>
                                        </Col>
                                        <Col md={9}>
                                          <div className="mb-3">
                                            <Select
                                              value={zoneId} // Find the option object with matching id
                                              className={`inputstyle ${
                                                zoneIdError ? "is-invalid" : ""
                                              }`}
                                              isDisabled={
                                                approvalStatus !== "Pending"
                                              }
                                              onChange={(selectedOption) => {
                                                handleZone(selectedOption);
                                                setZoneIdError("");
                                              }}
                                              options={
                                                zones && Array.isArray(zones)
                                                  ? zones.map((item) => ({
                                                      value: item.id,
                                                      label: item.zone_name,
                                                    }))
                                                  : []
                                              }
                                            />
                                            {zoneIdError && (
                                              <div className="invalid-feedback">
                                                {zoneIdError}
                                              </div>
                                            )}
                                          </div>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col md={3}>
                                          <div
                                            className="mb-3"
                                            style={{ textAlign: "right" }}
                                          >
                                            <Label
                                              for="birthdayidInput"
                                              className="form-label labelstyle mt-2"
                                            >
                                              Start/Finish
                                            </Label>
                                          </div>
                                        </Col>
                                        <Col md={4}>
                                          <div>
                                            {/*<Flatpickr
                                                  className={`form-control ${
                                                      fromTimeError ? "is-invalid" : ""
                                                    }`}
                                                  options={{
                                                    enableTime: true,
                                                    noCalendar: true,
                                                    dateFormat: "H:i",
                                                  }}
                                                  value={fromTime}
                                                  onChange={handleStartTime}
                                                  disabled={
                                                    approvalStatus !== "Pending"
                                                  }
                                                  style={
                                                    approvalStatus !== "Pending"
                                                      ? {
                                                          backgroundColor:
                                                            "#f0f0f0",
                                                        }
                                                      : {}
                                                  }
                                                />*/}
                                            <Flatpickr
                                              className={`form-control ${
                                                fromTimeError
                                                  ? "is-invalid"
                                                  : ""
                                              }`}
                                              options={{
                                                enableTime: true,
                                                noCalendar: true,
                                                dateFormat: "H:i",
                                              }}
                                              value={fromTime}
                                              onChange={handleStartTime}
                                              disabled={
                                                approvalStatus !== "Pending"
                                              }
                                              style={
                                                approvalStatus !== "Pending"
                                                  ? {
                                                      backgroundColor:
                                                        "#f0f0f0",
                                                    }
                                                  : {}
                                              }
                                            />
                                            {fromTimeError && (
                                              <div className="invalid-feedback">
                                                {fromTimeError}
                                              </div>
                                            )}
                                          </div>
                                        </Col>
                                        {/*<Col md={4}>
                                                                        <div className="mb-3">
                                                                          <select
                                                                            className={`form-select ${
                                                                              fromTimeError ? "is-invalid" : ""
                                                                            }`}
                                                                            value={fromTime}
                                                                            onChange={handleStartTime}
                                                                          >
                                                                            {timeOptions.map((option) => (
                                                                              <option key={option.value} value={option.value}>
                                                                                {option.label}
                                                                              </option>
                                                                            ))}
                                                                          </select>
                                                                          {fromTimeError && (
                                                                            <div className="invalid-feedback">
                                                                              {fromTimeError}
                                                                            </div>
                                                                          )}
                                                                        </div>
                                                                      </Col>*/}
                                        <Col md={1}>
                                          <div
                                            className="mt-2 labelstyle"
                                            
                                          >
                                            to
                                          </div>
                                        </Col>
                                        {/*<Col md={4}>
                                                                        <div className="mb-3">
                                                                          <select
                                                                            className={`form-select ${
                                                                              toTimeError ? "is-invalid" : ""
                                                                            }`}
                                                                            value={toTime}
                                                                            onChange={handleSelectChange}
                                                                          >
                                                                            {timeOptions.map((option) => (
                                                                              <option key={option.value} value={option.value}>
                                                                                {option.label}
                                                                              </option>
                                                                            ))}
                                                                          </select>
                                                                          {toTimeError && (
                                                                            <div className="invalid-feedback">
                                                                              {toTimeError}
                                                                            </div>
                                                                          )}
                                                                        </div>
                                                                      </Col>*/}
                                        <Col md={4}>
                                          <div>
                                            <Flatpickr
                                              className={`form-control ${
                                                toTimeError ? "is-invalid" : ""
                                              }`}
                                              options={{
                                                enableTime: true,
                                                noCalendar: true,
                                                dateFormat: "H:i",
                                              }}
                                              value={toTime}
                                              onChange={handleSelectChange}
                                              disabled={
                                                approvalStatus !== "Pending"
                                              }
                                              style={
                                                approvalStatus !== "Pending"
                                                  ? {
                                                      backgroundColor:
                                                        "#f0f0f0",
                                                    }
                                                  : {}
                                              }
                                            />
                                            {toTimeError && (
                                              <div className="invalid-feedback">
                                                {toTimeError}
                                              </div>
                                            )}
                                          </div>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col md={3}>
                                          <div
                                            className="mb-3"
                                            style={{ textAlign: "right" }}
                                          >
                                            <Label
                                              for="birthdayidInput"
                                              className="form-label labelstyle mt-2"
                                            >
                                              Total Breaks
                                            </Label>
                                          </div>
                                        </Col>
                                        <Col md={9}>
                                          <div className="mb-3">
                                            <Cleave
                                              className={`form-control ${
                                                totalBreaksError
                                                  ? "is-invalid"
                                                  : ""
                                              }`}
                                              placeholder="hh:mm"
                                              options={{
                                                time: true,
                                                timePattern: ["h", "m"],
                                              }}
                                              value={timeFormat}
                                              onChange={(e) =>
                                                onTimeFormatChange(e)
                                              }
                                              disabled={
                                                approvalStatus !== "Pending"
                                              }
                                              style={
                                                approvalStatus !== "Pending"
                                                  ? {
                                                      backgroundColor:
                                                        "#f0f0f0",
                                                    }
                                                  : {}
                                              }
                                            />
                                            {totalBreaksError && (
                                              <div className="invalid-feedback">
                                                {totalBreaksError}
                                              </div>
                                            )}
                                          </div>
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              </div>
                            </TabPane>
                            <TabPane tabId="2">
                              <div className="d-flex">
                                {/*<div className="flex-shrink-0">
                                      <i className="ri-checkbox-multiple-blank-fill text-success"></i>
                                    </div>*/}
                                {/*<div className="flex-grow-1 ms-2">
                                      When, while the lovely valley teems with
                                      vapour around me, and the meridian sun
                                      strikes the upper surface of the
                                      impenetrable foliage of my trees, and but
                                      a few stray gleams steal into the inner
                                      sanctuary, I throw myself down among the
                                      tall grass by the trickling stream; and,
                                      as I lie close to the earth, a thousand
                                      unknown.
                                      <div className="mt-2">
                                        <Link
                                          to="#"
                                          className="btn btn-sm btn-soft-primary"
                                        >
                                          Read More{" "}
                                          <i className="ri-arrow-right-line ms-1 align-middle"></i>
                                        </Link>
                                      </div>
                                    </div>*/}
                              </div>
                            </TabPane>
                            <TabPane tabId="3">
                              <div className="d-flex">
                                {/* <div className="flex-shrink-0">
                                      <i className="ri-checkbox-multiple-blank-fill text-success"></i>
                                    </div>*/}
                                {/*<div className="flex-grow-1 ms-2">
                                      Etsy mixtape wayfarers, ethical wes
                                      anderson tofu before they sold out
                                      mcsweeney's organic lomo retro fanny pack
                                      lo-fi farm-to-table readymade. Messenger
                                      bag gentrify pitchfork tattooed craft
                                      beer, iphone skateboard locavore carles
                                      etsy salvia banksy hoodie helvetica. DIY
                                      synth PBR banksy irony.
                                      <div className="mt-2">
                                        <Link
                                          to="#"
                                          className="btn btn-sm btn-soft-primary"
                                        >
                                          Read More{" "}
                                          <i className="ri-arrow-right-line ms-1 align-middle"></i>
                                        </Link>
                                      </div>
                                    </div>*/}
                              </div>
                            </TabPane>
                            <TabPane tabId="4">
                              <div className="d-flex">
                                {/*<div className="flex-shrink-0">
                                      <i className="ri-checkbox-multiple-blank-fill text-success"></i>
                                    </div>*/}
                                {/*<div className="flex-grow-1 ms-2">
                                      when darkness overspreads my eyes, and
                                      heaven and earth seem to dwell in my soul
                                      and absorb its power, like the form of a
                                      beloved mistress, then I often think with
                                      longing, Oh, would I could describe these
                                      conceptions, could impress upon paper all
                                      that is living so full and warm within me,
                                      that it might be the.
                                      <div className="mt-2">
                                        <Link
                                          to="#"
                                          className="btn btn-sm btn-soft-primary"
                                        >
                                          Read More{" "}
                                          <i className="ri-arrow-right-line ms-1 align-middle"></i>
                                        </Link>
                                      </div>
                                    </div>*/}
                              </div>
                            </TabPane>
                          </TabContent>
                        </CardBody>
                      </Card>
                    </Col>
                    {editData && (
                      <Col xxl={4}>
                        {editData && editData.ip_enabled === 'Y' && (
                          <p>This is an instant payout shift. If you approve, the amount will be debited from your account.</p>
                      )}
                        <Card
                          className=" borderd shadow cursor-pointer text-center align-items-center d-flex justify-content-center"
                          style={{
                            transition: "all 0.3s",
                            borderRadius: "20px",
                            padding: "30px",
                          }}
                        >
                          {console.log(
                            approvalStatus,
                            progressStatus,
                            "approvalStatus231"
                          )}
                          {approvalStatus && (
                            <button
                              className={`btn ${
                                approvalStatus === "Pending"
                                  ? "btn"
                                  : "btn-warning"
                              } p-2`}
                              onClick={() =>
                                approvalStatus === "Pending"
                                  ? UpdateTimesheet("approve")
                                  : UpdateTimesheet("undo")
                              }
                              style={{
                                backgroundColor: "#7f4bda",
                                width: "100%",
                                borderRadius: "13px",
                                cursor:
                                  approvalStatus === "Approved" &&
                                  progressStatus === "Paid"
                                    ? "not-allowed"
                                    : "pointer",
                              }}
                              /*disabled={
                                approvalStatus === "Approved" &&
                                progressStatus === "Paid"
                              }*/
                              disabled={
                                  (approvalStatus === "Approved" && progressStatus === "Paid") ||
                                  (approvalStatus === "Pending" && ["Scheduled", "Unsubmitted", "Pending Submission", "Started"].includes(progressStatus)) || (approvalStatus === "Approved" && editData && editData.ip_enabled === 'Y')
                                }
                            >
                              <span
                                style={{
                                  fontWeight: "bold",
                                  color: "white",
                                }}
                              >
                                {/*{approvalStatus === "Pending"
                                  ? "Approve"
                                  : approvalStatus === "Approved" &&
                                    progressStatus === "Paid"
                                  ? "Undo Approval (Blocked)"
                                  : "Undo Approval"}*/}
                              {approvalStatus === "Pending"
                                  ? "Approve"
                                  : (approvalStatus === "Approved" &&
                                    progressStatus === "Paid") ||  (approvalStatus === "Approved" && editData && editData.ip_enabled === 'Y')
                                  ? "Undo Approval (Blocked)"
                                  : "Undo Approval"}
                              </span>
                            </button>
                          )}
                          <Row className="mt-3">
                            {status && (
                              <>
                                <Col md={2}>
                                  <div className="mb-3">
                                    <button className="btn btn-ghost-dark">
                                      <img
                                        src={img14}
                                        alt=""
                                        className="avatar-xxs rounded-circle"
                                      />
                                    </button>
                                  </div>
                                </Col>
                                <Col md={10}>
                                  <h6
                                    style={{
                                      fontSize: "13px",
                                      marginTop: "10px",
                                      padding: "6px 23px",
                                      borderRadius: "13px",
                                    }}
                                    className={`badge ${
                                      status === "Pending"
                                        ? "bg-warning"
                                        : "bg-success"
                                    }`}
                                  >
                                    {status}
                                  </h6>
                                </Col>
                              </>
                            )}
                          </Row>
                          {/* <button
                                                                          className="btn btn-info p-2 ms-3"
                                                                          onClick={() =>
                                                                            UpdateTimesheet() 
                                                                          }
                                                                        >
                                                                         <span style={{padding:'0px 71px'}}>Approve</span>
                                                                        </button> */}
                          <button
                            style={{ width: "100%", borderRadius: "13px" }}
                            className="btn btn-success p-2"
                            /*onClick={() =>
                                                                            getTimesheetData() 
                                                                          }*/
                          >
                            <span>Approve & Next</span>
                          </button>
                        </Card>
                      </Col>
                    )}
                  </Row>
                </Col>
              </Row>
              <ToastContainer closeButton={false} limit={1} theme="light" />
              {isLoading && (
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    background: "rgba(255, 255, 255, 0.8)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    zIndex: 9999,
                  }}
                >
                  <Loader />
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ApproveTimesheets;
