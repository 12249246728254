import PrismCode from "../../Components/Common/Prism";
import {
  Card,
  CardBody,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  Alert,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  UncontrolledAlert,
  TabContent,
  TabPane,
  UncontrolledTooltip,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef,
} from "react";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import img13 from "../../assets/images/small/userimage.png";
import { Button } from "reactstrap";
import { Link } from "react-router-dom";
import { get_cookie } from "../../helpers/get_cookie";
import { isEmpty } from "lodash";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import classnames from "classnames";
import "../../assets/scss/pages/_settings.scss";
import TableContainer from "../../Components/Common/TableContainer";
import DeleteModal from "../../Components/Common/DeleteModal";
import {
  CardHeader,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  ModalFooter,
  Table,
  FormFeedback,
} from "reactstrap";
//Import actions
import {
  getContacts as onGetContacts,
  addNewContact as onAddNewContact,
  updateContact as onUpdateContact,
  deleteContact as onDeleteContact,
} from "../../store/actions";
//redux
import { useSelector, useDispatch } from "react-redux";
import userDummy from "../../assets/images/users/user-dummy-img.jpg";
import GetrosterTableContainer from "../../Components/Common/GetrosterTableContainer";
// Formik
import * as Yup from "yup";
import { useFormik } from "formik";
import Loader from "../../Components/Common/Loader";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import MyProfile from "../SearchWorkforce/ProfileView";
//reloading the page
import { jsx } from "@emotion/react";
import { ClipLoader } from "react-spinners";
import { api } from "../../config";

//reloading the page
const override = jsx`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

export const TalentPool = () => {
  document.title = "Talent Pool | Get Rostered";
  const [modal, setModal] = useState(false);
  const business_id = JSON.parse(get_cookie("authUser")).business_id;
  const [rowsData, setRowsData] = useState([]);
  const [archive, setArchive] = useState([]);
  const [data, setData] = useState([]);
  const [contact, setContact] = useState([]);
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const [uploadedImage, setUploadedImage] = useState("");
  const [archiveCount, setArchiveCount] = useState([]);
  const [activeCount, setActiveCount] = useState([]);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [authUserId, setAuthUserId] = useState();
  const [userId, setUserId] = useState();
  const [modal_assignModal, setmodal_assignModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [openProfileId, setOpenProfileId] = useState();
  const [modal_grid, setmodal_grid] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteModalMulti, setDeleteModalMulti] = useState(false);

  const [modal_large, setModal_Company] = useState(false);
  const [rejected, setrejected] = useState([]);
  //job Modal
  const [profileTitle, setProfileTitle] = useState();
  const [profileLocation, setProfileLocation] = useState();
  const [vacancy, setVacancy] = useState();
  const [salaryType, setSalaryType] = useState();
  const [salary, setSalary] = useState();
  const [status, setStatus] = useState();
  const [validity, setValidity] = useState();
  const [startDate, setStartDate] = useState();
  const [jobLocation, setJobLocation] = useState();
  const [jobDescription, setJobDescription] = useState();
  const [isOpenLoading, setIsOpenLoading] = useState(false);

  function tog_grid() {
    setmodal_grid(!modal_grid);
  }

  useEffect(() => {
    setUserId(JSON.parse(get_cookie("authUser")).user_id);
    console.log(userId);
    axios
      .get("/get-user-details")
      .then((res) => {
        console.log(res);
        setAuthUserId(res.id);
        setUserId(res.id);
        loadTable(res.id, "Potential");
      })
      .then((err) => {
        console.log(err);
      });
  }, []);

  const toggleDefaultCanvas = () => {
    setOpen(!open);
  };

  const activeemployees = (userId) => {
    setisLoading(true);
    const formData = {
      user_id: userId,
      business_id: business_id,
    };
    axios
      .post("/active-employees", formData)
      .then((response) => {
        setData(response.result);
        setArchive(response.archive);
        setActiveCount(response.active_count);
        setArchiveCount(response.archive_count);
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setisLoading(false);
      });
  };

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      tog_Company();
      setContact(null);
    } else {
      setModal(true);
      setTag([]);
      setAssignTag([]);
    }
  }, [modal]);

  const [currentStatus, setCurrentStatus] = useState("1"); // Default to "Active"

  const updateTeamMemberStatus = (id, status) => {
    setisLoading(true); // Set loading state

    console.log(authUserId, "userid");
    // Determine status based on the current tab

    let statusupdate = "";

    if (status == "Potential") {
      statusupdate = "Rejected";
    } else if (status == "Rejected") {
      statusupdate = "Potential";
    }

    let formData = {
        id: id,
        user_id: userId,
        business_id: business_id,
        status: statusupdate , // Include status in the request
    };

    console.log(formData);
    axios
      .post("/update-team-member-status", formData)
      .then((response) => {
        console.log(response);
        loadTable(userId, status);
        toast.success(response.message);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setisLoading(false); // Clear loading state
      });
  };
  // Handle tab switching
  const handleTabChange = (tab) => {
    console.log(tab, "tab");
    setCurrentStatus(tab); // Update current status based on the tab
    customHovertoggle(tab);
  };

  const addTableRows = () => {
    const rowsInput = {
      fullName: "",
      emailAddress: "",
      salary: "",
    };
    setRowsData([...rowsData, rowsInput]);
  };

  const dispatch = useDispatch();

  //react
  const isContactSuccess = true;
  const error = null;
  const crmcontacts = data;

  console.log(crmcontacts, "crmcontacts");

  const loadTable = (userId, status) => {
    const formData = {
      user_id: userId,
      business_id: business_id,
      status: status, // Add status to the form data
    };
    setisLoading(true);
    axios
      .post("/list-talent-tool", formData)
      .then((response) => {
        setData(response.result);
        setIsImageSelected(response.result.image);
        setActiveCount(response.active_count);
        setArchiveCount(response.archive_count);
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setisLoading(false);
      });
  };

  //react
  useEffect(() => {
    setContact(crmcontacts);
  }, [crmcontacts]);

  useEffect(() => {
    if (!isEmpty(crmcontacts)) {
      setContact(crmcontacts);
      setIsEdit(false);
    }
  }, [crmcontacts]);

  // Delete Data
  const handleDeleteContact = () => {
    if (contact) {
      dispatch(onDeleteContact(contact._id));
      setDeleteModal(false);
    }
  };
  const onClickDelete = (contact) => {
    setContact(contact);
    setDeleteModal(true);
  };
  //delete Conatct

  function tog_Company() {
    setModal_Company(!modal_large);
  }

  // Add Data
  const handleContactClicks = () => {
    setContact("");
    setIsEdit(false);
    toggle();
  };
  const dateFormat = () => {
    var d = new Date(),
      months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
    return d.getDate() + " " + months[d.getMonth()] + ", " + d.getFullYear();
  };
  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      // img: (contact && contact.img) || '',
      name: (contact && contact.name) || "",
      company: "",
      designation: "",
      email: (contact && contact.email) || "",
      phone: (contact && contact.phone) || "",
      lead_score: (contact && contact.lead_score) || "",
      tags: (contact && contact.tags) || [],
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Name"),
      company: Yup.string().required("Please Enter Company"),
      designation: Yup.string().required("Please Enter Designation"),
      email: Yup.string().required("Please Enter Email"),
      phone: Yup.string().required("Please Enter Phone"),
      lead_score: Yup.string().required("Please Enter lead_score"),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateContact = {
          _id: contact ? contact._id : 0,
          // img: values.img,
          name: values.name,
          company: values.company,
          designation: values.designation,
          email: values.email,
          phone: values.phone,
          lead_score: values.lead_score,
          last_contacted: dateFormat(),
          // time: timeFormat(),
          tags: assignTag,
        };
        // update Contact
        dispatch(onUpdateContact(updateContact));
        validation.resetForm();
      } else {
        const newContact = {
          _id: (Math.floor(Math.random() * (30 - 20)) + 20).toString(),
          // img: values["img"],
          name: values["name"],
          company: values["company"],
          designation: values["designation"],
          email: values["email"],
          phone: values["phone"],
          lead_score: values["lead_score"],
          last_contacted: dateFormat(),
          // time: timeFormat(),
          tags: assignTag,
        };
        // save new Contact
        dispatch(onAddNewContact(newContact));
        validation.resetForm();
      }
      toggle();
    },
  });

  // Update Data
  const handleContactClick = useCallback(
    (arg) => {
      const contact = arg;

      setContact({
        _id: contact._id,
        // img: contact.img,
        name: contact.name,
        company: contact.company,
        email: contact.email,
        designation: contact.designation,
        phone: contact.phone,
        lead_score: contact.lead_score,
        last_contacted: contact.date,
        // time: contact.time,
        tags: contact.tags,
      });

      setIsEdit(true);
      toggle();
    },
    [toggle]
  );

  const [order, setOrder] = useState([]);

  function tog_assignModal() {
    setmodal_assignModal(!modal_assignModal);
  }

  const handleOrderClick = useCallback(
    (arg) => {
      const order = arg;
      setOrder({
        _id: order._id,
        orderId: order.orderId,
        customer: order.customer,
        product: order.product,
        orderDate: order.orderDate,
        ordertime: order.ordertime,
        amount: order.amount,
        payment: order.payment,
        status: order.status,
      });

      setIsEdit(true);
      toggle();
    },
    [toggle]
  );

  const [selectedMember, setSelectedMember] = useState({
    id: null,
    status: null,
    image: null,
  });

  const handleIconClick = (id, status, image_file_name) => {
    const imageUrl = image_file_name ? `${image_file_name}` : null;

    setSelectedMember({
      id,
      status,
      image: imageUrl,
    });
    tog_assignModal(); // Open the modal
  };

  const handleConfirmClick = () => {
    updateTeamMemberStatus(selectedMember.id, selectedMember.status);
    tog_assignModal(); // Close the modal after the action
  };

  // Checked All
  const checkedAll = useCallback(() => {
    const checkall = document.getElementById("checkBoxAll");
    const ele = document.querySelectorAll(".contactCheckBox");

    if (checkall.checked) {
      ele.forEach((ele) => {
        ele.checked = true;
      });
    } else {
      ele.forEach((ele) => {
        ele.checked = false;
      });
    }
    deleteCheckbox();
  }, []);

  // Delete Multiple
  const [selectedCheckBoxDelete, setSelectedCheckBoxDelete] = useState([]);
  const [isMultiDeleteButton, setIsMultiDeleteButton] = useState(false);

  const deleteMultiple = () => {
    const checkall = document.getElementById("checkBoxAll");
    selectedCheckBoxDelete.forEach((element) => {
      dispatch(onDeleteContact(element.value));
      setTimeout(() => {
        toast.clearWaitingQueue();
      }, 3000);
    });
    setIsMultiDeleteButton(false);
    checkall.checked = false;
  };

  const deleteCheckbox = () => {
    const ele = document.querySelectorAll(".contactCheckBox:checked");
    ele.length > 0
      ? setIsMultiDeleteButton(true)
      : setIsMultiDeleteButton(false);
    setSelectedCheckBoxDelete(ele);
  };
  const handleDeleteOrder = () => {
    var orderIds = selectedOrderId;
    axios
      .get(`${"api/companies/destroy/"}${orderIds}`)
      .then((response) => {
        toast.success(response.message, { theme: "light" });
        loadTable();
        setDeleteModal(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  //react
  const smscolumn = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "full_name",
        filterable: false,
        Cell: ({ row }) => (
          <span
            className="cursor-pointer"
            onClick={() => {
              toggleDefaultCanvas();
              setOpenProfileId(row.original.id);
            }}
          >
            {row.original.full_name}
          </span>
        ),
      },
      {
        Header: "",
        accessor: "image",
        filterable: false,
        Cell: ({ row }) => (
          <>
            <div
              className="avatar-xxs"
              style={{ display: "flex", alignItems: "center", width: "50%" }}
            >
              {row.original.image_file_name ? (
                <img
                  src={`${row.original.image_file_name}`}
                  alt=""
                  className="rounded-circle avatar-xxs d-inline-block"
                  style={{ height: "2.5rem", width: "2.5rem" }}
                />
              ) : (
                /*<img
                  src={img13}
                  alt=""
                  className="rounded-circle avatar-xxs d-inline-block"
                  style={{ height: "2.5rem", width: "2.5rem" }}
                />*/
                <div
                  className="avatar-xxs"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    height: "2.5rem",
                    width: "2.5rem",
                  }}
                >
                  <span
                    className="rounded-circle avatar-xxs d-inline-block"
                    //className="avatar-title rounded-circle text-white"
                    style={{
                      padding: "11px",
                      color: "white",
                      backgroundColor: "#" + row.original.initial.color,
                      height: "2.5rem",
                      width: "2.5rem",
                    }}
                  >
                    {row.original.initial.initials}
                  </span>
                </div>
              )}
            </div>
          </>
        ),
      },
      {
        Header: "Job Offer",
        accessor: "job_offer",
        filterable: false,
        Cell: ({ row }) => (
          <span
            className={
              row.original.job_offer === "Open" ? "cursor-pointer " : ""
            }
            style={
              row.original.job_offer === "Open"
                ? { textDecoration: "underline" }
                : {}
            }
            onClick={() => {
              if (row.original.job_offer === "Open") {
                tog_grid();
                getJobOfferData(row.original.id, userId);
              }
            }}
          >
            {row.original.job_offer
              ? row.original.job_offer == "Open"
                ? "Pending Acceptance"
                : row.original.job_offer
              : "-"}
          </span>
        ),
      },
      {
        Header: "Profile Title",
        accessor: "profile_title",
        filterable: false,
      },
      {
        Header: "Location",
        accessor: "suburb",
        filterable: false,
      },
      {
        Header: "Hourly Rate",
        accessor: "desired_hourly_rate", // This can be any unique identifier
        filterable: false,
      },
      {
        Header: "Availability",
        accessor: "availability_count", // This can be any unique identifier
        filterable: false,
      },
      {
        Header: "Skills",
        accessor: "skill_list", // This can be any unique identifier
        filterable: false,
      },
      {
        Header: "Action",
        Cell: (cellProps) => {
          const { id, status, image_file_name } = cellProps.row.original;
          const buttonText = status === "Potential" ? "Reject" : "Reactivate";

          return (
            <div className="d-flex gap-3">
              <button
                className={`btn btn-sm ${
                  status === "Potential"
                    ? "btn-soft-danger"
                    : "btn-soft-success"
                }`}
                onClick={() => handleIconClick(id, status, image_file_name)}
              >
                {buttonText}
              </button>
            </div>
          );
        },
      },
    ],
    [handleContactClick, checkedAll]
  );
  //react
  const [tag, setTag] = useState([]);
  const [assignTag, setAssignTag] = useState([]);

  // Export Modal
  const [isExportCSV, setIsExportCSV] = useState(false);

  //image selection
  const fileInputRef = useRef(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isImageSelected, setIsImageSelected] = useState(false);
  const [customHoverTab, setcustomHoverTab] = useState("1");

  const customHovertoggle = (tab) => {
    if (customHoverTab !== tab) {
      setcustomHoverTab(tab);
      const status = tab === "1" ? "Potential" : "Rejected"; // Determine status based on tab
      loadTable(userId, status); // Call loadTable with userId and status
    }
  };

  const handleButtonClick = (event) => {
    event.preventDefault();
    fileInputRef.current.click();
  };

  const handleFileInputChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const objectUrl = URL.createObjectURL(selectedFile);
      setUploadedImage(selectedFile);
      setSelectedImage(objectUrl);
      setIsImageSelected(true);
    }
  };

  const handleRemoveClick = () => {
    setSelectedImage(null);
    setUploadedImage(null);
    setIsImageSelected(false);
    fileInputRef.current.value = null; // Clear the input field value
  };

  const handleChangeClick = (event) => {
    event.preventDefault();
    fileInputRef.current.click();
  };
  const getJobOfferData = (profileId) => {
    setIsOpenLoading(true);
    axios.get("/get-user-details").then((res) => {
      console.log(res);

      let formData = {
        user_id: res.id,
        profile_id: profileId,
        business_id: business_id,
      };
      axios.post("/get-job-offer-details", formData).then((res) => {
        console.log(res, "job-offer-details");
        setProfileTitle(res.data.user_details);
        setProfileLocation(res.data.user_details.suburb);
        setSalaryType(res.data.job_offer_status.salary_range_type);
        setSalary(res.data.job_offer_status.salary);
        setStatus(res.data.job_offer_status.status_name);
        setValidity(res.data.job_offer_status.offer_valid_until);
        setStartDate(res.data.job_offer_status.job_start_date);
        setVacancy(res.data.job_vacancy_data.title);
        setJobLocation(res.data.job_vacancy_data.location);
        setJobDescription(res.data.job_vacancy_data.description);
        setIsOpenLoading(false);
      });
    });
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Talent Pool" />
          <DeleteModal
            show={deleteModal}
            orderId={selectedOrderId}
            onDeleteClick={handleDeleteOrder}
            onCloseClick={() => setDeleteModal(false)}
          />

          <Modal
            size="md"
            isOpen={modal_assignModal}
            toggle={tog_assignModal}
            centered
          >
            <ModalHeader toggle={tog_assignModal}></ModalHeader>
            <ModalBody className="text-center">
              <Row>
                <Col lg={4} className="text-center">
                  <img
                    src={
                      selectedMember.image ? selectedMember.image : userDummy
                    }
                    alt="user-img"
                    style={{ height: "9rem", width: "9rem" }}
                    className="img-thumbnail avatar-xxs rounded-circle"
                  />
                </Col>
                <Col lg={8}>
                  <div className="confirmationText">
                    <h5 className="text-left">
                      {selectedMember.status === "Potential"
                        ? "Are you sure you want to reject this talent?"
                        : "Are you sure you want to reactivate this talent?"}
                    </h5>
                    <p className="subText">Confirm to proceed</p>
                    <div className="d-flex flex-wrap gap-2">
                      <Button
                        className="w-lg cancelButton"
                        color="primary"
                        onClick={tog_assignModal}
                      >
                        Cancel
                      </Button>
                      <Button
                        className="w-lg confirmButton"
                        color="primary"
                        onClick={handleConfirmClick}
                      >
                        Confirm
                      </Button>
                    </div>
                  </div>
                </Col>
              </Row>
            </ModalBody>
          </Modal>

          <Nav pills className="nav nav-pills custom-hover-nav-tabs mt-4">
            <NavItem
              style={{
                border: "1px solid #865ce2",
                borderTopLeftRadius: "7px",
                borderBottomLeftRadius: "7px",
              }}
            >
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({ active: currentStatus === "1" })}
                onClick={() => handleTabChange("1")}
              >
                <i className="bx bx-bolt-circle nav-icon nav-tab-position"></i>
                <h5 className="nav-titl nav-tab-position m-0">
                  Active ({activeCount})
                </h5>
              </NavLink>
            </NavItem>
            <NavItem
              style={{
                border: "1px solid #865ce2",
                borderTopRightRadius: "7px",
                borderBottomRightRadius: "7px",
                borderLeft: "none",
              }}
            >
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({ active: currentStatus === "2" })}
                onClick={() => handleTabChange("2")}
              >
                <i className="bx bx-archive nav-icon nav-tab-position m-0"></i>
                <h5 className="nav-titl nav-tab-position">
                  Rejected ({archiveCount})
                </h5>
              </NavLink>
            </NavItem>
          </Nav>
          <Row className="mt-3">
            <Col lg={12}>
              {/* <Card>
            <CardHeader>
              <div className="d-flex align-items-center flex-wrap gap-2">
                <div className="d-flex justify-content-between align-items-center flex-grow-1">
                  <h5
                    className="mb-0"
                    style={{
                      color: "black",
                      fontSize: "1.5em",
                      color: "black",
                    }}
                  >
                    <i className="ri-group-fill" /> Talent Pool
                  </h5>
                </div>
                <div className="flex-shrink-0">
                  <div className="hstack text-nowrap gap-2">
                    {isMultiDeleteButton && (
                      <button
                        className="btn btn-danger"
                        onClick={() => setDeleteModalMulti(true)}
                      >
                        <i className="ri-delete-bin-2-line"></i>
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </CardHeader>
          </Card> */}

              <Card id="contactList">
                <CardBody className="pt-0">
                  <div>
                    {isContactSuccess && crmcontacts.length ? (
                      <GetrosterTableContainer
                        columns={smscolumn}
                        data={crmcontacts || []}
                        isGlobalFilter={true}
                        isAddUserList={false}
                        customPageSize={100}
                        className="custom-header-css"
                        divClass="table-responsive table-card mb-3"
                        tableClass="align-middle table-nowrap"
                        theadClass="table-light"
                        handleContactClick={handleContactClicks}
                        isContactsFilter={true}
                        SearchPlaceholder="Search by name, profile title, location..."
                      />
                    ) : (
                      crmcontacts.length === 0 && (
                        <p style={{ textAlign: "center", marginTop: "10px" }}>
                          No records found
                        </p>
                      )
                    )}
                  </div>
                  <ToastContainer closeButton={false} limit={1} />
                  {isLoading && (
                    <div
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        background: "rgba(255, 255, 255, 0.8)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        zIndex: 9999,
                      }}
                    >
                      <Loader />
                    </div>
                  )}
                </CardBody>
              </Card>
              <Modal
                isOpen={modal_grid}
                centered
                size="lg"
                modalClassName="zoomIn"
                toggle={() => {
                  tog_grid();
                }}
              >
                {isOpenLoading ? (
                  <ModalHeader>
                    <h5 className=" placeholder-glow mt-2">
                      <span className="placeholder col-3"></span>
                    </h5>
                  </ModalHeader>
                ) : (
                  <ModalHeader>
                    <h5 className="modal-title text-info">
                      {profileTitle?.first_name} {profileTitle?.last_name}
                    </h5>
                    <h6 className="text-muted"> {profileLocation}</h6>
                  </ModalHeader>
                )}

                <hr className="mb-0" />
                {isOpenLoading ? (
                  <ModalBody>
                    <div className="row g-3">
                      <Col lg={6}>
                        <div>
                          <label className="form-label">Salary Offered:</label>{" "}
                          <h5 className=" placeholder-glow mt-2">
                            <span className="placeholder col-3"></span>
                          </h5>
                        </div>
                      </Col>

                      <Col lg={6}>
                        <div>
                          <label className="form-label">
                            Offer valid until:
                          </label>{" "}
                          <h5 className=" placeholder-glow mt-2">
                            <span className="placeholder col-3"></span>
                          </h5>
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div>
                          <label className="form-label">
                            Proposed Job Start Date:
                          </label>{" "}
                          <h5 className=" placeholder-glow mt-2">
                            <span className="placeholder col-3"></span>
                          </h5>
                        </div>
                      </Col>
                      <hr className="mt-3 " />

                      <div className="mt-0">
                        <h5 className=" placeholder-glow mt-2">
                          <span className="placeholder col-4"></span>
                        </h5>
                        <div className="hstack gap-2 align-items-center mt-2">
                          <i className="text-success bx bx-dollar-circle fs-18 me-1"></i>
                          <h6 className="mt-0"></h6>
                        </div>
                        <div className="hstack gap-2 mt-2">
                          <i className="text-danger fs-18 me-1 bx bx-map-pin"></i>
                          <h5 className=" placeholder-glow mt-2">
                            <span className="placeholder col-5"></span>
                          </h5>
                        </div>
                        <h5 className=" placeholder-glow mt-2">
                          <span className="placeholder col-4"></span>
                          <span className="placeholder col-7"></span>
                          <span className="placeholder col-8"></span>
                          <span className="placeholder col-10"></span>
                        </h5>
                      </div>

                      <div className="col-lg-12">
                        <div className="hstack gap-2 justify-content-end">
                          <Button
                            color="danger"
                            onClick={() => {
                              setmodal_grid(false);
                            }}
                          >
                            Close
                          </Button>
                        </div>
                      </div>
                    </div>
                  </ModalBody>
                ) : (
                  <ModalBody>
                    <div className="row g-3">
                      <Col lg={6}>
                        <div className="">
                          <label
                            htmlFor="firstName"
                            className="form-label hstack fs-14"
                          >
                            <i className="text-success bx bx-dollar-circle fs-18 me-1"></i>{" "}
                            Salary Offered: $ {salary} {salaryType}
                          </label>{" "}
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="">
                          <label
                            htmlFor="firstName"
                            className="form-label hstack fs-14"
                          >
                            <i className="text-primary bx bx-briefcase fs-18 me-1"></i>
                            Offer status:{" "}
                            <span
                              className={` ${
                                status == "Open"
                                  ? "text-info"
                                  : status == "Accepted"
                                  ? "text-success"
                                  : "text-danger"
                              } ms-2`}
                            >
                              {status == "Open" ? "Pending Acceptance" : status}
                            </span>
                          </label>{" "}
                        </div>
                      </Col>

                      <Col lg={6}>
                        <div>
                          <label className="form-label hstack fs-14">
                            <i className="text-warning bx bx-calendar fs-18 me-1"></i>{" "}
                            Offer valid until: {validity ? validity : "--"}
                          </label>
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div>
                          <label className="form-label hstack fs-14">
                            <i className="text-warning bx bx-calendar fs-18 me-1"></i>{" "}
                            Proposed Job Start Date:{" "}
                            {startDate ? startDate : "--"}
                          </label>
                        </div>
                      </Col>
                      <hr className="mt-3 " />

                      <div className="mt-0">
                        <h5 className="text-primary">{vacancy}</h5>
                        <div className="hstack gap-2 align-items-center mt-2">
                          <h6 className="mt-0"></h6>
                        </div>
                        <div className="hstack gap-2 mt-2">
                          <i className="text-danger fs-18 me-1 bx bx-map-pin"></i>
                          <h6>{jobLocation}</h6>
                        </div>
                        <div className="mt-3">
                          <span
                            dangerouslySetInnerHTML={{
                              __html: jobDescription,
                            }}
                          />{" "}
                        </div>
                      </div>

                      <div className="col-lg-12">
                        <div className="hstack gap-2 justify-content-end">
                          <Button
                            color="danger"
                            onClick={() => {
                              setmodal_grid(false);
                            }}
                          >
                            Close
                          </Button>
                        </div>
                      </div>
                    </div>
                  </ModalBody>
                )}
              </Modal>
              <Offcanvas
                isOpen={open}
                toggle={toggleDefaultCanvas}
                style={{ minWidth: "70%", borderLeft: 0 }}
                direction="end"
                id="offcanvasExample"
              >
                <OffcanvasBody
                  className="white-overall-bg p-4"
                  style={{ overflowX: "hidden" }}
                >
                  <MyProfile
                    profileId={openProfileId}
                    userId={authUserId}
                    isTalentPool={true}
                  />
                </OffcanvasBody>
              </Offcanvas>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default TalentPool;
