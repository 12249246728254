import {
  Card,
  CardBody,
  Col,
  Container,
  Button,
  NavLink,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  CardHeader,
  UncontrolledTooltip,
  UncontrolledDropdown,
  DropdownToggle,
  Nav,
  NavItem,
  Alert,
  DropdownMenu,
  Badge,
  Table,
  DropdownItem,
} from "reactstrap";
import dayjs from "dayjs";
import { Header } from "./common/ShareHeader";
import { Footer } from "./common/ShareFooter";
import relativeTime from "dayjs/plugin/relativeTime";
import React, { useEffect, useState, useCallback, useMemo } from "react";
import axios from "axios";
import { useNavigate, Link, useParams } from "react-router-dom";
import logoPurple from "../../assets/images/logopurple.png";
import logoWhite from "../../assets/images/Logo - White 1.png";
import logoPink from "../../assets/images/LogoPink.png";
import AndroidQR from "../../assets/images/QR_Code/OpenApp.png";
import IosQR from "../../assets/images/QR_Code/2.png";
import googlePlay from "../../assets/images/QR_Code/googlePlay.png";
import appleStore from "../../assets/images/QR_Code/appStore.png";

import Avatar9 from "../../assets/images/users/avatar-9.jpg";
const JobShare = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [jobData, setJobData] = useState();
  const [businessData, setBusinessData] = useState();
  const [relatedJobs, setRelatedJobs] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [isRelatedJobsAvailable, setIsRelatedJobsAvailable] = useState(true);
  const businessLink = "https://getrostered.app/business";
  const workforceLink = "https://getrostered.app/workforce";
  const signupLink = "https://business.getrostered.app";
  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };
  // Decode the Base64 job ID
  const decodedId = atob(id);
  dayjs.extend(relativeTime);

  const formatPostDate = (date) => {
    const today = dayjs();
    const postDate = dayjs(date);

    if (postDate.isSame(today, "day")) {
      return " today";
    } else {
      return postDate.from(today); // "X days ago"
    }
  };

  const formatCloseDate = (dateString) => {
    const closeDate = dayjs(dateString);
    const today = dayjs();

    if (closeDate.isBefore(today, "day")) {
      return "Closed";
    }

    return closeDate.format("DD MMM YYYY"); // Default format, adjust as needed
  };

  useEffect(() => {
    const postData = {
      id: decodedId,
      job_view: "public",
    };
    axios
      .post("view-jobvacancies-data", postData)
      .then((res) => {
        console.log(res);
        setJobData(res.job_vacancies_data);
        setBusinessData(res.business_data);
        setRelatedJobs(res.related_job_vacancies);
        setIsRelatedJobsAvailable(res.related_job_vacancies.length > 0);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
    <section className=" " id="jobShare">
      <div className="container-fluid share-navbar">
        <Header toggle={toggleModal} />
      </div>
      <div className="container">
        <Row className="mt-4">
          <Col lg={isRelatedJobsAvailable ? 8 : 12}>
            <Card
              style={{
                borderRadius: "25px",
                boxShadow: " 0 6px 12px rgba(30, 10, 58, .04)",
              }}
            >
              {jobData && businessData ? (
                <CardBody>
                  <div className="d-flex justify-content-end">
                    <div className=" flex-grow-1">
                      <Link to="#!">
                        <h4>{jobData.title}</h4>
                      </Link>

                      <li className="list-inline-item">
                        <i className="ri-building-line text-primary align-bottom me-1"></i>{" "}
                        {businessData.business_name}
                      </li>

                      <br />

                      <li className="list-inline-item text-muted">
                        <i className="ri-money-dollar-circle-line text-primary align-bottom me-1"></i>{" "}
                        {"$"}
                        {parseFloat(
                          jobData.salary_range_from
                        ).toLocaleString()}{" "}
                        - {"$"}
                        {parseFloat(
                          jobData.salary_range_to
                        ).toLocaleString()}{" "}
                        {jobData.salary_range_type}
                      </li>
                      <br />
                      <li className="list-inline-item text-muted">
                        <i className="ri-map-pin-2-line text-primary align-bottom me-1"></i>{" "}
                        {jobData.location}
                      </li>
                      {jobData?.skills?.length > 0 ? (
                        <>
                          <h6 className="mt-3">Skills Required</h6>
                          <div className="hstack gap-2 mt-2">
                            {jobData?.skills?.map((skill, index) => (
                              <span
                                key={index}
                                className={"badge badge-soft-primary"}
                              >
                                {skill.skill_name}
                              </span>
                            ))}
                          </div>
                        </>
                      ) : null}
                    </div>
                    {businessData.business_logo && (
                      <div className="avatar-md">
                        <div className=" bg-soft-warning rounded">
                          <img
                            src={businessData.s3_logo_url}
                            alt=""
                            className="avatar-md rounded"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  <hr className="calendar-hr"></hr>
                  <div
                    className="text-muted hstack align-items-center fs-12 justify-content-between 
                "
                  >
                    {" "}
                    <span>
                      Posted:{" "}
                      <span className="text-black fs-12">
                        {formatPostDate(jobData?.post_date)}{" "}
                      </span>
                      | Close date:{" "}
                      <span
                        className={
                          formatCloseDate(jobData?.close_date) === "Closed"
                            ? "text-danger"
                            : "text-black"
                        }
                      >
                        {formatCloseDate(jobData.close_date)}
                      </span>
                    </span>
                    <div className="hstack gap-2">
                      <Button
                        className="btn btn-pink "
                        style={{ borderRadius: "30px" }}
                        onClick={() => setModalOpen(true)}
                      >
                        Apply Now
                      </Button>
                    </div>
                  </div>
                </CardBody>
              ) : (
                <CardBody>
                  <div className="d-flex justify-content-end">
                    <div className="flex-grow-1">
                      <h4 className="placeholder-glow">
                        <span className="placeholder col-4 bg-dark"></span>
                      </h4>
                      <div className="hstack gap-2 mt-2">
                        <span className="badge badge-soft-primary placeholder col-2"></span>
                        <span className="badge badge-soft-primary placeholder col-2"></span>
                        <span className="badge badge-soft-primary placeholder col-2"></span>
                      </div>
                      <span className="placeholder-glow mt-3">
                        <span className="placeholder col-5 bg-dark"></span>
                      </span>
                      <br />
                      <span className="placeholder-glow text-muted">
                        <span className="placeholder col-4 bg-dark"></span>
                      </span>
                      <br />
                      <span className="placeholder-glow text-muted">
                        <span className="placeholder col-6 bg-dark"></span>
                      </span>
                      <h5 className="mt-3 placeholder-glow">
                        <span className="placeholder col-4 bg-dark"></span>
                      </h5>
                    </div>
                    <div className="avatar-md">
                      <div className="bg-soft-primary rounded placeholder-glow">
                        <span className="placeholder col-12 bg-dark avatar-md rounded"></span>
                      </div>
                    </div>
                  </div>
                  <hr className="calendar-hr"></hr>
                  <div className="text-muted hstack gap-2 placeholder-glow align-items-center fs-12 ">
                    <span className="placeholder col-2 bg-dark"></span> |
                    <span className="placeholder col-2 bg-dark"></span>
                  </div>
                </CardBody>
              )}
            </Card>
            <Card
              style={{
                borderRadius: "25px",
                boxShadow: " 0 6px 12px rgba(30, 10, 58, .04)",
              }}
            >
              {businessData && jobData ? (
                <CardBody>
                  <h5>Job Description</h5>
                  <p
                    className="mt-3"
                    dangerouslySetInnerHTML={{
                      __html: jobData.description,
                    }}
                  ></p>
                </CardBody>
              ) : (
                <CardBody>
                  <div className="d-flex justify-content-end">
                    <div className="flex-grow-1">
                      <h4 className="placeholder-glow">
                        <span className="placeholder col-5 bg-dark"></span>
                      </h4>
                      <h5 className="mt-3 placeholder-glow">
                        <span className="placeholder col-4 bg-dark"></span>
                      </h5>

                      <span className="placeholder-glow mt-3">
                        <span className="placeholder col-5 bg-dark"></span>
                      </span>
                      <br />
                      <span className="placeholder-glow text-muted">
                        <span className="placeholder col-3 bg-dark"></span>
                      </span>
                      <br />
                      <span className="placeholder-glow text-muted">
                        <span className="placeholder col-6 bg-dark"></span>
                      </span>
                      <br />
                    </div>
                  </div>
                </CardBody>
              )}
            </Card>
            <Card
              style={{
                borderRadius: "25px",
                boxShadow: " 0 6px 12px rgba(30, 10, 58, .04)",
              }}
            >
              {businessData && jobData ? (
                <CardBody>
                  <h5 className="mb-3">Company details</h5>
                  <p className="text-black">
                    <i className="ri-building-line text-primary align-bottom me-1"></i>{" "}
                    <b> {businessData.business_name}</b>
                  </p>
                  <p>
                    <i className="ri-money-dollar-circle-line text-primary align-bottom me-1"></i>{" "}
                    <b>Location: </b> {businessData.address}
                  </p>
                  <p>
                    <i className=" ri-group-line text-primary align-bottom me-1"></i>{" "}
                    <b> Business Size: </b> {businessData.business_size}
                  </p>
                  <h6>About Business</h6>
                  <p>{businessData.about_the_business}</p>
                </CardBody>
              ) : (
                <CardBody>
                  <div className="d-flex justify-content-end">
                    <div className="flex-grow-1">
                      <h4 className="placeholder-glow">
                        <span className="placeholder col-7 bg-dark"></span>
                      </h4>
                      <h5 className="mt-3 placeholder-glow">
                        <span className="placeholder col-3   bg-dark"></span>
                      </h5>
                      <div className="text-muted hstack gap-2 placeholder-glow align-items-center  ">
                        <span className="placeholder col-2 bg-dark"></span>
                        <span className="placeholder col-2 bg-dark"></span>
                      </div>

                      <div className="text-muted hstack gap-2 mt-2 placeholder-glow align-items-center fs-12 ">
                        <span className="placeholder col-2 bg-dark"></span>
                        <span className="placeholder col-3 bg-dark"></span>
                      </div>

                      <h5 className="placeholder-glow ">
                        <span className="placeholder col-3 mt-3 bg-dark"></span>
                      </h5>

                      <span className="placeholder-glow text-muted">
                        <span className="placeholder col-4 bg-dark"></span>
                      </span>
                      <br />
                      <span className="placeholder-glow text-muted">
                        <span className="placeholder col-6 bg-dark"></span>
                      </span>
                    </div>
                  </div>
                  <hr className="calendar-hr"></hr>
                </CardBody>
              )}
            </Card>
          </Col>
          {isRelatedJobsAvailable && (
            <Col lg={4}>
              <Card
                className=""
                style={{
                  borderRadius: "25px",
                  boxShadow: " 0 6px 12px rgba(30, 10, 58, .04)",
                }}
              >
                {relatedJobs?.length > 0 ? (
                  <CardBody>
                    <h6 className="mb-2">
                      Similar jobs that you might be interested in
                    </h6>
                    <div
                      className="mt-3"
                      style={{
                        border: "0.5px solid #bcbcbc57",
                        borderRadius: "20px",
                        cursor: "pointer",
                      }}
                    >
                      {relatedJobs?.map((item, index) => (
                        <div
                          key={index}
                          onClick={() => {
                            const base64Id = btoa(item.id);
                            const link = `http://devbusiness.getrostered.app/job/${base64Id}`;
                            //const link = `http://localhost:3000/job/${base64Id}`;
                            window.location.href = link;
                          }}
                        >
                          <div className=" mt-3">
                            <div className="ms-3 flex-grow-1">
                              <Link to="#!">
                                <h6>{item.title}</h6>
                              </Link>

                              <li className="list-inline-item">
                                <i className="ri-building-line text-primary align-bottom me-1"></i>{" "}
                                {item.business.business_name}
                              </li>
                              <br />
                              <li className="list-inline-item text-muted">
                                <i className="ri-money-dollar-circle-line text-primary align-bottom me-1"></i>{" "}
                                {"$"}
                                {parseFloat(
                                  item.salary_range_from
                                ).toLocaleString()}{" "}
                                - {"$"}
                                {parseFloat(
                                  item.salary_range_to
                                ).toLocaleString()}{" "}
                                {item.salary_range_type}
                              </li>
                              <br />
                              <li className="list-inline-item text-muted">
                                <i className="ri-map-pin-2-line text-primary align-bottom me-1"></i>{" "}
                                {item.location}
                              </li>
                            </div>
                            <div className="text-muted hstack align-items-center justify-content-end me-3">
                              {" "}
                              <span className="text-muted fs-11">
                                {" "}
                                Posted: {formatPostDate(item.post_date)}{" "}
                              </span>
                            </div>
                          </div>

                          <hr className="calendar-hr ms-3 me-3"></hr>
                        </div>
                      ))}
                    </div>
                  </CardBody>
                ) : (
                  <CardBody>
                    <h4 className="placeholder-glow ps-3">
                      <span className="placeholder col-7 bg-dark"></span>
                    </h4>
                    <div
                      className="mt-3 p-3"
                      style={{
                        border: "0.5px solid #bcbcbc57",
                        borderRadius: "20px",
                        cursor: "pointer",
                      }}
                    >
                      <h4 className="placeholder-glow">
                        <span className="placeholder col-5 bg-dark"></span>
                      </h4>
                      <h5 className=" placeholder-glow">
                        <span className="placeholder col-8 bg-dark"></span>
                      </h5>
                      <span className="placeholder-glow mt-3">
                        <span className="placeholder col-5 bg-dark"></span>
                      </span>
                      <br />

                      <div className="text-muted hstack mt-2 gap-2 justify-content-between placeholder-glow align-items-center ">
                        <span className="placeholder col-4 bg-dark"></span>
                        <span className="placeholder col-3 bg-dark"></span>
                      </div>
                      <hr className="calendar-hr"></hr>
                      <h4 className="placeholder-glow">
                        <span className="placeholder col-5 bg-dark"></span>
                      </h4>
                      <h5 className=" placeholder-glow">
                        <span className="placeholder col-8 bg-dark"></span>
                      </h5>
                      <span className="placeholder-glow mt-3">
                        <span className="placeholder col-5 bg-dark"></span>
                      </span>
                      <br />

                      <div className="text-muted hstack mt-2 gap-2 justify-content-between placeholder-glow align-items-center ">
                        <span className="placeholder col-4 bg-dark"></span>
                        <span className="placeholder col-3 bg-dark"></span>
                      </div>
                      <hr className="calendar-hr"></hr>
                      <h4 className="placeholder-glow">
                        <span className="placeholder col-5 bg-dark"></span>
                      </h4>
                      <h5 className=" placeholder-glow">
                        <span className="placeholder col-8 bg-dark"></span>
                      </h5>
                      <span className="placeholder-glow mt-3">
                        <span className="placeholder col-5 bg-dark"></span>
                      </span>
                      <br />

                      <div className="text-muted hstack mt-2 gap-2 justify-content-between placeholder-glow align-items-center ">
                        <span className="placeholder col-4 bg-dark"></span>
                        <span className="placeholder col-3 bg-dark"></span>
                      </div>
                    </div>
                  </CardBody>
                )}
              </Card>
            </Col>
          )}
        </Row>
      </div>



      <Modal
        isOpen={modalOpen}
        toggle={toggleModal}
        centered
        className="share-modal p-4"
        size="lg"
        scrollable={true}
      >
        <ModalBody
          className=""
          style={{
            backgroundImage:
              "linear-gradient(45deg, #d50080, rgb(255 101 194 / 84%))",
          }}
        >
          <div className=" text-white align-items-center hstack gap-0 justify-content-center">
            <h3 className="mb-0 text-white   text-center pb-0">Ready to </h3>
            <img src={logoWhite} className="" height={40}></img>
            <h3 className="mb-0 text-white ">? Get the App!</h3>
          </div>

          <Row className="mt-3">
            <Col lg={6} className="align-self-center">
              {/* <img
                src={IosQR}
                className="img-fluid p-5 pb-3 pt-2"
                style={{ borderRadius: "20px" }}
              ></img> */}
              <div className="d-flex justify-content-center">
                <img
                  className="text-center "
                  src={googlePlay}
                  height={70}
                ></img>
              </div>
              <div className="d-flex justify-content-center">
                <img src={appleStore} height={70}></img>
              </div>
            </Col>
            <Col lg={6}>
              <div className="d-flex justify-content-center">
                <img
                  height={200}
                  width={200}
                  src={AndroidQR}
                  className="img-fluid"
                ></img>
              </div>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </section>
          <Footer />
          </>
  );
};
export default JobShare;
