import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Badge,
  Button,
  Label,
  Row,
  Modal,
  ModalBody,
  Table,
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import DOMPurify from "dompurify";
import React, { useState, useRef, useEffect } from "react";
import { get_cookie } from "../../helpers/get_cookie";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import {useParams} from 'react-router-dom';
import Rating from "react-rating";
import moment from "moment/moment";
import logoWhite from "../../assets/images/Logo - White 1.png";
import AndroidQR from "../../assets/images/QR_Code/OpenApp.png";
import googlePlay from "../../assets/images/QR_Code/googlePlay.png";
import appleStore from "../../assets/images/QR_Code/appStore.png";
import profileBg from "../../assets/images/profile-bg.jpg";
import userDummy from "../../assets/images/users/user-dummy-img.jpg";
import workHistory from "../../assets/images/circle-briefcase.png";
import { Header } from "./common/ShareHeader";
import { Footer } from "./common/ShareFooter";

const ProfileShare = () => {
    const { id } = useParams();
    const decodedId = atob(id);
  const [modalOpen, setModalOpen] = useState(false);
  const [userId, setUserId] = useState(null);
  const [username, setUserName] = useState(null);
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [editsubalone, seteditsubalone] = useState();
  const [email, setEmail] = useState(null);
  const [contactNumber, setContactNumber] = useState(null);
  const [suburb, setSuburb] = useState(null);
  const [country, setCountry] = useState(null);
  const [fullAddress, setFullAddress] = useState(null);
  const [suburbWithState, setSuburbWithState] = useState(null);
  const [postalCode, setPostalCode] = useState(null);
  const [street, setStreet] = useState(null);
  const [isPortfolioEdit, setIsPortfolioEdit] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState({
    latitude: null,
    longitude: null,
  });
  const [review, setReview] = useState([]);

  const [isChecked, setIsChecked] = useState(false);

  const [userProfile, setUserProfile] = useState();
  const [userProfileImg, setUserProfileImg] = useState("");
  const [userRating, setuserRating] = useState(0);
  const [skills, setSkills] = useState([]);
  const [industryNames, setIndustryNames] = useState([]);

  const [selectedIndustry, setSelectedIndustry] = useState([]);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [userSkill, setUserSkill] = useState(null);

  const [selected, setSelected] = useState(["Barista"]);

  // side section states
  const [workforcePreferences, setWorkforcePreferences] = useState(null);
  const [hourlyRate, setHourlyRate] = useState("");
  const [monthlySalary, setMonthlySalary] = useState("");
  const [preferredWorkType, setPreferredWorkType] = useState("");
  const [workPreferrences, setWorkPreferrences] = useState([]);
  const [availableStartDate, setAvailableStartDate] = useState(null);
  const [profileSummary, setProfileSummary] = useState(null);
  const [profileTitle, setProfileTitle] = useState(null);
  const [currentAvailability, setCurrentAvailability] = useState(null);
  const [userhistory, setUserHistory] = useState();
  const [userlocation, setUserlocation] = useState(null);

  // Tooltip
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [workid, setWorkid] = useState();
  const [userEducation, setUserEducation] = useState(null);
  const [userCertificates, setUserCertificates] = useState(null);
  const [userPortfolio, setUserPortfolio] = useState(null);

  // preferences
  const [employeeChecked, setEmployeeChecked] = useState(false);
  const [contractorChecked, setContractorChecked] = useState(false);
  const [volunteerChecked, setVolunteerChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };
  // Assuming userProfileImg is the image URL
  const profleImageUrl = userProfileImg;

  useEffect(() => {
    fetchUserProfile();
  }, []);

  const fetchUserProfile = async (initial = false) => {
    try {
      if (initial) {
        setIsLoading(true);
      }
      if (get_cookie("authUser") !== false) {
        const user_id = JSON.parse(get_cookie("authUser")).user_id;
        console.log(user_id, "userid");
        setUserId(user_id);
        // Call userdetails API
        // const userResponse = await axios.get(`/userdetails/${user_id}`);

        const userResponse = await axios.get(`/userdetails/${decodedId}`);

        if (userResponse.data && userResponse.message === "Success") {
          // Assuming the API response has a property 'data' containing user details
          const userProfile = userResponse.data.user_profile[0];
          const workforcePreferences = userResponse.data.preferences;
          const userDetails = userResponse.data.user_details;
          const userhistory = userResponse.data.history;
          const Education = userResponse.data.education;
          const Certifications = userResponse.data.certificates;
          const Portfolio = userResponse.data.portfolio;
          const workprofileid = userResponse.data.workforce_profile_id;
          const preferredWorkLocations = userResponse.data.locations;
          const employeeReviews = userResponse.data.review;

          // Assuming the user details are nested under 'data'
          setUserProfile(userProfile);
          setUserHistory(userhistory);
          setUserEducation(Education);
          setUserPortfolio(Portfolio);
          setUserCertificates(Certifications);
          setWorkid(workprofileid);
          setUserlocation(preferredWorkLocations);
          setWorkPreferrences(workforcePreferences);
          setReview(employeeReviews);

          // Profile section
          if (userProfile) {
            const {
              first_name,
              last_name,
              suburb,
              make_profile_private,
              profile_pic,
              latitude,
              longitude,
              address,
              postcode,
              email,
              contact_number,
              star_rating,
              workforce_skills,
              workforce_preferences,
              workforce_industry,
              full_address,
              workforce_verification_documents,
              workforce_availability,
            } = userProfile;
            setUserName(`${first_name} ${last_name}`);
            setFirstName(first_name);
            setLastName(last_name);
            setUserProfileImg(profile_pic);
            setSuburbWithState(suburb);
            setSelectedLocation({ latitude: latitude, longitude: longitude });
            setStreet(address);
            setFullAddress(full_address);
            setPostalCode(postcode);
            setEmail(email);
            setIsChecked(make_profile_private === 1);
            setContactNumber(contact_number);
            setuserRating(star_rating);
            setUserSkill(workforce_skills);
            setWorkforcePreferences(workforce_preferences);
            setIndustryNames(workforce_industry);
            const defaultSelectedOptions = workforce_industry?.map((item) => ({
              value: item.id,
              label: item.name,
            }));
            const defaultSelectedSkillsOptions = workforce_skills?.map(
              (skill) => ({ value: skill.id, label: skill.name })
            );
            //
            setSelectedIndustry(defaultSelectedOptions);
            setSelectedSkills(defaultSelectedSkillsOptions);
            if (workforce_availability.length > 0) {
              setCurrentAvailability(workforce_availability[0]);
            }
          }

          // workpreferences
          if (workforcePreferences) {
            const {
              desired_hourly_rate,
              desired_monthly_salary,
              preferred_work_type,
              available_start_date,
              minimum_engagement_duration,
              profile_summary,
              profile_title,
              is_employee,
              is_contractor,
              is_volunteer,
            } = workforcePreferences;
            setHourlyRate(desired_hourly_rate);
            setMonthlySalary(desired_monthly_salary);
            setPreferredWorkType(preferred_work_type);
            setAvailableStartDate(available_start_date);
            setProfileSummary(profile_summary);
            setProfileTitle(profile_title);
            setEmployeeChecked(is_employee == 1 ? true : false);
            setContractorChecked(is_contractor == 1 ? true : false);
          }

          // Call get-skills API
          const skillsResponse = await axios.get("/get-skills");

          if (skillsResponse.data && skillsResponse.message === "Success") {
            // Assuming the skills are nested under 'data'
            const skillsData = skillsResponse.data;

            // Update state with the fetched skills
            setSkills(skillsData);

            // Log the skills to the console for debugging
            //
          } else {
            // Handle case where skills are not found
            console.error("Skills not found");
          }

          setIsLoading(false);
        } else {
          // Handle case where user details are not found
          console.error("User details not found");
        }
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching user profile:", error);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();
    return `${month} ${year}`;
  };
  return (
    <>
      <Card>
        <Header toggle={toggleModal} />
      </Card>

      <div className="container mb-2">
        <div className="profile-foreground container position-relative ">
          <div className="profile-wid-bg ">
            <img src={profileBg} alt="" className="profile-wid-img" />
          </div>
        </div>

        <div className="pt-4 mb-4 mb-lg-3 pb-lg-4 container profile-wrapper">
          <Row className="align-items-center">
            <div className="col-1   ">
              <div className="avatar-lg">
                {userProfileImg ? (
                  <img
                    src={profleImageUrl}
                    alt="user-img"
                    className="img-thumbnail h-100 w-100 rounded-circle"
                  />
                ) : (
                  <img
                    src={userDummy}
                    alt="user-img"
                    className="img-thumbnail rounded-circle"
                  />
                )}
              </div>
            </div>

            <Col
              className="col-lg-8 mt-3 py-4 pb-1 ps-4 "
              // style={{ borderRight: "0.5px solid #e8e8e8" }}
            >
              <div>
                {username && (
                  <div className="d-flex gap-2 mt-n2 justify-content-between">
                    <div className="d-flex gap-3 align-items-center ">
                      <h3 className="mb-0 text-white">{username} </h3>
                      <div className="d-flex align-items-center justify-content-end">
                        {[1, 2, 3, 4, 5].map((star) => {
                          const integerPart = Math.floor(userRating); // Integer part of the rating
                          const isHalfStar =
                            star === integerPart + 1 && userRating % 1 !== 0; // Check if it's a half star
                          const starClass = isHalfStar
                            ? "ri-star-half-s-fill" // Half star icon
                            : star <= integerPart
                            ? "ri-star-s-fill" // Full star icon
                            : "ri-star-s-line"; // Empty star icon

                          const starColor =
                            star <= integerPart || isHalfStar
                              ? "gold" // Color for filled and half stars
                              : "white"; // Color for empty stars

                          return (
                            <i
                              className={starClass}
                              key={star}
                              style={{
                                fontSize: "20px",
                                color: starColor, // Set color based on whether it's a full, half, or empty star
                                marginRight: "2px",
                              }}
                            ></i>
                          );
                        })}
                        <Badge
                          color="primary"
                          className="badgems-1 px-1 ms-2 bold fs-12 "
                          style={{ paddingBottom: "2.8px" }}
                        >
                          {userRating}
                        </Badge>
                      </div>
                    </div>

                    {/* <h6>{profileTitle && ( profileTitle )}</h6> */}
                  </div>
                )}
                {profileTitle && (
                  <p className="fs-14 mb-1 mt-1 text-white">{profileTitle}</p>
                )}
                {fullAddress && (
                  <div className="text-muted hstack gap-1 mb-1 align-items-center">
                    <i className="ri-map-pin-line me-1 fs-15 text-white"></i>
                    <span className="text-white-75 ">{fullAddress}</span>
                  </div>
                )}
              </div>
              <div className="hstack flex-wrap gap-2 mt-2">
                {selectedSkills && selectedSkills !== null ? (
                  selectedSkills?.map((skill) => {
                    return (
                      <span
                        key={skill.value}
                        className="badge badge-soft-light text-white fs-12 me-2"
                      >
                        {skill.label}
                      </span>
                    );
                  })
                ) : (
                  <span className="badge badge-soft-primary fs-12 me-2">
                    None
                  </span>
                )}
              </div>
            </Col>
            <Col className="col-3 vstack justify-content-end">
              {contactNumber && (
                <div className=" hstack gap-2 px-3  mt-4 text-right justify-content-end  text-white ">
                  <i className=" bx bx-phone fs-14"></i>
                  <span className="py-1 ">Phone: {contactNumber}</span>
                </div>
              )}
              {email && (
                <div className=" hstack gap-2 text-right  justify-content-end px-3  text-white ">
                  <i className=" ri-mail-line fs-14"></i>
                  <span className="py-1 "> {email}</span>
                </div>
              )}
            </Col>
          </Row>
        </div>

        <Row>
          <Col lg={4} className="ps-4">
            <Card>
              <CardHeader className="d-flex justify-content-start align-items-center">
                <i className="text-primary fs-18 me-1 bx bxs-wrench"></i>
                <h5 className="mb-0 text-primary">Preferences</h5>
              </CardHeader>
              <CardBody>
                <div className="table-responsive">
                  <Table className="table-borderless mb-0">
                    <tbody>
                      <tr>
                        <th className="ps-0 text-muted fw-normal" scope="row">
                          Industries
                        </th>
                        <td className="fw-normal pe-0">
                          {industryNames.length !== 0 ? (
                            industryNames.map((industry, index) => (
                              <span key={industry.id}>
                                {index > 0 && ", "}
                                {industry.name}
                              </span>
                            ))
                          ) : (
                            <span>-</span>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <th className="ps-0 text-muted fw-normal" scope="row">
                          Preferred Work Type
                        </th>
                        <td className="fw-normal pe-0">
                          {workPreferrences ? (
                            <>
                              {workPreferrences.is_employee !== 0 ||
                              workPreferrences.is_contractor !== 0 ||
                              workPreferrences.is_volunteer !== 0 ? (
                                <>
                                  {workPreferrences.is_employee !== 0 && (
                                    <span>Employee</span>
                                  )}
                                  {workPreferrences.is_contractor !== 0 && (
                                    <>
                                      {workPreferrences.is_employee !== 0 &&
                                        ", "}
                                      Contractor
                                    </>
                                  )}
                                  {workPreferrences.is_volunteer !== 0 && (
                                    <>
                                      {(workPreferrences.is_employee !== 0 ||
                                        workPreferrences.is_contractor !== 0) &&
                                        ", "}
                                      Volunteer
                                    </>
                                  )}
                                </>
                              ) : (
                                <span>-</span>
                              )}
                            </>
                          ) : (
                            <span>-</span>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <th className="ps-0 text-muted fw-normal" scope="row">
                          Hourly rate
                        </th>
                        <td className="fw-normal  pe-0">
                          {workPreferrences &&
                          workPreferrences?.desired_hourly_rate !== null ? (
                            `$${workPreferrences?.desired_hourly_rate}`
                          ) : (
                            <span>-</span>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <th className="ps-0 text-muted fw-normal" scope="row">
                          Monthly Salary
                        </th>
                        <td className="fw-normal pe-0">
                          {workPreferrences &&
                          workPreferrences.desired_monthly_salary !== null ? (
                            `$${workPreferrences?.desired_monthly_salary}`
                          ) : (
                            <span>-</span>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <th className="ps-0 text-muted fw-normal" scope="row">
                          Available Start Date
                        </th>
                        <td className="fw-normal pe-0">
                          {" "}
                          {workPreferrences && availableStartDate !== null ? (
                            moment(availableStartDate)?.format("MMMM D, Y")
                          ) : (
                            <span>-</span>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <th className="ps-0 text-muted fw-normal" scope="row">
                          Current Availability
                        </th>
                        <td className="fw-normal pe-0">
                          {" "}
                          {currentAvailability &&
                          currentAvailability?.total_hours !== null
                            ? currentAvailability?.total_hours
                            : 0}{" "}
                          Hrs
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </CardBody>
            </Card>
            <Card className="mb-2">
              <CardHeader className="d-flex justify-content-between">
                <div className="hstack">
                  <i className="text-primary me-1 fs-18 ri-medal-fill"></i>
                  <h4 className=" text-primary card-title flex-grow-1 mb-0 ">
                    Certifications
                  </h4>
                </div>
              </CardHeader>
              <CardBody>
                {userCertificates?.map((item, index) => {
                  return (
                    <>
                      <div key={index} className="certificate-container mb-3">
                        <div className="hstack justify-content-between">
                          <h6>{item.certification_name}</h6>
                        </div>
                        <div className="text-muted mt-1">
                          {item.issuing_authority}
                        </div>
                        <div className="d-flex  justify-content-between">
                          <div className="">
                            <span className="text-success">Issued on: </span>
                            <span className="text-gray">
                              {item.date_issued
                                ? moment(item.date_issued).format("MM-DD-YYYY")
                                : "-"}
                            </span>
                          </div>
                          <div className="hstack">
                            <span className="text-danger me-1">Expiry: </span>{" "}
                            <span
                              className="text-gray text-center"
                              style={{ width: "5rem" }}
                            >
                              {item.expiration_date
                                ? moment(item.expiration_date).format(
                                    "MM-DD-YYYY"
                                  )
                                : "--"}
                            </span>
                          </div>
                        </div>
                      </div>

                      {userCertificates.length != index + 1 && <hr />}
                    </>
                  );
                })}
              </CardBody>
            </Card>
          </Col>
          <Col lg={8} className="ps-1 pe-4">
            <Card className="mb-2">
              <CardHeader className="d-flex justify-content-start align-items-center">
                <i className="text-primary me-1 fs-18 ri-profile-line"></i>
                <h5 className=" text-primary mb-0   ">Profile Summary</h5>
              </CardHeader>

              <CardBody>
                <p
                  style={{ lineHeight: "1.5rem" }}
                  dangerouslySetInnerHTML={{
                    __html: profileSummary || " ",
                  }}
                ></p>
                {/* <p> This Page is Under Progress</p> */}
              </CardBody>
            </Card>

            <Card className="mb-2">
              <CardHeader className="d-flex align-items-center pb-2 pt-2 justify-content-between">
                <div className="hstack">
                  <i className="text-primary fs-18 me-1 ri-briefcase-4-fill"></i>
                  <h4 className="text-primary card-title flex-grow-1 mb-0">
                    Employment History
                  </h4>
                </div>
              </CardHeader>
              <CardBody>
                <div
                  className="profile-timeline "
                  style={{ maxHeight: "400px", overflowY: "scroll" }}
                >
                  <div className="accordion accordion-flush" id="todayExample">
                    {userhistory?.map((item, index) => {
                      return (
                        <>
                          <div key={index} className="accordion-item border-0">
                            <div className="accordion-header">
                              <div
                                className="accordion-item p-2 shadow-none"
                                id="headingOne"
                              >
                                <div className="d-flex">
                                  <div className="flex-shrink-0">
                                    <img
                                      src={workHistory}
                                      alt=""
                                      className="avatar-xs rounded-circle"
                                    />
                                  </div>
                                  <div className="flex-grow-1 ms-3">
                                    <div className="hstack gap-4 justify-content-between">
                                      <h5 className="mb-1">
                                        {item.role}, {item.company_name}
                                      </h5>
                                    </div>
                                    <p
                                      style={{ fontWeight: "normal" }}
                                      className="text-muted"
                                    >
                                      {item.location}
                                    </p>
                                    <p>
                                      {formatDate(item.start_date)} -{" "}
                                      {item.is_current == 1 ? (
                                        "Present"
                                      ) : (
                                        <>{formatDate(item.end_date)} </>
                                      )}
                                    </p>

                                    {item.description ? (
                                      <span
                                        className="text-dark employment-description"
                                        style={{ lineHeight: "1.5rem" }}
                                        dangerouslySetInnerHTML={{
                                          __html: DOMPurify.sanitize(
                                            item.description || ""
                                          ),
                                        }}
                                      ></span>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>
              </CardBody>
            </Card>

            <Card className="mb-2">
              <CardHeader className="d-flex align-items-center text-secondary pt-2 pb-2 justify-content-between">
                <div className="hstack">
                  <i className="bx bxs-graduation fs-18 text-primary me-1"></i>
                  <h4 className=" text-primary card-title flex-grow-1 mb-0 ">
                    Education
                  </h4>
                </div>
              </CardHeader>
              <CardBody>
                <div className="education-wrapper" style={{ maxHeight: "400px", overflowY: "scroll" }}>
                {userEducation?.map((item, index) => {
                  return (
                    <>
                      <div key={index} className="education-container pe-2 mb-3">
                        <div className="d-flex justify-content-between">
                          <h6>{item.institution_name}</h6>
                        </div>

                        <div className="d-flex justify-content-between">
                          <div>
                            <span> {item.degree}</span>
                            {item.degree && item.field_of_study && ","}
                            <span className="text-muted">
                              {" "}
                              {item.field_of_study}
                            </span>
                            <p>{item.achievements}</p>
                          </div>
                          <span className="text-gray">
                            {new Date(item.start_date).getFullYear()} -{" "}
                            {new Date(item.end_date).getFullYear()}
                          </span>
                        </div>
                      </div>

                      {userEducation.length != index + 1 && <hr className="calendar-hr" />}
                    </>
                  );
                })}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Modal
          isOpen={modalOpen}
          toggle={toggleModal}
          centered
          className="share-modal p-4"
          size="lg"
          scrollable={true}
        >
          <ModalBody
            className=""
            style={{
              backgroundImage:
                "linear-gradient(45deg, #d50080, rgb(255 101 194 / 84%))",
            }}
          >
            <div className=" text-white align-items-center hstack gap-0 justify-content-center">
              <h3 className="mb-0 text-white   text-center pb-0">Ready to </h3>
              <img src={logoWhite} className="" height={40}></img>
              <h3 className="mb-0 text-white ">? Get the App!</h3>
            </div>

            <Row className="mt-3">
              <Col lg={6} className="align-self-center">
                {/* <img
                src={IosQR}
                className="img-fluid p-5 pb-3 pt-2"
                style={{ borderRadius: "20px" }}
              ></img> */}
                <div className="d-flex justify-content-center">
                  <img
                    className="text-center "
                    src={googlePlay}
                    height={70}
                  ></img>
                </div>
                <div className="d-flex justify-content-center">
                  <img src={appleStore} height={70}></img>
                </div>
              </Col>
              <Col lg={6}>
                <div className="d-flex justify-content-center">
                  <img
                    height={200}
                    width={200}
                    src={AndroidQR}
                    className="img-fluid"
                  ></img>
                </div>
              </Col>
            </Row>
          </ModalBody>
        </Modal>
      </div>
      <Footer  />
    </>
  );
};

export default ProfileShare;
