import PrismCode from "../../Components/Common/Prism";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Accordion,
  AccordionItem,
  Collapse,
  UncontrolledTooltip,
  Spinner,
} from "reactstrap";
import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef,
} from "react";
import img13 from "../../assets/images/small/img-13.png";
import { Button } from "reactstrap";
import ShareModal from "./ShareSocial";
import { Link } from "react-router-dom";
import { isEmpty } from "lodash";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "../../assets/scss/pages/_settings.scss";
import ReactTable from "react-table";
import DeleteModal from "../../Components/Common/DeleteModal";
import CustomCopyModal from "../../Components/Common/CustomCopyModal";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import {
  CardHeader,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
//Import actions
import {
  getContacts as onGetContacts,
  addNewContact as onAddNewContact,
  updateContact as onUpdateContact,
  deleteContact as onDeleteContact,
} from "../../store/actions";
//redux
import { useSelector, useDispatch } from "react-redux";
import TableContainer from "../../Components/Common/TableContainer";
// Formik
import * as Yup from "yup";
import { useFormik } from "formik";
import Loader from "../../Components/Common/Loader";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
//reloading the page
import { jsx } from "@emotion/react";
import classnames from "classnames";
import Flatpickr from "react-flatpickr";
import { format } from "date-fns";
import GoogleAutocomplete from "react-google-autocomplete";
import Select from "react-select";
import moment from "moment";
import { get_cookie } from "../../helpers/get_cookie";
import { useNavigate } from 'react-router-dom';
//reloading the page
const override = jsx`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const JobVacancy = () => {
  document.title = "Job Vacancies | Get Rostered";
  const [modal, setModal] = useState(false);
  const business_id = JSON.parse(get_cookie("authUser")).business_id;
  const [isUpdating, setIsUpdating] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [authUserId, setAuthUserId] = useState();
  const [selectedCity, setSelectedcity] = useState();
  const [selectedSuburb, setselectedSuburb] = useState();
  const [selectedPostcode, setselectedPostcode] = useState();
  const [country, setCountry] = useState([]);
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [modal1, setModal1] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const [modalChangeStatus, setModalChangeStatus] = useState(false);
  const [modalCopy, setModalCopy] = useState(false);
  const [sys_status, setstatussys] = useState([]);
  const [job_title, setJobTitle] = useState("");
  const [job_description, setJobDescription] = useState("");
  const [job_location, setJobLocation] = useState("");
  const [salary_range_type, setSalaryRangeType] = useState("");
  const [salary_range_from, setSalaryRangeFrom] = useState("");
  const [salary_range_to, setSalaryRangeTo] = useState("");
  const [jobTitleError, setJobTitleError] = useState("");
  const [jobDescriptionError, setJobDescriptionError] = useState("");
  const [jobLocationError, setJobLocationError] = useState("");
  const [salaryRangeFromError, setSalaryRangeFromError] = useState("");
  const [salaryRangeToError, setSalaryRangeToError] = useState("");
  const [salaryRangeTypeError, setSalaryRangeTypeError] = useState("");
  const [jobStatusError, setJobStatusError] = useState("");
  const [defaultstatusset, setdefaultstatusset] = useState([]);
  const [job_status_id, setJobStatusId] = useState("");
  const [sys_skills, setSysSkills] = useState("");
  const [skill_id, setSkillId] = useState("");
  const [skillIdError, setSkillIdError] = useState("");
  const [close_date_error, setCloseDateError] = useState("");
  const defaultCloseDate = moment().add(30, "days").toDate();
  const [close_date, setCloseDate] = useState(defaultCloseDate);
  const [isShare, setShare] = useState(false);
  const [shareModal, setShareModal] = useState(false);
  const [jobId, setJobId] = useState();
  const [shareableLink, setShareableLink] = useState("");
  const [isGenerating, setIsGenerating] = useState(false);

  const toggleShareModal = () => {
    setShareModal(!shareModal);
  };
  const toggleModal1 = () => {
    setModal1(!modal1);
  };
  const toggleModalEdit = () => {
    setModalEdit(!modalEdit);
  };
  const toggleModalChangeStatus = () => {
    setModalChangeStatus(!modalChangeStatus);
  };
  const toggleModalCopy = () => {
    setModalCopy(!modalCopy);
  };
  useEffect(() => {
    axios
      .get("/get-user-details")
      .then((res) => {
        setAuthUserId(res.id);
        loadTable(res.id);
      })
      .then((err) => {
        console.log(err);
      });
  }, []);
  function handleSelectDay(selectedDay) {
    setSalaryRangeType(selectedDay);
  }
  function handleSelectStatus(selectedStatus) {
    setJobStatusId(selectedStatus);
  }

  const [col1, setcol1] = useState(false);
  const t_col1 = () => {
    setcol1(!col1);
  };
  const salaryType = [
    {
      options: [
        { label: "Hourly", value: "Hourly" },
        { label: "Weekly", value: "Weekly" },
        { label: "Monthly", value: "Monthly" },
        { label: "Yearly", value: "Yearly" },
      ],
    },
  ];
  const jobStatus = [
    { label: "Closed", value: 2 },
    { label: "Filled", value: 3 },
    { label: "On Hold", value: 4 },
  ];
  const residentialplace = (place) => {
    const addressComponents = place?.address_components;
    const { lat, lng } = place.geometry.location;
    const streetNumberComponent = addressComponents?.find((component) =>
      component.types.includes("street_number")
    );
    const streetRouteComponent = addressComponents?.find((component) =>
      component.types.includes("route")
    );
    const unitComponent = place?.addressComponents?.find((component) =>
      component.types.includes("subpremise")
    );
    const suburbComponent = addressComponents?.find((component) =>
      component.types.includes("locality")
    );
    const stateComponent = addressComponents?.find((component) =>
      component.types.includes("administrative_area_level_1")
    );
    const postcodeComponent = addressComponents?.find((component) =>
      component.types.includes("postal_code")
    );
    const countryComponent = addressComponents?.find((component) =>
      component.types.includes("country")
    );

    let selectedStreetNumber = "";
    if (streetNumberComponent) {
      selectedStreetNumber = streetNumberComponent.long_name;
    }
    let selectedUnitNumber = "";
    if (unitComponent) {
      selectedUnitNumber = unitComponent.long_name;
    }
    let selectedStreetRoute = "";
    if (streetRouteComponent) {
      selectedStreetRoute = streetRouteComponent.long_name;
    }
    let selectedSuburb = "";
    if (suburbComponent) {
      selectedSuburb = suburbComponent.long_name;
    }
    let selectedState = "";
    if (stateComponent) {
      selectedState = stateComponent.short_name;
    }
    let selectedPostcode = "";
    if (postcodeComponent) {
      selectedPostcode = postcodeComponent.long_name;
    }
    let selectedcountry = "";
    if (countryComponent) {
      selectedcountry = countryComponent.long_name;
    }
    setSelectedcity(
      `${selectedUnitNumber} ${selectedStreetNumber} ${selectedStreetRoute} `
    );
    setselectedSuburb(selectedSuburb + ", " + selectedState);
    setselectedPostcode(selectedPostcode);
    setCountry(selectedcountry);
    setJobLocation(selectedSuburb + ", " + selectedState);
    setLatitude(lat());
    setLongitude(lng());
  };
  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      tog_Company();
      setContact(null);
    } else {
      setModal(true);
      setTag([]);
      setAssignTag([]);
    }
  }, [modal]);

  const [rowsData, setRowsData] = useState([]);
  const addTableRows = () => {
    const rowsInput = {
      fullName: "",
      emailAddress: "",
      salary: "",
    };
    setRowsData([...rowsData, rowsInput]);
  };
  const [selectedstatus, setselectedstatus] = useState(null);

  const $status_array = {
    Open: "Open",
    Closed: "Closed",
    Filled: "Filled",
    OnHold: "OnHold",
    Draft: "Draft",
  };
  const StatusOption = Object.keys($status_array).map((status) => ({
    value: status,
    label: $status_array[status],
  }));

  const handlestatusMulti = (selectedOptions) => {
    setselectedstatus(selectedOptions);
    console.log(selectedOptions);
  };

  const [contact, setContact] = useState([]);
  const dispatch = useDispatch();
  //react
  const isContactSuccess = true;
  const error = null;
  const [data, setData] = useState([]);
  const crmcontacts = data;
  const loadTable = (userId, value = "") => {
    const statusIds = selectedStatus
      ? selectedStatus.map((status) => status.value)
      : [];
    const formData = {
      user_id: userId,
      business_id: business_id,
      status_id: statusIds,
    };

    setisLoading(true);
    axios
      .post("/jobvacancieslist", formData)
      .then((response) => {
        console.log(response, "heyyylowww");
        setData(response.data);
        if (value != "search") {
          setstatussys(response.statussys.status_sys);
        }
        setdefaultstatusset(response.defaultstatusset.default_status_set);
        setSysSkills(response.sys_skills);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setisLoading(false);
      });
  };

  const [selectedStatus, setSelectedStatus] = useState([]);
  const [statusOptions, setStatusOptions] = useState([]);

  useEffect(() => {
    if (sys_status && sys_status.length > 0) {
      const defaultValues = sys_status
        .filter((status) => status.id === 1 || status.id === 5)
        .map((status) => ({ value: status.id, label: status.status_name }));
      setSelectedStatus(defaultValues);

      // Update statusOptions based on all.statussys
      const dynamicStatusOptions = sys_status.map((status) => ({
        value: status.id,
        label: status.status_name,
      }));
      setStatusOptions(dynamicStatusOptions);
    }
  }, [sys_status]);

  const handleStatusMulti = (selectedOptions) => {
    setSelectedStatus(selectedOptions);
  };

  const handleSearch = () => {
    console.log("Search button clicked!");
    // Handle the search functionality, using selectedStatus
    console.log("Selected status for search:", selectedStatus);
    loadTable(authUserId, "search");
  };

  const handleReset = () => {
    // Set default values or clear selected options based on your requirements
    const defaultValues = sys_status
      .filter((status) => status.id === 1 || status.id === 5)
      .map((status) => ({ value: status.id, label: status.status_name }));
    setSelectedStatus([]);
  };

  const skillOptions =
    sys_skills &&
    sys_skills.map((skill) => ({
      value: skill.id,
      label: skill.name,
    }));

  const handleChange = (selectedOption) => {
    setSkillId(selectedOption);
    setSkillIdError("");
  };
  const handleChangeEdit = (selectedOption) => {
    setSkillId(selectedOption);
    setSkillIdError("");
  };
  const handleChangeCopy = (selectedOption) => {
    setSkillId(selectedOption);
  };

  //input field values
  const [company_name, setCompanyName] = useState("");
  const [email, setEmail] = useState("");
  const [contact_name, setContactName] = useState("");
  const [phone, setPhone] = useState("");
  const [sms_number, setSmsNumber] = useState("");
  const [abn, setAbn] = useState("");
  const [payment_terms, setPaymentTerms] = useState("");
  const [customer_sign_off_checklist, setCustomerSign] = useState("");
  const [customer_pre_job_checklist, setCustomerPre] = useState("");
  const [work_order_instructions, setWorkOrderInstructions] = useState("");
  const [pod_instructions, setPodInstructions] = useState("");
  const [defaultInterest, setDefaultInterest] = useState("N");
  const [activeInterest, setActiveInterest] = useState("N");
  const [imagesel, setImage] = useState("");
  const [id, setId] = useState("");
  const [shouldShowIdField, setShouldShowIdField] = useState(false);
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const [uploadedImage, setUploadedImage] = useState("");
  const [vacancy_status_id, setVacancyStatusId] = useState("");
  //checkbox code
  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;

    if (name === "default") {
      setDefaultInterest(checked ? "Y" : "N");
    } else if (name === "active") {
      setActiveInterest(checked ? "Y" : "N");
    }
  };
  //react
  useEffect(() => {
    setContact(crmcontacts);
  }, [crmcontacts]);

  useEffect(() => {
    if (!isEmpty(crmcontacts)) {
      setContact(crmcontacts);
      setIsEdit(false);
    }
  }, [crmcontacts]);

  const editorRef = React.useRef(null);

  const [isEdit, setIsEdit] = useState(false);
  // Delete Data
  const handleDeleteContact = () => {
    if (contact) {
      dispatch(onDeleteContact(contact._id));
      setDeleteModal(false);
    }
  };
  const onClickDelete = (contact) => {
    setContact(contact);
    setDeleteModal(true);
  };
  //delete Conatct
  const [deleteModal, setDeleteModal] = useState(false);
  const [customCopyModal, setCustomCopyModal] = useState(false);
  const [deleteModalMulti, setDeleteModalMulti] = useState(false);

  const [modal_large, setModal_Company] = useState(false);
  function tog_Company() {
    setModal_Company(!modal_large);
  }

  // Add Data
  const handleContactClicks = () => {
    setContact("");
    //setIsEdit(false);
    toggle();
  };
  // Date & Time Format
  const dateFormat = () => {
    var d = new Date(),
      months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
    return d.getDate() + " " + months[d.getMonth()] + ", " + d.getFullYear();
  };

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      // img: (contact && contact.img) || '',
      name: (contact && contact.name) || "",
      company: "",
      designation: "",
      email: (contact && contact.email) || "",
      phone: (contact && contact.phone) || "",
      lead_score: (contact && contact.lead_score) || "",
      tags: (contact && contact.tags) || [],
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Name"),
      company: Yup.string().required("Please Enter Company"),
      designation: Yup.string().required("Please Enter Designation"),
      email: Yup.string().required("Please Enter Email"),
      phone: Yup.string().required("Please Enter Phone"),
      lead_score: Yup.string().required("Please Enter lead_score"),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateContact = {
          _id: contact ? contact._id : 0,
          // img: values.img,
          name: values.name,
          company: values.company,
          designation: values.designation,
          email: values.email,
          phone: values.phone,
          lead_score: values.lead_score,
          last_contacted: dateFormat(),
          // time: timeFormat(),
          tags: assignTag,
        };
        // update Contact
        dispatch(onUpdateContact(updateContact));
        validation.resetForm();
      } else {
        const newContact = {
          _id: (Math.floor(Math.random() * (30 - 20)) + 20).toString(),
          // img: values["img"],
          name: values["name"],
          company: values["company"],
          designation: values["designation"],
          email: values["email"],
          phone: values["phone"],
          lead_score: values["lead_score"],
          last_contacted: dateFormat(),
          // time: timeFormat(),
          tags: assignTag,
        };
        // save new Contact
        dispatch(onAddNewContact(newContact));
        validation.resetForm();
      }
      toggle();
    },
  });

  // Update Data
  const handleContactClick = useCallback(
    (arg) => {
      const contact = arg;

      setContact({
        _id: contact._id,
        // img: contact.img,
        name: contact.name,
        company: contact.company,
        email: contact.email,
        designation: contact.designation,
        phone: contact.phone,
        lead_score: contact.lead_score,
        last_contacted: contact.date,
        // time: contact.time,
        tags: contact.tags,
      });

      setIsEdit(true);
      toggle();
    },
    [toggle]
  );

  const [order, setOrder] = useState([]);
  const handleOrderClick = useCallback(
    (arg) => {
      const order = arg;
      setOrder({
        _id: order._id,
        orderId: order.orderId,
        customer: order.customer,
        product: order.product,
        orderDate: order.orderDate,
        ordertime: order.ordertime,
        amount: order.amount,
        payment: order.payment,
        status: order.status,
      });

      setIsEdit(true);
      toggle();
    },
    [toggle]
  );

  // Checked All
  const checkedAll = useCallback(() => {
    const checkall = document.getElementById("checkBoxAll");
    const ele = document.querySelectorAll(".contactCheckBox");

    if (checkall.checked) {
      ele.forEach((ele) => {
        ele.checked = true;
      });
    } else {
      ele.forEach((ele) => {
        ele.checked = false;
      });
    }
    deleteCheckbox();
  }, []);

  // Delete Multiple
  const [selectedCheckBoxDelete, setSelectedCheckBoxDelete] = useState([]);
  const [isMultiDeleteButton, setIsMultiDeleteButton] = useState(false);

  const deleteMultiple = () => {
    const checkall = document.getElementById("checkBoxAll");
    selectedCheckBoxDelete.forEach((element) => {
      dispatch(onDeleteContact(element.value));
      setTimeout(() => {
        toast.clearWaitingQueue();
      }, 3000);
    });
    setIsMultiDeleteButton(false);
    checkall.checked = false;
  };

  const deleteCheckbox = () => {
    const ele = document.querySelectorAll(".contactCheckBox:checked");
    ele.length > 0
      ? setIsMultiDeleteButton(true)
      : setIsMultiDeleteButton(false);
    setSelectedCheckBoxDelete(ele);
  };
  const handleDeleteOrder = () => {
    var orderIds = selectedOrderId;
    axios
      .get(`${"api/companies/destroy/"}${orderIds}`)
      .then((response) => {
        toast.success(response.message, { theme: "light" });
        loadTable();
        setDeleteModal(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const openDuplicateModal = (orderId) => {
    setCustomCopyModal(true);
    setSelectedOrderId(orderId);
  };
  const handleDuplicate = (orderId) => {
    setCustomCopyModal(false);
    setisLoading(true);
    toggleModalCopy(true);
    setSkillId("");
    var formData = {
      user_id: authUserId,
      business_id: business_id,
      id: selectedOrderId,
    };
    axios
      .post("/get-jobvacancies-data", formData)
      .then((response) => {
        console.log(response);
        setJobTitle(response.job_vacancies_data.title);
        setJobDescription(response.job_vacancies_data.description);
        setJobLocation(response.job_vacancies_data.location);
        setSalaryRangeType(response.job_vacancies_data.salary_range_type);
        setSalaryRangeFrom(response.job_vacancies_data.salary_range_from);
        setSalaryRangeTo(response.job_vacancies_data.salary_range_to);
        setVacancyStatusId(response.job_vacancies_data.vacancy_status_id);
        setLatitude(response.job_vacancies_data.latitude);
        setLongitude(response.job_vacancies_data.longitude);
        if (Array.isArray(sys_skills)) {
          const skillOptions = sys_skills.map((skill) => ({
            value: skill.id,
            label: skill.name,
          }));
          const selectedSkillOptions = response.job_vacancies_data.skills
            .map((skillId) => {
              const selectedSkillOption = skillOptions.find(
                (option) => option.value === skillId.skill_id
              );
              return selectedSkillOption
                ? { value: skillId.skill_id, label: selectedSkillOption.label }
                : null;
            })
            .filter(Boolean); // Filter out null values
          setSkillId(selectedSkillOptions);
          setCloseDate(
            moment(response.job_vacancies_data.close_date).format(
              "DD MMM, YYYY"
            )
          );
          setJobTitleError("");
          setJobDescriptionError("");
          setJobLocationError("");
          setSalaryRangeFromError("");
          setSalaryRangeToError("");
          setSalaryRangeTypeError("");
          setJobStatusError("");
          setSkillIdError("");
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setisLoading(false);
      });
  };

  const [selectedRow, setSelectedRow] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  const handleRowClick = (rowData) => {
    setSelectedRow(rowData);
    setModalOpen(true);
  };
  const openCreateModal = () => {
    setId("");
    setJobTitle("");
    setJobDescription("");
    setJobLocation("");
    setSalaryRangeType("");
    setSalaryRangeFrom("");
    setSalaryRangeTo("");
    setselectedSuburb("");
    setLatitude("");
    setLongitude("");
    setSkillId("");
    setCloseDate(defaultCloseDate);
    setJobTitleError("");
    setJobDescriptionError("");
    setJobLocationError("");
    setSalaryRangeFromError("");
    setSalaryRangeToError("");
    setSalaryRangeTypeError("");
    setJobStatusError("");
    setSkillIdError("");
    setCloseDateError("");
    setModal(true);
  };

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  //react
  const smscolumn = useMemo(
    () => [
      {
        Header: "Title",
        accessor: "Title",
        Cell: ({ row }) => (
          <div
            onClick={() => handleRowClick(row.original)}
            style={{ color: "rgb(134, 92, 226)", cursor: "pointer" }}
          >
            {row.original.Title}
          </div>
        ),
        filterable: false,
      },
      {
        Header: "Location",
        accessor: "location",
        filterable: false,
      },

      {
        Header: "Salary  Type",
        accessor: "salary_range_type",
        filterable: false,
      },

      {
        Header: "Salary",
        accessor: (row) => {
          const from =
            row.salary_range_from !== null
              ? `$${row.salary_range_from}`
              : "$00.00";
          const to =
            row.salary_range_to !== null ? `$${row.salary_range_to}` : "$00.00";
          return `${from} - ${to}`;
        },
        filterable: false,
      },
      {
        Header: "Status",
        accessor: "status_name",
        filterable: false,
      },

      {
        Header: "Post Date",
        accessor: "post_date",
        filterable: false,
        Cell: ({ value }) => {
          // Check if value is null or undefined
          if (value == null) {
            return "00/00/0000";
          }

          // Format the date using date-fns
          const formattedDate = format(new Date(value), "dd MMM yyyy");
          return formattedDate;
        },
      },
      // Assuming this is inside a React component or function
      {
        Header: "Close Date",
        accessor: "close_date",
        filterable: false,
        Cell: ({ value }) => {
          // Check if value is null or undefined
          if (value == null) {
            return "00/00/0000";
          }

          // Format the date using date-fns
          const formattedDate = format(new Date(value), "dd MMM yyyy");
          return formattedDate;
        },
      },

      {
        Header: "Action",
        Cell: (cellProps) => {
          const site_id = cellProps.row.original.id;
          const statusName = cellProps.row.original.status_name;
          return (
            <div className="d-flex gap-3">
              <button
                className="btn btn-sm btn-soft-danger copy-list"
                onClick={() => openDuplicateModal(site_id)}
              >
                <i
                  className="ri-file-copy-fill fs-12 pt-1"
                  id="copytooltip"
                ></i>
              </button>
              <UncontrolledTooltip placement="top" target="copytooltip">
                Duplicate
              </UncontrolledTooltip>
              {(statusName === "Open" || statusName === "Draft") && (
                <React.Fragment>
                  <button
                    className="btn btn-sm btn-soft-warning edit-list"
                    onClick={() => {
                      const orderData = cellProps.row.original;
                      const orderId = orderData.id;
                      setModalEdit(true);
                      setisLoading(true);
                      setSkillId("");
                      var formData = {
                        user_id: authUserId,
                        business_id: business_id,
                        id: orderId,
                      };
                      axios
                        .post("/get-jobvacancies-data", formData)
                        .then((response) => {
                          setId(response.job_vacancies_data.id);
                          setJobTitle(response.job_vacancies_data.title);
                          setJobDescription(
                            response.job_vacancies_data.description
                          );
                          setJobLocation(response.job_vacancies_data.location);
                          setSalaryRangeType(
                            response.job_vacancies_data.salary_range_type
                          );
                          setSalaryRangeFrom(
                            response.job_vacancies_data.salary_range_from
                          );
                          setSalaryRangeTo(
                            response.job_vacancies_data.salary_range_to
                          );
                          setVacancyStatusId(
                            response.job_vacancies_data.vacancy_status_id
                          );
                          setLatitude(response.job_vacancies_data.latitude);
                          setLongitude(response.job_vacancies_data.longitude);
                          if (Array.isArray(response.sys_skills)) {
                            const skillOptions = response.sys_skills.map(
                              (skill) => ({
                                value: skill.id,
                                label: skill.name,
                              })
                            );
                            const selectedSkillOptions =
                              response.job_vacancies_data.skills
                                .map((skillId) => {
                                  const selectedSkillOption = skillOptions.find(
                                    (option) =>
                                      option.value === skillId.skill_id
                                  );
                                  return selectedSkillOption
                                    ? {
                                        value: skillId.skill_id,
                                        label: selectedSkillOption.label,
                                      }
                                    : null;
                                })
                                .filter(Boolean); // Filter out null values
                            setSkillId(selectedSkillOptions);
                            setCloseDate(
                              moment(
                                response.job_vacancies_data.close_date
                              ).format("DD MMM, YYYY")
                            );
                            setJobTitleError("");
                            setJobDescriptionError("");
                            setJobLocationError("");
                            setSalaryRangeFromError("");
                            setSalaryRangeToError("");
                            setSalaryRangeTypeError("");
                            setJobStatusError("");
                            setSkillIdError("");
                          }
                        })
                        .catch((error) => {
                          console.error(error);
                        })
                        .finally(() => {
                          setisLoading(false);
                        });
                    }}
                  >
                    <i
                      className="bx bxs-pencil fs-12 pt-1"
                      id="edittooltip"
                    ></i>
                  </button>

                  <UncontrolledTooltip placement="top" target="edittooltip">
                    Edit
                  </UncontrolledTooltip>
                </React.Fragment>
              )}
              {statusName === "Open" && (
                <React.Fragment>
                  <button
                    className="btn btn-sm btn-soft-info edit-list"
                    onClick={() => {
                      const orderData = cellProps.row.original;
                      const orderId = orderData.id;
                      setModalChangeStatus(true);
                      var formData = {
                        user_id: authUserId,
                        business_id: business_id,
                        id: orderId,
                      };
                      axios
                        .post("/get-jobvacancies-data", formData)
                        .then((response) => {
                          setId(response.job_vacancies_data.id);
                        })
                        .catch((error) => {
                          console.error(error);
                        });
                    }}
                  >
                    <i
                      className="ri-exchange-line fs-12 pt-1"
                      id="viewtooltip"
                    ></i>
                  </button>

                  <UncontrolledTooltip placement="top" target="viewtooltip">
                    Change Status
                  </UncontrolledTooltip>
                </React.Fragment>
              )}
              <React.Fragment>
                <button
                  className="btn btn-sm btn-soft-success edit-list"
                  onClick={() => {
                    setShareModal(true);
                    const base64Id = btoa(cellProps.row.original.id);
                    const link = `http://devbusiness.getrostered.app/job/${base64Id}`;
                    //const link = `http://localhost:3000/job/${base64Id}`;
                    console.log(link);
                    setJobId(cellProps.row.original.id);
                    setShareableLink(link);
                  }}
                >
                  <i
                    className="bx bx-share-alt fs-12 pt-1"
                    id="sharetooltip"
                  ></i>
                </button>

                <UncontrolledTooltip placement="top" target="sharetooltip">
                  Share
                </UncontrolledTooltip>
              </React.Fragment>
            </div>
          );
        },
      },
    ],
    [handleContactClick, checkedAll]
  );
  //react
  const [tag, setTag] = useState([]);
  const [assignTag, setAssignTag] = useState([]);

  // Export Modal
  const [isExportCSV, setIsExportCSV] = useState(false);

  //image selection
  const fileInputRef = useRef(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isImageSelected, setIsImageSelected] = useState(false);

  const handleButtonClick = (event) => {
    event.preventDefault();
    fileInputRef.current.click();
  };
  const handleFileInputChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const objectUrl = URL.createObjectURL(selectedFile);
      setUploadedImage(selectedFile);
      setSelectedImage(objectUrl);
      setIsImageSelected(true);
    }
  };
  const handleRemoveClick = () => {
    setSelectedImage(null);
    setUploadedImage(null);
    setIsImageSelected(false);
    fileInputRef.current.value = null; // Clear the input field value
  };
  const handleChangeClick = (event) => {
    event.preventDefault();
    fileInputRef.current.click();
  };
  //Save and edit Job Vacancies
  const handleSubmit = (event) => {
    console.log(salaryRangeToError, " salary to range error");
    event.preventDefault();
    setisLoading(false);
    /* validation */
    const currentDate = new Date();
    const selectedDate = new Date(close_date);
    if (job_title === "") {
      setJobTitleError("Please enter job title.");
      setisLoading(false);
    } else {
      setJobTitleError("");
    }
    if (job_description === "" || job_description === null) {
      setJobDescriptionError("Please enter job description.");
      toast.error("Please enter job description.");
      setisLoading(false);
    } else {
      setJobDescriptionError("");
    }
    if (job_location === "" || job_location === null) {
      setJobLocationError("Please enter job location.");
      setisLoading(false);
    } else {
      setJobLocationError("");
    }
    if (salary_range_type === "" || salary_range_type === null) {
      setSalaryRangeTypeError("Please select salary range type.");
      setisLoading(false);
    } else {
      setSalaryRangeTypeError("");
    }
    if (
      salary_range_from === "" ||
      salary_range_from === null ||
      salary_range_from.length > 10
    ) {
      setSalaryRangeFromError("Please enter salary range from.");
      setisLoading(false);
    } else {
      setSalaryRangeFromError("");
    }
    if (
      salary_range_to === "" ||
      salary_range_to === null ||
      salary_range_to.length > 10
    ) {
      setSalaryRangeToError("Please enter salary range to.");
      setisLoading(false);
    } else {
      setSalaryRangeToError("");
    }
    if (salary_range_from < 0) {
      setSalaryRangeFromError("negative value present");
    }
    if (parseFloat(salary_range_from) >= parseFloat(salary_range_to)) {
      setSalaryRangeToError("a");
    }

    if (skill_id === "" || skill_id === null || skill_id.length === 0) {
      setSkillIdError("Please select skills.");
      setisLoading(false);
    } else {
      setSkillIdError("");
    }
    if (
      close_date === "" ||
      close_date === null ||
      close_date === "Invalid date"
    ) {
      setCloseDateError("Please select close date.");
      setisLoading(false);
    } else {
      setCloseDateError("");
    }
    if (selectedDate < currentDate) {
      setCloseDateError("Close date cannot be in the past");
      setisLoading(false);
      return; // Exit function if validation fails
    }
    console.log(salaryRangeToError, " salary to range error");
    if (
      job_title === "" ||
      job_description === "" ||
      job_description === null ||
      job_location === "" ||
      job_location === null ||
      salary_range_type === "" ||
      salary_range_type === null ||
      salary_range_from === "" ||
      salary_range_from === null ||
      salary_range_to === "" ||
      salary_range_to === null ||
      skill_id === "" ||
      skill_id === null ||
      skill_id.length === 0 ||
      salary_range_from.length > 10 ||
      salary_range_to.length > 10 ||
      close_date === "" ||
      close_date === null ||
      close_date === "Invalid date" ||
      salaryRangeFromError != "" ||
      salaryRangeToError != "" ||
      parseFloat(salary_range_from) >= parseFloat(salary_range_to)
    ) {
      // If any of the required fields are empty, do not proceed with form submission.
      return;
    }
    var formData = {
      user_id: authUserId,
      business_id: business_id,
      title: job_title,
      description: job_description,
      location: job_location,
      latitude: latitude,
      longitude: longitude,
      salary_range_type: salary_range_type,
      salary_range_from: salary_range_from,
      salary_range_to: salary_range_to,
      skill_id: skill_id,
      close_date: close_date,
      post: 1,
    };
    if (id) {
      formData.id = id;
    }
    console.log(formData);
    axios
      .post("/post-job-data", formData)
      .then((response) => {
        // toast.success(response.success, { theme: "light" });
        if (response.result == "updated") {
          toast.success(response.message, { theme: "light" });
          setModalEdit(false);
        } else if (response.result == "created") {
          setModal(false);
          toast.success(response.message, { theme: "light" });
          setId("");
          setJobTitle("");
          setJobDescription("");
          setJobLocation("");
          setselectedSuburb("");
          setSalaryRangeType("");
          setSalaryRangeFrom("");
          setSalaryRangeTo("");
          setLatitude("");
          setLongitude("");
          setSkillId("");
        }

        loadTable(authUserId);
      })
      .catch((error) => {
        console.error(error);
        toast.error("Something went wrong...");
      })
      .finally(() => {
        setisLoading(false); //reloading the page
      });
  };
  const saveJobDraft = (event) => {
    event.preventDefault();
    setJobDescriptionError("");
    setJobLocationError("");
    setSalaryRangeFromError("");
    setSalaryRangeToError("");
    setSalaryRangeTypeError("");
    setJobStatusError("");
    setSkillIdError("");
    setCloseDateError("");
    /* validation */
    const currentDate = new Date();
    const selectedDate = new Date(close_date);
    if (job_title === "") {
      setJobTitleError("Please enter job title.");
    } else {
      setJobTitleError("");
    }
    if (selectedDate < currentDate) {
      setCloseDateError("Close date cannot be in the past");
      return; // Exit function if validation fails
    }
    if (job_title === "") {
      return;
    }
    var formData = {
      user_id: authUserId,
      business_id: business_id,
      title: job_title,
      description: job_description,
      location: job_location,
      latitude: latitude,
      longitude: longitude,
      salary_range_type: salary_range_type,
      salary_range_from: salary_range_from,
      salary_range_to: salary_range_to,
      skill_id: skill_id,
      close_date: close_date,
      save_draft_job: true,
    };
    if (id) {
      formData.id = id;
    }
    axios
      .post("/post-job-data", formData)
      .then((response) => {
        toast.success(response.success, { theme: "light" });
        if (response.result === "edit_draft") {
          toast.success(response.message, { theme: "light" });
          setModalEdit(false);
        } else if (response.result === "save_draft") {
          toast.success(response.message, { theme: "light" });
          setModal(false);
          setModalCopy(false);
        }
        setId("");
        setJobTitle("");
        setJobDescription("");
        setJobLocation("");
        setSalaryRangeType("");
        setSalaryRangeFrom("");
        setSalaryRangeTo("");
        setselectedSuburb("");
        setLatitude("");
        setLongitude("");
        setSkillId("");
        loadTable(authUserId);
      })
      .catch((error) => {
        setisLoading(false);
        console.error(error);
        toast.error("Something went wrong...");
      })
      .finally(() => {
        setisLoading(false); //reloading the page
      });
  };
  const duplicateDataToDraft = (event) => {
    event.preventDefault();
    /* validation */
    if (job_title === "") {
      setJobTitleError("Please enter job title.");
    } else {
      setJobTitleError("");
    }
    const currentDate = new Date();
    const selectedDate = new Date(close_date);
    if (selectedDate < currentDate) {
      setCloseDateError("Close date cannot be in the past");
      return; // Exit function if validation fails
    }
    if (job_title === "") {
      return;
    }
    var formData = {
      user_id: authUserId,
      business_id: business_id,
      title: job_title,
      description: job_description,
      location: job_location,
      latitude: latitude,
      longitude: longitude,
      salary_range_type: salary_range_type,
      salary_range_from: salary_range_from,
      salary_range_to: salary_range_to,
      skill_id: skill_id,
      close_date: close_date,
      save_draft_job: true,
    };
    axios
      .post("/post-job-data", formData)
      .then((response) => {
        if (response.result == "save_draft") {
          toast.success(response.message, { theme: "light" });
        }
        setModalCopy(false);
        setId("");
        setJobTitle("");
        setJobDescription("");
        setJobLocation("");
        setSalaryRangeType("");
        setSalaryRangeFrom("");
        setSalaryRangeTo("");
        setselectedSuburb("");
        setLatitude("");
        setLongitude("");
        setSkillId("");
        loadTable(authUserId);
      })
      .catch((error) => {
        console.error(error);
        toast.error("Something went wrong...");
      })
      .finally(() => {
        setisLoading(false); //reloading the page
      });
  };
  const changeVacancyStatus = (event) => {
    event.preventDefault();
    /* validation */
    if (job_status_id === "") {
      setJobStatusError("Please select job status.");
    } else {
      setJobStatusError("");
    }
    if (job_status_id === "") {
      return;
    }
    var formData = {
      user_id: authUserId,
      business_id: business_id,
      id: id,
      vacancy_status_id: job_status_id,
    };
    axios
      .post("/update-job-vacacncy-status", formData)
      .then((response) => {
        toast.success(response.success, { theme: "light" });
        setModalChangeStatus(false);
        loadTable(authUserId);
      })
      .catch((error) => {
        console.error(error);
        toast.error("Something went wrong...");
      })
      .finally(() => {
        setisLoading(false); //reloading the page
      });
  };  

  const handleSuggestDescription = () => {
    setIsGenerating(true);
    // Your logic to interact with ChatGPT-4 API or AI service to suggest a job description
    console.log("handle suggestion here");
    const postData = {
      jobRole: job_title,
      user_id: authUserId,
      business_id: business_id,
    };
    axios
      .post("generate-job-description", postData)
      .then((res) => {
        console.log(res);
        const formattedDescription = res.jobDescription.replace(/\n/g, "<br>");
        setJobDescription(formattedDescription);
        setIsGenerating(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <React.Fragment>
      <div>
        <div>
          <Modal
            isOpen={modalOpen}
            toggle={toggleModal}
            centered
            size="lg"
            scrollable={true}
          >
            {selectedRow && (
              <ModalBody className="">
                <h5 className="text-primary pb-0">{selectedRow.Title}</h5>
                <div className="d-flex justify-content-between  pt-0 pb-2 ">
                  <div className="mt-3">
                    <label
                      htmlFor="firstName"
                      className="form-label hstack fs-14"
                    >
                      <i className="text-danger bx bx-map-pin fs-18 me-1"></i>{" "}
                      {selectedRow.location}
                    </label>
                  </div>
                  <div className="mt-3">
                    <label
                      htmlFor="firstName"
                      className="form-label hstack fs-14"
                    >
                      <i className="text-success bx bx-dollar-circle fs-18 me-1"></i>{" "}
                      Salary Offered: $
                      {selectedRow.salary_range_from
                        ? `${selectedRow.salary_range_from}`
                        : "$0.00"}{" "}
                      - $
                      {selectedRow.salary_range_to
                        ? `${selectedRow.salary_range_to}`
                        : "$0.00"}
                    </label>{" "}
                  </div>
                </div>
                <hr className="mt-1" />
                <div className="row ">
                  <div className="mt-2">
                    <span
                      dangerouslySetInnerHTML={{
                        __html: selectedRow.description,
                      }}
                    ></span>
                  </div>

                  <div className="hstack gap-2 justify-content-end">
                    <Button color="btn btn-light" onClick={toggleModal}>
                      Close
                    </Button>
                  </div>
                </div>
              </ModalBody>
            )}
          </Modal>
          <ShareModal
            shareableLink={shareableLink}
            isOpen={shareModal}
            toggle={toggleShareModal}
          />
        </div>
      </div>
      <div className="page-content">
        <Container fluid>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <BreadCrumb title="Job Vacancies" />
           
            <button
              className="btn btn-success"
              style={{
                backgroundColor: "#865ce2",
                borderColor: "#f8f5ff",
              }}
              onClick={openCreateModal}
            >
              Create New Vacancies
            </button>
          </div>
          <DeleteModal
            show={deleteModal}
            orderId={selectedOrderId}
            onDeleteClick={handleDeleteOrder}
            onCloseClick={() => setDeleteModal(false)}
          />
          <CustomCopyModal
            show={customCopyModal}
            orderId={selectedOrderId}
            onDeleteClick={handleDuplicate}
            onCloseClick={() => setCustomCopyModal(false)}
          />
          <Row className="mt-3">
            <Col lg={12}>
              {/* <Card>
                <CardHeader>
                  <div className="d-flex align-items-center flex-wrap gap-2">
                    <div className="d-flex justify-content-between align-items-center flex-grow-1">
                      <h5
                        className="mb-0"
                        style={{
                          color: "black",
                          fontSize: "1.5em",
                          color: "black",
                        }}
                      >
                        <i className="bx bxs-user-account" /> Job Vacancies
                      </h5>
                      <button
                        className="btn btn-success"
                        style={{
                          backgroundColor: "#865ce2",
                          borderColor: "#f8f5ff",
                        }}
                        onClick={openCreateModal}
                      >
                        Create New Vacancies
                      </button>
                    </div>
                    <div className="flex-shrink-0">
                      <div className="hstack text-nowrap gap-2">
                        {isMultiDeleteButton && (
                          <button
                            className="btn btn-danger"
                            onClick={() => setDeleteModalMulti(true)}
                          >
                            <i className="ri-delete-bin-2-line"></i>
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </CardHeader>
              </Card> */}
              <Accordion id="default-accordion-example">
                <AccordionItem>
                  <h2 className="accordion-header" id="headingOne">
                    <button
                      className={classnames("accordion-button fw-semibold", {
                        collapsed: !col1,
                      })}
                      type="button"
                      onClick={t_col1}
                      style={{ cursor: "pointer" }}
                    >
                      Search Filters
                    </button>
                  </h2>
                  <Collapse
                    isOpen={col1}
                    className="accordion-collapse"
                    id="collapseOne"
                  >
                    <div className="accordion-body">
                      <Row className="d-flex justify-content-between">
                        <Col md={9}>
                          <div className="mb-3">
                            <Label for="leadStatusinput" className="form-label">
                              Job Vacancy Status
                            </Label>
                            <Select
                              isMulti={true}
                              onChange={handleStatusMulti}
                              options={statusOptions}
                              value={selectedStatus}
                            />
                          </div>
                        </Col>

                        <Col md={3}>
                          <div className="mt-3 d-flex gap-2">
                            <Button
                              className="btn btn-success"
                              style={{
                                backgroundColor: "#865ce2",
                                borderColor: "#f8f5ff",
                                marginTop: "10px",
                                //  padding: "1% 2%",
                              }}
                              onClick={handleSearch}
                            >
                              Search
                            </Button>
                            <Button
                              className="btn btn-secondary"
                              style={{
                                backgroundColor: "#A9A9A9",
                                borderColor: "#A9A9A9",
                                marginTop: "10px",
                                //  padding: "1% 2%",
                              }}
                              onClick={handleReset}
                            >
                              Reset
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Collapse>
                </AccordionItem>
              </Accordion>

              <Card id="contactList">
                <CardBody className="pt-0">
                  <div>
                    {isContactSuccess && crmcontacts.length ? (
                      <TableContainer
                        columns={smscolumn}
                        data={crmcontacts || []}
                        isGlobalFilter={true}
                        isAddUserList={false}
                        customPageSize={100}
                        className="custom-header-css"
                        divClass="table-responsive table-card mb-3"
                        tableClass="align-middle table-nowrap"
                        theadClass="table-light"
                        handleContactClick={handleContactClicks}
                        isContactsFilter={true}
                        SearchPlaceholder="Search by title, location, salary type..."
                      />
                    ) : (
                      crmcontacts.length === 0 && (
                        <p style={{ textAlign: "center", marginTop: "10px" }}>
                          No records found
                        </p>
                      )
                    )}
                  </div>
                  <ToastContainer closeButton={false} limit={1} />
                </CardBody>
              </Card>
              <Modal
                className="zoomIn"
                id="addSeller"
                size="lg"
                isOpen={modal}
                toggle={toggle}
                centered
              >
                <ModalHeader toggle={toggle}>Job Vacancy</ModalHeader>
                <div className="modal-content border-0 mt-3"></div>
                <ModalBody>
                  <Row>
                    <Col lg={12}>
                      <div className="mb-3">
                        <Label for="firstnameInput" className="form-label">
                          Job Title
                        </Label>
                        <Input
                          type="text"
                          value={job_title}
                          onChange={(e) => {
                            setJobTitle(e.target.value);
                            setJobTitleError("");
                          }}
                          className={`form-control ${
                            jobTitleError ? "is-invalid" : ""
                          }`}
                          id="firstnameInput"
                          placeholder="Enter job title"
                        />
                        {jobTitleError && (
                          <div className="invalid-feedback">
                            {jobTitleError}
                          </div>
                        )}
                      </div>
                    </Col>
                    <Col lg={12}>
                      <div className="mb-3">
                        <Label
                          for="exampleFormControlTextarea1"
                          className="form-label hstack gap-2 justify-content-between"
                        >
                          <span>Job Description</span>

                          {!isGenerating ? (
                            <span
                              className={`${
                                job_title.length > 2 ? "example-2 " : ""
                              }`}
                              style={{
                                cursor:
                                  job_title.length > 2 ? "pointer" : "default",
                              }}
                              onClick={() => {
                                if (job_title.length > 2) {
                                  setIsGenerating(true);
                                  handleSuggestDescription();
                                }
                              }}
                            >
                              <span
                                className={`badge text-primary inner hstack gap-2 p-2 align-items-center animated-border `}
                                id="suggest-description"
                                style={{
                                  backgroundColor:
                                    job_title.length > 2
                                      ? "#f0e9ff"
                                      : "#ededed",
                                }}
                              >
                                <i className="fs-16 ri-magic-line text-warning"></i>
                                <span>Suggest a job description using AI*</span>
                                {job_title.length > 2 ? null : (
                                  <UncontrolledTooltip
                                    placement="bottom"
                                    target="suggest-description"
                                  >
                                    Please enter a job title for suggestions
                                  </UncontrolledTooltip>
                                )}
                              </span>
                            </span>
                          ) : (
                            <span
                              className="badge text-primary inner hstack gap-2 p-2 align-items-center animated-border"
                              style={{ backgroundColor: "#f0e9ff" }}
                            >
                              <span className="fs-12">Generating </span>{" "}
                              <Spinner
                                color="primary"
                                style={{
                                  width: "0.8rem",
                                  height: "0.8rem",
                                }}
                                type="grow"
                              ></Spinner>
                              <Spinner
                                color="primary"
                                style={{
                                  width: "0.6rem",
                                  height: "0.6rem",
                                }}
                                type="grow"
                              ></Spinner>
                              <Spinner
                                color="primary"
                                style={{
                                  width: "0.4rem",
                                  height: "0.4rem",
                                }}
                                type="grow"
                              ></Spinner>
                            </span>
                          )}
                        </Label>
                        <CKEditor
                          className={`form-control ${
                            jobDescriptionError ? "is-invalid" : ""
                          }`}
                          editor={ClassicEditor}
                          data={job_description}
                          config={{
                            placeholder:
                              "Craft a comprehensive job description that captures the essence of this role. Highlight key attributes you're seeking in a candidate, such as specific skills, personality traits, required qualifications, and location preferences. Please include any crucial details that will help candidates understand the role better. This description is not just a guide for prospective applicants but also aids our AI in accurately matching your vacancy with the most suitable candidates in our talent pool. The more detailed your description, the better the match will be!",
                          }}
                          onReady={(editor) => {
                            editorRef.current = editor;
                          }}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            console.log(data);
                            setJobDescription(data);
                            setJobDescriptionError("");
                          }}
                        />
                        {jobDescriptionError && (
                          <div className="invalid-feedback">
                            {jobDescriptionError}
                          </div>
                        )}
                      </div>
                    </Col>
                    <Col lg={12}>
                      <div className="mb-3">
                        <Label for="phonenumberInput" className="form-label">
                          Work Location
                        </Label>
                        <GoogleAutocomplete
                          className={`${jobLocationError ? "is-invalid" : ""}`}
                          apiKey="AIzaSyB2SMtaVBlqC5v72gqS716BX8R5oXklaFc"
                          value={job_location}
                          onChange={(e) => {
                            setJobLocation(e.target.value);
                            setJobLocationError("");
                          }}
                          onPlaceSelected={(place) => residentialplace(place)}
                          options={{
                            types: ["geocode"],
                            componentRestrictions: {
                              country: "au",
                            },
                          }}
                          style={{
                            width: "100%",
                            border: "1px solid #ccc",
                            padding: "10px",
                            borderRadius: "5px",
                            position: "relative",
                            zIndex: 9999,
                          }}
                        />
                        {jobLocationError && (
                          <div className="invalid-feedback">
                            {jobLocationError}
                          </div>
                        )}
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="mb-3">
                        <Label for="cityidInput" className="form-label">
                          Salary Range{" "}
                          <button
                            className="btn btn"
                            onClick={() => {
                              toggleModal1();
                            }}
                          >
                            <i className="ri-questionnaire-fill me-1 align-bottom"></i>
                          </button>
                        </Label>
                        <Select
                          className={`${
                            salaryRangeTypeError ? "is-invalid" : ""
                          }`}
                          value={salaryType[0].options.find(
                            (option) => option.value === salary_range_type
                          )}
                          onChange={(selectedOption) => {
                            handleSelectDay(selectedOption.value);
                            setSalaryRangeTypeError("");
                          }}
                          options={salaryType[0].options}
                          id="day"
                        ></Select>
                        {salaryRangeTypeError && (
                          <div className="invalid-feedback">
                            {salaryRangeTypeError}
                          </div>
                        )}
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="mb-3">
                        <div
                          style={{ marginTop: "45px", position: "relative" }}
                          className="input-group"
                        >
                          <span className="input-group-text">$</span>
                          <Input
                            type="number"
                            value={salary_range_from}
                            onChange={(e) => {
                              setSalaryRangeFrom(e.target.value);
                              setSalaryRangeFromError("");
                              const userInput = e.target.value;

                              // Regular expression to match up to 10 digits with up to 2 decimal places
                              const regex = /^\d{0,8}(\.\d{0,2})?$/;
                              if (
                                parseFloat(e.target.value) >=
                                parseFloat(salary_range_to)
                              ) {
                                setSalaryRangeToError("a");
                              } else if (
                                regex.test(userInput) ||
                                userInput === ""
                              ) {
                                setSalaryRangeFrom(userInput);
                                setSalaryRangeFromError("");
                                setSalaryRangeToError("");
                              } else {
                                // If input doesn't match the pattern, display an error
                                setSalaryRangeFromError(
                                  "Maximum 10 digits with up to 2 decimal places allowed"
                                );
                              }
                            }}
                            className={`form-control ${
                              salaryRangeFromError ? "is-invalid" : ""
                            }`}
                            id="zipcodeidInput"
                            placeholder="0.00"
                            style={{ paddingLeft: "20px" }}
                          />
                          {salaryRangeFromError && (
                            <div className="invalid-feedback"></div>
                          )}
                        </div>
                      </div>
                    </Col>
                    <Col lg={1}>
                      <div className="mb-3">
                        <Label
                          for="cityidInput"
                          className="form-label"
                          style={{ marginTop: "52px", paddingLeft: "8px" }}
                        >
                          To
                        </Label>
                      </div>
                    </Col>
                    <Col lg={3}>
                      <div className="mb-3">
                        <div
                          style={{ marginTop: "45px", position: "relative" }}
                          className="input-group"
                        >
                          <span className="input-group-text">$</span>
                          <Input
                            value={salary_range_to}
                            onChange={(e) => {
                              setSalaryRangeTo(e.target.value);

                              const userInput = e.target.value;
                              // Regular expression to match up to 10 digits with up to 2 decimal places
                              const regex = /^\d{0,8}(\.\d{0,2})?$/;

                              if (
                                parseFloat(salary_range_from) >=
                                parseFloat(e.target.value)
                              ) {
                                setSalaryRangeToError("a");
                              } else if (
                                regex.test(userInput) ||
                                userInput === ""
                              ) {
                                setSalaryRangeTo(userInput);
                                setSalaryRangeToError("");
                              } else {
                                // If input doesn't match the pattern, display an error
                                setSalaryRangeToError(
                                  "Maximum 10 digits with up to 2 decimal places allowed"
                                );
                              }
                            }}
                            type="number"
                            className={`form-control ${
                              salaryRangeToError ? "is-invalid" : ""
                            }`}
                            id="zipcodeidInput"
                            placeholder="0.00"
                            style={{ paddingLeft: "20px" }}
                          />
                          {salaryRangeToError && (
                            <div className="invalid-feedback"></div>
                          )}
                        </div>
                      </div>
                    </Col>
                    <Col md={12}>
                      <div className="mb-3">
                        <Label for="leadStatusinput" className="form-label">
                          Skills
                        </Label>
                        <Select
                          className={`${skillIdError ? "is-invalid" : ""}`}
                          value={skill_id}
                          isMulti={true}
                          onChange={handleChange}
                          options={skillOptions}
                          placeholder="Select a skill..."
                        />
                        {skillIdError && (
                          <div className="invalid-feedback">{skillIdError}</div>
                        )}
                      </div>
                    </Col>
                    <Col lg={12}>
                      <div className="mb-3">
                        <Label for="birthdayidInput" className="form-label">
                          Close Date
                        </Label>
                        <Flatpickr
                          className={`form-control ${
                            close_date_error ? "is-invalid" : ""
                          }`}
                          options={{
                            dateFormat: "d M, Y",
                          }}
                          onChange={(selectedDates) => {
                            setCloseDate(
                              moment(selectedDates[0]).format("DD MMM, YYYY")
                            );
                            setCloseDateError("");
                          }}
                          value={close_date}
                          placeholder="Select Date"
                        />
                        {close_date_error && (
                          <div className="invalid-feedback">
                            {close_date_error}
                          </div>
                        )}
                      </div>
                    </Col>
                    <Col lg={12}>
                      <Row>
                        <div className="hstack gap-2 justify-content-end">
                          <Col lg={6}>
                            <button
                              type="submit"
                              className="btn btn-light"
                              style={{ color: "#865ce2" }}
                              onClick={saveJobDraft}
                            >
                              <i className="ri-save-3-line align-bottom me-1"></i>{" "}
                              Save Draft
                            </button>
                          </Col>
                          <Col
                            lg={6}
                            className="d-flex justify-content-end gap-2"
                          >
                            <Button
                              className="btn btn-light"
                              data-bs-dismiss="modal"
                              onClick={toggle}
                            >
                              Close
                            </Button>
                            <button
                              type="submit"
                              className="btn btn-primary"
                              onClick={handleSubmit}
                            >
                              <i className="ri-save-3-line align-bottom me-1"></i>{" "}
                              Post Job
                            </button>
                          </Col>
                        </div>
                      </Row>
                    </Col>
                  </Row>
                </ModalBody>
              </Modal>
              <Modal
                className="zoomIn"
                id="addSeller1"
                size="md"
                isOpen={modal1}
                toggle={toggleModal1}
                centered
              >
                <ModalHeader toggle={toggleModal1}>Salary Range</ModalHeader>
                <ModalBody>
                  Enter the desired salary range, selecting between hourly,
                  weekly, monthly or yearly rates. Ensure the minimum rate
                  complies with the National Minimum Wage as outlined by Fair
                  Work Australia. For the latest wage standards and guidelines,
                  visit{" "}
                  <a href="https://www.fairwork.gov.au/pay/minimum-wages">
                    Fair Work Australia's Minimum Wages page
                  </a>
                  . Consider setting rates that reflect the role's requirements
                  and the qualifications needed, while keeping industry
                  standards in mind."
                </ModalBody>
              </Modal>
              {isLoading ? (
                <>
                  <Modal
                    className="zoomIn"
                    id="addSeller"
                    size="lg"
                    isOpen={modalEdit}
                    toggle={toggleModalEdit}
                    centered
                  >
                    <ModalHeader toggle={toggleModalEdit}>
                      Edit Job Vacancy
                    </ModalHeader>
                    <div className="modal-content border-0 mt-3"></div>
                    <ModalBody>
                      <Row>
                        <h3 className="card-title placeholder-glow">
                          <span className="placeholder placeholder-sm  col-2 bg-dark"></span>
                        </h3>
                        <h3 className="card-title placeholder-glow">
                          <span className="placeholder placeholder-xs  col-4 bg-dark"></span>
                        </h3>
                        <h3 className="card-title placeholder-glow">
                          <span className="placeholder placeholder-xs  col-6 bg-dark"></span>
                        </h3>
                        <h3 className="card-title placeholder-glow">
                          <span className="placeholder placeholder-sm  col-2 bg-dark"></span>
                        </h3>
                        <h3 className="card-title placeholder-glow">
                          <span className="placeholder placeholder-xs  col-6 bg-dark"></span>
                        </h3>
                        <h3 className="card-title placeholder-glow">
                          <span className="placeholder placeholder-sm  col-7 bg-dark"></span>
                        </h3>
                        <h3 className="card-title placeholder-glow">
                          <span className="placeholder placeholder-xs  col-9 bg-dark"></span>
                        </h3>
                        <h3 className="card-title placeholder-glow">
                          <span className="placeholder placeholder-sm  col-2 bg-dark"></span>
                        </h3>
                        <h3 className="card-title placeholder-glow">
                          <span className="placeholder placeholder-xs  col-4 bg-dark"></span>
                        </h3>
                        <h3 className="card-title placeholder-glow">
                          <span className="placeholder placeholder-xs  col-6 bg-dark"></span>
                        </h3>
                        <h3 className="card-title placeholder-glow">
                          <span className="placeholder placeholder-sm  col-2 bg-dark"></span>
                        </h3>
                        <h3 className="card-title placeholder-glow">
                          <span className="placeholder placeholder-xs  col-6 bg-dark"></span>
                        </h3>
                        <h3 className="card-title placeholder-glow">
                          <span className="placeholder placeholder-sm  col-7 bg-dark"></span>
                        </h3>
                        <h3 className="card-title placeholder-glow">
                          <span className="placeholder placeholder-xs  col-9 bg-dark"></span>
                        </h3>
                        <h3 className="card-title placeholder-glow">
                          <span className="placeholder placeholder-sm  col-2 bg-dark"></span>
                        </h3>
                        <h3 className="card-title placeholder-glow">
                          <span className="placeholder placeholder-xs  col-4 bg-dark"></span>
                        </h3>
                        <h3 className="card-title placeholder-glow">
                          <span className="placeholder placeholder-xs  col-6 bg-dark"></span>
                        </h3>
                        <h3 className="card-title placeholder-glow">
                          <span className="placeholder placeholder-sm  col-2 bg-dark"></span>
                        </h3>
                        <h3 className="card-title placeholder-glow">
                          <span className="placeholder placeholder-xs  col-6 bg-dark"></span>
                        </h3>
                        <h3 className="card-title placeholder-glow">
                          <span className="placeholder placeholder-sm  col-7 bg-dark"></span>
                        </h3>
                        <h3 className="card-title placeholder-glow">
                          <span className="placeholder placeholder-xs  col-9 bg-dark"></span>
                        </h3>
                      </Row>
                    </ModalBody>
                  </Modal>
                </>
              ) : (
                <>
                  <Modal
                    className="zoomIn"
                    id="addSeller"
                    size="lg"
                    isOpen={modalEdit}
                    toggle={toggleModalEdit}
                    centered
                  >
                    <ModalHeader toggle={toggleModalEdit}>
                      Edit Job Vacancy
                    </ModalHeader>
                    <div className="modal-content border-0 mt-3"></div>
                    <ModalBody>
                      <Row>
                        <Col lg={12}>
                          <div className="mb-3">
                            <Input
                              type="hidden"
                              value={id}
                              onChange={(e) => {
                                setId(e.target.value);
                              }}
                              id="firstnameInput"
                            />
                            <Label for="firstnameInput" className="form-label">
                              Job Title
                            </Label>
                            <Input
                              type="text"
                              value={job_title}
                              onChange={(e) => {
                                setJobTitle(e.target.value);
                                setJobTitleError("");
                              }}
                              className={`form-control ${
                                jobTitleError ? "is-invalid" : ""
                              }`}
                              id="firstnameInput"
                              placeholder="Enter job title"
                            />
                            {jobTitleError && (
                              <div className="invalid-feedback">
                                {jobTitleError}
                              </div>
                            )}
                          </div>
                        </Col>
                        <Col lg={12}>
                          <div className="mb-3">
                            <Label
                              for="exampleFormControlTextarea1"
                              className="form-label hstack gap-2"
                            >
                              Job Description
                              {job_title && job_title.length > 2 && (
                                <span
                                  className="example-2"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    handleSuggestDescription();
                                  }}
                                >
                                  <span
                                    className="badge text-primary inner hstack gap-2 p-2 align-items-center animated-border"
                                    style={{ backgroundColor: "#f0e9ff" }}
                                  >
                                    <i className="fs-16 ri-magic-line textqaw-warning"></i>
                                    <span>
                                      Suggest a job description using AI*
                                    </span>
                                  </span>
                                </span>
                              )}
                            </Label>
                            <CKEditor
                              className={`form-control ${
                                jobDescriptionError ? "is-invalid" : ""
                              }`}
                              editor={ClassicEditor}
                              data={job_description || ""}
                              config={{
                                placeholder:
                                  "Craft a comprehensive job description that captures the essence of this role. Highlight key attributes you're seeking in a candidate, such as specific skills, personality traits, required qualifications, and location preferences. Please include any crucial details that will help candidates understand the role better. This description is not just a guide for prospective applicants but also aids our AI in accurately matching your vacancy with the most suitable candidates in our talent pool. The more detailed your description, the better the match will be!",
                              }}
                              onReady={(editor) => {}}
                              onChange={(event, editor) => {
                                const data = editor.getData();
                                setJobDescription(data);
                                setJobDescriptionError("");
                              }}
                            />
                            {jobDescriptionError && (
                              <div className="invalid-feedback">
                                {jobDescriptionError}
                              </div>
                            )}
                          </div>
                        </Col>

                        <Col lg={12}>
                          <div className="mb-3">
                            <Label
                              for="phonenumberInput"
                              className="form-label"
                            >
                              Work Location
                            </Label>
                            <GoogleAutocomplete
                              className={`${
                                jobLocationError ? "is-invalid" : ""
                              }`}
                              apiKey="AIzaSyB2SMtaVBlqC5v72gqS716BX8R5oXklaFc"
                              value={job_location}
                              onChange={(e) => {
                                setJobLocation(e.target.value);
                                setJobLocationError("");
                              }}
                              onPlaceSelected={(place) =>
                                residentialplace(place)
                              }
                              options={{
                                types: ["geocode"],
                                componentRestrictions: {
                                  country: "au",
                                },
                              }}
                              style={{
                                width: "100%",
                                border: "1px solid #ccc",
                                padding: "10px",
                                borderRadius: "5px",
                                position: "relative",
                                zIndex: 9999,
                              }}
                            />
                            {jobLocationError && (
                              <div className="invalid-feedback">
                                {jobLocationError}
                              </div>
                            )}
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="mb-3">
                            <Label for="cityidInput" className="form-label">
                              Salary Range{" "}
                              <button
                                className="btn btn"
                                onClick={() => {
                                  toggleModal1();
                                }}
                              >
                                <i className="ri-questionnaire-fill me-1 align-bottom"></i>
                              </button>
                            </Label>
                            <Select
                              className={`${
                                salaryRangeTypeError ? "is-invalid" : ""
                              }`}
                              value={salaryType[0].options.find(
                                (option) => option.value === salary_range_type
                              )}
                              onChange={(selectedOption) => {
                                handleSelectDay(selectedOption.value);
                                setSalaryRangeTypeError("");
                              }}
                              options={salaryType[0].options}
                              id="day"
                            ></Select>
                            {salaryRangeTypeError && (
                              <div className="invalid-feedback">
                                {salaryRangeTypeError}
                              </div>
                            )}
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="mb-3">
                            <div
                              style={{
                                marginTop: "45px",
                                position: "relative",
                              }}
                              className="input-group"
                            >
                              <span className="input-group-text">$</span>
                              <Input
                                type="number"
                                value={salary_range_from}
                                onChange={(e) => {
                                  setSalaryRangeFrom(e.target.value);
                                  setSalaryRangeFromError("");
                                  const userInput = e.target.value;

                                  // Regular expression to match up to 10 digits with up to 2 decimal places
                                  const regex = /^\d{0,8}(\.\d{0,2})?$/;

                                  if (
                                    parseFloat(e.target.value) >=
                                    parseFloat(salary_range_to)
                                  ) {
                                    setSalaryRangeToError("a");
                                  } else if (
                                    regex.test(userInput) ||
                                    userInput === ""
                                  ) {
                                    setSalaryRangeFrom(userInput);
                                    setSalaryRangeFromError("");
                                    setSalaryRangeToError("");
                                  } else {
                                    // If input doesn't match the pattern, display an error
                                    setSalaryRangeFromError(
                                      "Maximum 10 digits with up to 2 decimal places allowed"
                                    );
                                  }
                                }}
                                className={`form-control ${
                                  salaryRangeFromError ? "is-invalid" : ""
                                }`}
                                id="zipcodeidInput"
                                placeholder="0.00"
                                style={{ paddingLeft: "20px" }}
                              />
                              {salaryRangeFromError && (
                                <div className="invalid-feedback"></div>
                              )}
                            </div>
                          </div>
                        </Col>
                        <Col lg={1}>
                          <div className="mb-3">
                            <Label
                              for="cityidInput"
                              className="form-label"
                              style={{ marginTop: "52px", paddingLeft: "8px" }}
                            >
                              To
                            </Label>
                          </div>
                        </Col>
                        <Col lg={3}>
                          <div className="mb-3">
                            <div
                              style={{
                                marginTop: "45px",
                                position: "relative",
                              }}
                              className="input-group"
                            >
                              <span className="input-group-text">$</span>
                              <Input
                                value={salary_range_to}
                                onChange={(e) => {
                                  setSalaryRangeTo(e.target.value);

                                  const userInput = e.target.value;
                                  // Regular expression to match up to 10 digits with up to 2 decimal places
                                  const regex = /^\d{0,8}(\.\d{0,2})?$/;

                                  if (
                                    parseFloat(salary_range_from) >=
                                    parseFloat(e.target.value)
                                  ) {
                                    setSalaryRangeToError("a");
                                  } else if (
                                    regex.test(userInput) ||
                                    userInput === ""
                                  ) {
                                    setSalaryRangeTo(userInput);
                                    setSalaryRangeToError("");
                                  } else {
                                    // If input doesn't match the pattern, display an error
                                    setSalaryRangeToError(
                                      "Maximum 10 digits with up to 2 decimal places allowed"
                                    );
                                  }
                                }}
                                type="number"
                                className={`form-control ${
                                  salaryRangeToError ? "is-invalid" : ""
                                }`}
                                id="zipcodeidInput"
                                placeholder="0.00"
                                style={{ paddingLeft: "20px" }}
                              />
                              {salaryRangeToError && (
                                <div className="invalid-feedback"></div>
                              )}
                            </div>
                          </div>
                        </Col>
                        <Col md={12}>
                          <div className="mb-3">
                            <Label for="leadStatusinput" className="form-label">
                              Skills
                            </Label>
                            <Select
                              className={`${skillIdError ? "is-invalid" : ""}`}
                              value={skill_id}
                              isMulti={true}
                              onChange={handleChangeEdit}
                              options={skillOptions}
                              placeholder="Select a skill..."
                            />
                            {skillIdError && (
                              <div className="invalid-feedback">
                                {skillIdError}
                              </div>
                            )}
                          </div>
                        </Col>
                        <Col lg={12}>
                          <div className="mb-3">
                            <Label for="birthdayidInput" className="form-label">
                              Close Date
                            </Label>
                            <Flatpickr
                              className={`form-control ${
                                close_date_error ? "is-invalid" : ""
                              }`}
                              options={{
                                dateFormat: "d M, Y",
                              }}
                              onChange={(selectedDates) => {
                                setCloseDate(
                                  moment(selectedDates[0]).format(
                                    "DD MMM, YYYY"
                                  )
                                );
                                setCloseDateError("");
                              }}
                              value={close_date}
                              placeholder="Select Date"
                            />
                            {close_date_error && (
                              <div className="invalid-feedback">
                                {close_date_error}
                              </div>
                            )}
                          </div>
                        </Col>
                        <Col lg={12}>
                          <Row>
                            <div className="hstack gap-2 justify-content-end">
                              <Col lg={6}>
                                <button
                                  type="submit"
                                  className="btn btn-light"
                                  style={{ color: "#865ce2" }}
                                  onClick={saveJobDraft}
                                  disabled={vacancy_status_id !== 5}
                                >
                                  <i className="ri-save-3-line align-bottom me-1"></i>{" "}
                                  Update Draft
                                </button>
                              </Col>
                              <Col
                                lg={6}
                                className="d-flex justify-content-end gap-2"
                              >
                                <Button
                                  className="btn btn-light"
                                  data-bs-dismiss="modal"
                                  onClick={toggleModalEdit}
                                >
                                  Close
                                </Button>
                                <button
                                  type="submit"
                                  className="btn btn-primary"
                                  onClick={handleSubmit}
                                  disabled={
                                    vacancy_status_id !== 1 &&
                                    vacancy_status_id !== 5
                                  }
                                >
                                  <i className="ri-save-3-line align-bottom me-1"></i>{" "}
                                  Publish Job
                                </button>
                              </Col>
                            </div>
                          </Row>
                        </Col>
                      </Row>
                    </ModalBody>
                  </Modal>
                </>
              )}
              {isLoading ? (
                <>
                  <Modal
                    className="zoomIn"
                    id="addSeller"
                    size="lg"
                    isOpen={modalCopy}
                    toggle={toggleModalCopy}
                    centered
                  >
                    <ModalHeader toggle={toggleModalCopy}>
                      Edit Job Vacancy
                    </ModalHeader>
                    <div className="modal-content border-0 mt-3"></div>
                    <ModalBody>
                      <Row>
                        <h3 className="card-title placeholder-glow">
                          <span className="placeholder placeholder-sm  col-2 bg-dark"></span>
                        </h3>
                        <h3 className="card-title placeholder-glow">
                          <span className="placeholder placeholder-xs  col-4 bg-dark"></span>
                        </h3>
                        <h3 className="card-title placeholder-glow">
                          <span className="placeholder placeholder-xs  col-6 bg-dark"></span>
                        </h3>
                        <h3 className="card-title placeholder-glow">
                          <span className="placeholder placeholder-sm  col-2 bg-dark"></span>
                        </h3>
                        <h3 className="card-title placeholder-glow">
                          <span className="placeholder placeholder-xs  col-6 bg-dark"></span>
                        </h3>
                        <h3 className="card-title placeholder-glow">
                          <span className="placeholder placeholder-sm  col-7 bg-dark"></span>
                        </h3>
                        <h3 className="card-title placeholder-glow">
                          <span className="placeholder placeholder-xs  col-9 bg-dark"></span>
                        </h3>
                        <h3 className="card-title placeholder-glow">
                          <span className="placeholder placeholder-sm  col-2 bg-dark"></span>
                        </h3>
                        <h3 className="card-title placeholder-glow">
                          <span className="placeholder placeholder-xs  col-4 bg-dark"></span>
                        </h3>
                        <h3 className="card-title placeholder-glow">
                          <span className="placeholder placeholder-xs  col-6 bg-dark"></span>
                        </h3>
                        <h3 className="card-title placeholder-glow">
                          <span className="placeholder placeholder-sm  col-2 bg-dark"></span>
                        </h3>
                        <h3 className="card-title placeholder-glow">
                          <span className="placeholder placeholder-xs  col-6 bg-dark"></span>
                        </h3>
                        <h3 className="card-title placeholder-glow">
                          <span className="placeholder placeholder-sm  col-7 bg-dark"></span>
                        </h3>
                        <h3 className="card-title placeholder-glow">
                          <span className="placeholder placeholder-xs  col-9 bg-dark"></span>
                        </h3>
                        <h3 className="card-title placeholder-glow">
                          <span className="placeholder placeholder-sm  col-2 bg-dark"></span>
                        </h3>
                        <h3 className="card-title placeholder-glow">
                          <span className="placeholder placeholder-xs  col-4 bg-dark"></span>
                        </h3>
                        <h3 className="card-title placeholder-glow">
                          <span className="placeholder placeholder-xs  col-6 bg-dark"></span>
                        </h3>
                        <h3 className="card-title placeholder-glow">
                          <span className="placeholder placeholder-sm  col-2 bg-dark"></span>
                        </h3>
                        <h3 className="card-title placeholder-glow">
                          <span className="placeholder placeholder-xs  col-6 bg-dark"></span>
                        </h3>
                        <h3 className="card-title placeholder-glow">
                          <span className="placeholder placeholder-sm  col-7 bg-dark"></span>
                        </h3>
                        <h3 className="card-title placeholder-glow">
                          <span className="placeholder placeholder-xs  col-9 bg-dark"></span>
                        </h3>
                      </Row>
                    </ModalBody>
                  </Modal>
                </>
              ) : (
                <>
                  <Modal
                    className="zoomIn"
                    id="addSeller"
                    size="lg"
                    isOpen={modalCopy}
                    toggle={toggleModalCopy}
                    centered
                  >
                    <ModalHeader toggle={toggleModalCopy}>
                      Duplicate Job Vacancy
                    </ModalHeader>
                    <div className="modal-content border-0 mt-3"></div>
                    <ModalBody>
                      <Row>
                        <Col lg={12}>
                          <div className="mb-3">
                            <Label for="firstnameInput" className="form-label">
                              Job Title
                            </Label>
                            <Input
                              type="text"
                              value={job_title}
                              onChange={(e) => {
                                setJobTitle(e.target.value);
                                setJobTitleError("");
                              }}
                              className={`form-control ${
                                jobTitleError ? "is-invalid" : ""
                              }`}
                              id="firstnameInput"
                              placeholder="Enter job title"
                            />
                            {jobTitleError && (
                              <div className="invalid-feedback">
                                {jobTitleError}
                              </div>
                            )}
                          </div>
                        </Col>
                        <Col lg={12}>
                          <div className="mb-3">
                            <Label
                              for="exampleFormControlTextarea1"
                              className="form-label"
                            >
                              Job Description
                            </Label>
                            <CKEditor
                              className={`form-control ${
                                jobDescriptionError ? "is-invalid" : ""
                              }`}
                              editor={ClassicEditor}
                              data={job_description || ""}
                              config={{
                                placeholder:
                                  "Craft a comprehensive job description that captures the essence of this role. Highlight key attributes you're seeking in a candidate, such as specific skills, personality traits, required qualifications, and location preferences. Please include any crucial details that will help candidates understand the role better. This description is not just a guide for prospective applicants but also aids our AI in accurately matching your vacancy with the most suitable candidates in our talent pool. The more detailed your description, the better the match will be!",
                              }}
                              onReady={(editor) => {}}
                              onChange={(event, editor) => {
                                const data = editor.getData();
                                console.log(data);
                                setJobDescription(data);
                                setJobDescriptionError("");
                              }}
                            />
                            {jobDescriptionError && (
                              <div className="invalid-feedback">
                                {jobDescriptionError}
                              </div>
                            )}
                          </div>
                        </Col>
                        <Col lg={12}>
                          <div className="mb-3">
                            <Label
                              for="phonenumberInput"
                              className="form-label"
                            >
                              Work Location
                            </Label>
                            <GoogleAutocomplete
                              className={`${
                                jobLocationError ? "is-invalid" : ""
                              }`}
                              apiKey="AIzaSyB2SMtaVBlqC5v72gqS716BX8R5oXklaFc"
                              value={job_location}
                              onChange={(e) => {
                                setJobLocation(e.target.value);
                                setJobLocationError("");
                              }}
                              onPlaceSelected={(place) =>
                                residentialplace(place)
                              }
                              options={{
                                types: ["geocode"],
                                componentRestrictions: {
                                  country: "au",
                                },
                              }}
                              style={{
                                width: "100%",
                                border: "1px solid #ccc",
                                padding: "10px",
                                borderRadius: "5px",
                                position: "relative",
                                zIndex: 9999,
                              }}
                            />
                            {jobLocationError && (
                              <div className="invalid-feedback">
                                {jobLocationError}
                              </div>
                            )}
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="mb-3">
                            <Label for="cityidInput" className="form-label">
                              Salary Range{" "}
                              <button
                                className="btn btn"
                                onClick={() => {
                                  toggleModal1();
                                }}
                              >
                                <i className="ri-questionnaire-fill me-1 align-bottom"></i>
                              </button>
                            </Label>
                            <Select
                              className={`${
                                salaryRangeTypeError ? "is-invalid" : ""
                              }`}
                              value={salaryType[0].options.find(
                                (option) => option.value === salary_range_type
                              )}
                              onChange={(selectedOption) => {
                                handleSelectDay(selectedOption.value);
                                setSalaryRangeTypeError("");
                              }}
                              options={salaryType[0].options}
                              id="day"
                            ></Select>
                            {salaryRangeTypeError && (
                              <div className="invalid-feedback">
                                {salaryRangeTypeError}
                              </div>
                            )}
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="mb-3">
                            <div
                              style={{
                                marginTop: "45px",
                                position: "relative",
                              }}
                              className="input-group"
                            >
                              <span className="input-group-text">$</span>
                              <Input
                                type="number"
                                value={salary_range_from}
                                onChange={(e) => {
                                  setSalaryRangeFrom(e.target.value);
                                  setSalaryRangeFromError("");
                                  const userInput = e.target.value;
                                  // Regular expression to match up to 10 digits with up to 2 decimal places
                                  const regex = /^\d{0,8}(\.\d{0,2})?$/;

                                  if (
                                    parseFloat(e.target.value) >=
                                    parseFloat(salary_range_to)
                                  ) {
                                    setSalaryRangeToError("a");
                                  } else if (
                                    regex.test(userInput) ||
                                    userInput === ""
                                  ) {
                                    setSalaryRangeFrom(userInput);
                                    setSalaryRangeFromError("");
                                    setSalaryRangeToError("");
                                  } else {
                                    // If input doesn't match the pattern, display an error
                                    setSalaryRangeFromError(
                                      "Maximum 10 digits with up to 2 decimal places allowed"
                                    );
                                  }
                                }}
                                className={`form-control ${
                                  salaryRangeFromError ? "is-invalid" : ""
                                }`}
                                id="zipcodeidInput"
                                placeholder="0.00"
                                style={{ paddingLeft: "20px" }}
                              />
                              {salaryRangeFromError && (
                                <div className="invalid-feedback"></div>
                              )}
                            </div>
                          </div>
                        </Col>
                        <Col lg={1}>
                          <div className="mb-3">
                            <Label
                              for="cityidInput"
                              className="form-label"
                              style={{ marginTop: "52px", paddingLeft: "8px" }}
                            >
                              To
                            </Label>
                          </div>
                        </Col>
                        <Col lg={3}>
                          <div className="mb-3">
                            <div
                              style={{
                                marginTop: "45px",
                                position: "relative",
                              }}
                              className="input-group"
                            >
                              <span className="input-group-text">$</span>
                              <Input
                                value={salary_range_to}
                                onChange={(e) => {
                                  setSalaryRangeTo(e.target.value);

                                  const userInput = e.target.value;
                                  // Regular expression to match up to 10 digits with up to 2 decimal places
                                  const regex = /^\d{0,8}(\.\d{0,2})?$/;

                                  if (
                                    parseFloat(salary_range_from) >=
                                    parseFloat(e.target.value)
                                  ) {
                                    setSalaryRangeToError("a");
                                  } else if (
                                    regex.test(userInput) ||
                                    userInput === ""
                                  ) {
                                    setSalaryRangeTo(userInput);
                                    setSalaryRangeToError("");
                                  } else {
                                    // If input doesn't match the pattern, display an error
                                    setSalaryRangeToError(
                                      "Maximum 10 digits with up to 2 decimal places allowed"
                                    );
                                  }
                                }}
                                type="number"
                                className={`form-control ${
                                  salaryRangeToError ? "is-invalid" : ""
                                }`}
                                id="zipcodeidInput"
                                placeholder="0.00"
                                style={{ paddingLeft: "20px" }}
                              />
                              {salaryRangeToError && (
                                <div className="invalid-feedback"></div>
                              )}
                            </div>
                          </div>
                        </Col>
                        <Col md={12}>
                          <div className="mb-3">
                            <Label for="leadStatusinput" className="form-label">
                              Skills
                            </Label>
                            <Select
                              className={`${skillIdError ? "is-invalid" : ""}`}
                              value={skill_id}
                              isMulti={true}
                              onChange={handleChangeCopy}
                              options={skillOptions}
                              placeholder="Select a skill..."
                            />
                            {skillIdError && (
                              <div className="invalid-feedback">
                                {skillIdError}
                              </div>
                            )}
                          </div>
                        </Col>
                        <Col lg={12}>
                          <div className="mb-3">
                            <Label for="birthdayidInput" className="form-label">
                              Close Date
                            </Label>
                            <Flatpickr
                              className={`form-control ${
                                close_date_error ? "is-invalid" : ""
                              }`}
                              options={{
                                dateFormat: "d M, Y",
                              }}
                              onChange={(selectedDates) => {
                                setCloseDate(
                                  moment(selectedDates[0]).format(
                                    "DD MMM, YYYY"
                                  )
                                );
                                setCloseDateError("");
                              }}
                              value={close_date}
                              placeholder="Select Date"
                            />
                            {close_date_error && (
                              <div className="invalid-feedback">
                                {close_date_error}
                              </div>
                            )}
                          </div>
                        </Col>
                        <Col lg={12}>
                          <Row>
                            <div className="hstack gap-2 justify-content-end">
                              <Col
                                lg={6}
                                className="d-flex justify-content-end gap-2"
                              >
                                <Button
                                  className="btn btn-light"
                                  data-bs-dismiss="modal"
                                  onClick={toggleModalCopy}
                                >
                                  Close
                                </Button>
                                <button
                                  type="submit"
                                  className="btn btn-light"
                                  style={{ color: "#ec1388" }}
                                  onClick={duplicateDataToDraft}
                                >
                                  <i className="ri-save-3-line align-bottom me-1"></i>{" "}
                                  Save Draft
                                </button>
                              </Col>
                            </div>
                          </Row>
                        </Col>
                      </Row>
                    </ModalBody>
                  </Modal>
                </>
              )}
              <Modal
                className="zoomIn"
                id="addSeller"
                size="lg"
                isOpen={modalChangeStatus}
                toggle={toggleModalChangeStatus}
                centered
              >
                <ModalHeader toggle={toggleModalChangeStatus}>
                  Change Job Status
                </ModalHeader>
                <div className="modal-content border-0 mt-3"></div>
                <ModalBody>
                  <Input
                    type="hidden"
                    value={id}
                    onChange={(e) => {
                      setId(e.target.value);
                    }}
                    id="firstnameInput"
                  />
                  <Row>
                    <Col lg={6}>
                      <div className="mb-3">
                        <Label for="cityidInput" className="form-label">
                          Change Status
                        </Label>
                        <Select
                          className={`${jobStatusError ? "is-invalid" : ""}`}
                          value={jobStatus.find(
                            (option) => option.value === job_status_id
                          )} // Find the option with the matching value
                          onChange={(selectedOption) => {
                            handleSelectStatus(selectedOption.value); // Make sure selectedOption is the correct structure
                            setJobStatusError(""); // Clear error if any
                          }}
                          options={jobStatus}
                          id="day"
                        ></Select>
                        {jobStatusError && (
                          <div className="invalid-feedback">
                            {jobStatusError}
                          </div>
                        )}
                      </div>
                    </Col>
                    <Col lg={12}>
                      <Row>
                        <div className="hstack gap-2 justify-content-end">
                          <Col
                            lg={6}
                            className="d-flex justify-content-end gap-2"
                          >
                            <Button
                              className="btn btn-light"
                              data-bs-dismiss="modal"
                              onClick={toggleModalChangeStatus}
                            >
                              Close
                            </Button>
                            <button
                              type="submit"
                              className="btn btn-light"
                              style={{ color: "#865ce2" }}
                              onClick={changeVacancyStatus}
                            >
                              <i className="ri-save-3-line align-bottom me-1"></i>{" "}
                              Change Status
                            </button>
                          </Col>
                        </div>
                      </Row>
                    </Col>
                  </Row>
                </ModalBody>
              </Modal>
              {isLoading && (
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    background: "rgba(255, 255, 255, 0.8)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    zIndex: 9999,
                  }}
                >
                  <Loader />
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default JobVacancy;
