import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Accordion,
  AccordionItem,
  Collapse,
  Label,
  Input,
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import React, {
  useState,
  useRef,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import { useParams } from "react-router-dom";
import { SettingsTabPages } from "./Constants/SettingsConstants";
import SettingsTabControl from "./TabComponents/SettingsTabControl";
import SettingsTabContent from "./TabComponents/SettingsTabContent";
import { get_cookie } from "../../helpers/get_cookie";

const BusinessSettings = () => {
  document.title = "Business Settings | Get Rostered";
  const params = useParams();
  const tab = params.activeTab;
  const [activeTab, setActiveTab] = useState("");
  const [showSystemUsers, setShowSystemUsers] = useState(false);
  const [showGeneralSettings, setShowGeneralSettings] = useState(false);
  const [showSites, setShowSites] = useState(false);
  const [showRolesAndPermissions, setShowRolesAndPermissions] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const allPermissions = JSON.parse(get_cookie("permissions"));
        setShowSystemUsers(allPermissions?.includes("manage_users"));
        setShowGeneralSettings(allPermissions?.includes("view_business"));
        setShowRolesAndPermissions(
          allPermissions?.includes("manage_roles_permissions")
        );
        setShowSites(allPermissions?.includes("view_sites"));
      } catch (err) {
        console.error(err);
      }
    };

    fetchData();
  }, [tab]);

  useEffect(() => {
    // Handle tab parameter if it's provided in the URL
    if (tab === "general") {
      setActiveTab(showGeneralSettings && SettingsTabPages.General);
    } else if (tab === "system_users") {
      setActiveTab(showSystemUsers && SettingsTabPages.SystemUsers);
    } else if (tab === "permissions") {
      setActiveTab(showRolesAndPermissions && SettingsTabPages.Permissions);
    } else if (tab === "roster") {
      setActiveTab(SettingsTabPages.Roster);
    } else if (tab === "sites") {
      setActiveTab(showSites && SettingsTabPages.Sites);
    } else if (tab === "workorder") {
      setActiveTab(SettingsTabPages.Workorder);
    } else if (tab === "workorder_templates") {
      setActiveTab(SettingsTabPages.WorkorderTemplates);
    } else if (tab === "timesheets") {
      setActiveTab(SettingsTabPages.Timesheets);
    } else if (tab === "leave") {
      setActiveTab(SettingsTabPages.Leave);
    } else if (tab === "roles") {
      setActiveTab(showRolesAndPermissions && SettingsTabPages.Roles);
    } else if (tab === "notifications") {
      setActiveTab(SettingsTabPages.Notifications);
     } else if (tab === "Xero") {
        setActiveTab(SettingsTabPages.Xero);
    } else if (tab === "instant_payout") {
        setActiveTab(SettingsTabPages.InstantPayout);
    }else if (tab === "profile") {
      setActiveTab(SettingsTabPages.Profile);
    } else {
      setActiveTab(SettingsTabPages.Profile);
    }
  }, [
    tab,
    showGeneralSettings,
    showSystemUsers,
    showSites,
    showRolesAndPermissions,
  ]);

  //Pagepersonalsation Headings
  const getPageTitle = () => {
    switch (activeTab) {
      case SettingsTabPages.General:
        return "General";
      case SettingsTabPages.People:
        return "People";
      case SettingsTabPages.Roster:
        return "Roster";
      case SettingsTabPages.Timesheets:
        return "Timesheets";
      case SettingsTabPages.Leave:
        return "Leave";
      case SettingsTabPages.Roles:
        return "Roles";
        case SettingsTabPages.CancelShift:
          return "cancelshift";
      case SettingsTabPages.Profile:
        return "Profile";
      default:
        return "General";
    }
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Business Settings" />
          <Row className="mt-3">
            <Col md="3">
              <SettingsTabControl
                activeTabSetter={setActiveTab}
                activeTab={activeTab}
                showGeneralSettings={showGeneralSettings}
                showSystemUsers={showSystemUsers}
                showSites={showSites}
                showRolesAndPermissions={showRolesAndPermissions}
              />
            </Col>
            {activeTab ? (
              <Col md="9">
                <SettingsTabContent activeTab={activeTab} />
              </Col>
            ) : (
              <Col md="9">
                <Card className="p-3 text-bold">
                  {" "}
                  Permission denied for this user
                </Card>
              </Col>
            )}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default BusinessSettings;
