import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";

// <-----------------------------ROSTER------------------------------->
//OTP
import TwoSVerify from "../pages/Authentication/BasicTwosVerify";

import BusinessList from "../pages/Authentication/BusinessList";
//Password Reset
import PasswReset from "../pages/Authentication/BasicPasswReset";
//Registration
import BusinessProfile from "../pages/Authentication/BusinessProfile";
// Directory
import Directory from "../pages/Directory/Index";
// Job Roster
import JobRoster from "../pages/JobRoster/Index";
//Calendar
import Calendar from "../pages/JobRoster/Calendar";

// Invitations Sent
import InvitationsSent from "../pages/InvitationsSent/Index";
// Search Workforce
import SearchWorkforce from "../pages/SearchWorkforce/Index";

// Job Postings
import JobPostings from "../pages/JobPostings/Index";
import JobVacancy from "../pages/Jobvacancies/index";
import JobApplications from "../pages/JobApplications/Index";
// Talent Pool
import TalentPool from "../pages/TalentPool/Index";

// Current Contracts
import CurrentContracts from "../pages/CurrentContracts/Index";
// Past Contracts
import PastContracts from "../pages/PastContracts/Index";
// Pending Invitations
import PendingInvitations from "../pages/PendingInvitations/Index";
// Overview
import Overview from "../pages/Overview/Index";
// Activity
import Activity from "../pages/Activity/Index";
//Time Clock
import TimeClock from "../pages/TimeClock/Index";

//Xero Connect 
import { Xero } from "../pages/BusinessSettings/TabPages/Xero";
//import { InstantPayout } from "../pages/BusinessSettings/TabPages/InstantPayout";
// import { Xerocallback } from "../pages/BusinessSettings/TabPages/XeroCallback";
// Chat
import Chat from "../pages/Chat/Index";
//inbox
import MailInbox from "../pages/EmailInbox/index";
//Text Connect
// import TextConnect from "../pages/TextConnect/Index";
// Bussiness Settings
import BusinessSettings from "../pages/BusinessSettings/Index";
import { Referral } from "../pages/Referral Rewards/Referral";
import ProfileShare from "../pages/Share/ProfileShare";

import BusinessSettingsTabControl from "../pages/BusinessSettings/TabComponents/SettingsTabControl";
//sites
import Sites from "../pages/Sites/Index";
// import AddSite from "../pages/Sites/AddSite";
import EditSite from "../pages/Sites/EditSite";
// import SiteTabControl from "../pages/Sites/TabComponents/SettingsTabControl";
//Manage Team
import ManageTeam from "../pages/ManageTeam/Index";
//Work order
import WorkOrder from "../pages/WorkOrder/WorkOrder";
//Team Availability
import TeamAvailability from "../pages/TeamAvailability/Index";
// Roster Lounge
import RosterLounge from "../pages/RosterLounge/Index";

// <-----------------------------ROSTER------------------------------->

//dashboard
import Dashboard from "../pages/Dashboard/Index";

//Reporting
import LeadReport from "../pages/Reporting/LeadReport";
import OperationsReport from "../pages/Reporting/OperationsReport";
import VehicleCheck from "../pages/Reporting/VehicleCheck";
import SalesPipeline from "../pages/Reporting/SalesPipeline";

//Settings
// import Settings from "../pages/Settings/Settings";
// import Support from "../pages/Support/Support";
// import PersonalisationDetail from "../pages/Settings/TabPages/PersonalisationDetail";

// import { PagePersonalisation } from "../pages/Settings/TabPages/PagePersonalisation";
// import SettingsTabControl from "../pages/Settings/TabComponents/SettingsTabControl";

// Email box
import BasicAction from "../pages/Email/EmailTemplates/BasicAction";
import EcommerceAction from "../pages/Email/EmailTemplates/EcommerceAction";

//AuthenticationInner pages
import BasicSignIn from "../pages/AuthenticationInner/Login/BasicSignIn";
import CoverSignIn from "../pages/AuthenticationInner/Login/CoverSignIn";
import BasicSignUp from "../pages/AuthenticationInner/Register/BasicSignUp";
import CoverSignUp from "../pages/AuthenticationInner/Register/CoverSignUp";
import BasicPasswReset from "../pages/AuthenticationInner/PasswordReset/BasicPasswReset";

import CoverPasswReset from "../pages/AuthenticationInner/PasswordReset/CoverPasswReset";
import BasicLockScreen from "../pages/AuthenticationInner/LockScreen/BasicLockScr";
import CoverLockScreen from "../pages/AuthenticationInner/LockScreen/CoverLockScr";
import BasicLogout from "../pages/AuthenticationInner/Logout/BasicLogout";
import CoverLogout from "../pages/AuthenticationInner/Logout/CoverLogout";
import BasicSuccessMsg from "../pages/AuthenticationInner/SuccessMessage/BasicSuccessMsg";
import CoverSuccessMsg from "../pages/AuthenticationInner/SuccessMessage/CoverSuccessMsg";
import BasicTwosVerify from "../pages/AuthenticationInner/TwoStepVerification/BasicTwosVerify";
import CoverTwosVerify from "../pages/AuthenticationInner/TwoStepVerification/CoverTwosVerify";
import Basic404 from "../pages/AuthenticationInner/Errors/Basic404";
import Cover404 from "../pages/AuthenticationInner/Errors/Cover404";
import Alt404 from "../pages/AuthenticationInner/Errors/Alt404";
import Error500 from "../pages/AuthenticationInner/Errors/Error500";

import BasicPasswCreate from "../pages/AuthenticationInner/PasswordCreate/BasicPasswCreate";
import CoverPasswCreate from "../pages/AuthenticationInner/PasswordCreate/CoverPasswCreate";
import Offlinepage from "../pages/AuthenticationInner/Errors/Offlinepage";

//login
import Login from "../pages/Authentication/Login";
import ForgetPasswordPage from "../pages/Authentication/ForgetPassword";
import ResetPassword from "../pages/Authentication/ResetPassword";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";

// User Profile
import UserProfile from "../pages/Authentication/user-profile";
import { get_cookie } from "../helpers/get_cookie";

// Checkout
import Checkout from "../pages/Checkout/checkout";
//Invite
import Invite from "../pages/AcceptInvite";
import AddSite from "../pages/Sites/AddSite";
import Cover403 from "../pages/AuthenticationInner/Errors/UnAuthorized403";
import ApproveTimesheets from "../pages/Timesheets/ApproveTimesheets/ApproveTimesheets";
import ExportTimesheets from "../pages/Timesheets/ExportTimesheets/ExportTimesheets";
import TermsOfUse from "../terms-of-use"; // Correct path
import PrivacyPolicy from "../privacy-policy"; // Add this import

import { components } from "react-select";
//Billing

import Billing from "../pages/Billing/Index";
import JobShare from "../pages/Share/JobShare";


const authProtectedRoutes = [
  // ----------------------------ROSTER-----------------------------------

  // Dashboard
  { path: "/directory", component: <Directory /> },
  //Job Roster
  { path: "/jobroster", component: <JobRoster /> },
  { path: "/calendar", component: <Calendar />, permission: "view_rosters" },
  // Invitations Sent
  { path: "/invitation", component: <InvitationsSent /> },
  // Search Workforce
  {
    path: "/searchworkforce",
    component: <SearchWorkforce />,
    permission: "view_workforce_member",
  },

  // Job Postings
  {
    path: "/job-postings",
    component: <JobPostings />,
    permission: "view_workforce_member",
  },
  {
    path: "/job-Vacancies",
    component: <JobVacancy />,
    permission: "view_workforce_member",
  },
  {
    path: "/job-applications",
    component: <JobApplications />,
    permission: "view_workforce_member",
  },
  // Talent Pool
  {
    path: "/talent-pool",
    component: <TalentPool />,
    permission: "view_workforce_member",
  },
  // Current Contracts
  { path: "/current-contracts", component: <CurrentContracts /> },

  // Past Contracts
  { path: "/past-contracts", component: <PastContracts /> },
  // Pending Invitations
  { path: "/pending-invitation", component: <PendingInvitations /> },
  // Overview
  { path: "/overview", component: <Overview /> },
  // Activity
  { path: "/activity", component: <Activity /> },
  // Time CLock
  { path: "/timeclock", component: <TimeClock /> },
  // Chat
  { path: "/chat", component: <Chat /> },
  //Inbox
  { path: "/email-inbox", component: <MailInbox /> },
  // TimeSheets
  { path: "/approve_timesheets", component: <ApproveTimesheets /> },
  { path: "/export_timesheets", component: <ExportTimesheets /> },

  // Text Connect
  // { path: "/textconnect", component: <TextConnect /> },
  // BussinessSettings
  {
    path: "/business_settings",
    component: <BusinessSettings />,
  },

  {
    path: "/referral_rewards",
    component: <Referral />,
  },

  { path: "/invite", component: <Invite /> },
  // {
  //   path: "/businesssettingstabcontent",
  //   component: <BusinessSettingsTabControl />,
  // },
  {
    path: "/business_settings/:activeTab",
    component: <BusinessSettings />,
  },
  {
    path: "/business-profile",
    component: <BusinessProfile />,
  },
  // {
  //   path: "/business_settings/:activeTab",
  //   component:
  //     JSON.parse(get_cookie("authUser"))?.profile_status == 0 ? (
  //       <Navigate to="/business-profile" />
  //     ) : (
  //       <BusinessSettings />
  //     ),
  // },

  //Sites
  {
    path: "/business_settings/sites/AddSite",
    component: <AddSite />,
    permission: "add_site",
  },

  //edit site
  {
    path: "/editsite/:activeTab/:id",
    component: <EditSite />,
    permission: "edit_site",
  },

  //Manage Team
  {
    path: "/manage-team",
    component: <ManageTeam />,
    permission: "view_workforce_member",
  },

  //Manage Team
  {
    path: "/team-availability",
    component: <TeamAvailability />,
    //permission: "view_workforce_member",
  },
  //Work Order
  {
    path: "/work-order",
    component: <WorkOrder />,
  },

  // Roster Lounge
  {
    path: "/roster_lounge",
    component: <RosterLounge />,
    permission: "view_rosters",
  },

  // ----------------------------ROSTER-----------------------------------

  //Dashboard
  { path: "/dashboard", component: <Dashboard /> },

  //reporting
  { path: "/salespipeline", component: <SalesPipeline /> },
  { path: "/operationsreport", component: <OperationsReport /> },
  { path: "/leadreport", component: <LeadReport /> },
  { path: "/dailyvehiclecheck", component: <VehicleCheck /> },

  //Settings
  // { path: "/settings/:activeTab", component: <Settings /> },
  // { path: "/personalisationdetail/:id", component: <PersonalisationDetail /> },
  // { path: "/support", component: <Support /> },
  // { path: "/pagepersonalisation", component: <PagePersonalisation /> },
  // { path: "/settingstabcontent", component: <SettingsTabControl /> },

  //User Profile
  { path: "/profile", component: <UserProfile /> },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: get_cookie("authUser") ? (
      <Navigate to="/business_settings" />
    ) : (
      <Navigate to="/login" />
    ),
  },
  //  { path: "*", component: <Navigate to="/dashboard" /> },
];

const publicRoutes = [
  // Authentication Page
  { path: "/logout", component: <Logout /> },
  { path: "/Xero", component: <Xero /> },
  // { path: "/xero-callback", component: <XeroCallback /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPasswordPage /> },
  { path: "/register", component: <Register /> },
  { path: "/twoStepVerify", component: <TwoSVerify /> },
  { path: "/business-list", component: <BusinessList /> },
  { path: "/pass-reset", component: <PasswReset /> },
  { path: "/reset-password", component: <ResetPassword /> },
  { path: "/accept-invitation/:params", component: <Invite /> },

  //AuthenticationInner pages
  { path: "/auth-signin-basic", component: <BasicSignIn /> },
  { path: "/auth-signin-cover", component: <CoverSignIn /> },
  { path: "/auth-signup-basic", component: <BasicSignUp /> },
  { path: "/auth-signup-cover", component: <CoverSignUp /> },
  { path: "/auth-pass-reset-basic", component: <BasicPasswReset /> },
  { path: "/auth-pass-reset-cover", component: <CoverPasswReset /> },
  { path: "/auth-lockscreen-basic", component: <BasicLockScreen /> },
  { path: "/auth-lockscreen-cover", component: <CoverLockScreen /> },
  { path: "/auth-logout-basic", component: <BasicLogout /> },
  { path: "/auth-logout-cover", component: <CoverLogout /> },
  { path: "/auth-success-msg-basic", component: <BasicSuccessMsg /> },
  { path: "/auth-success-msg-cover", component: <CoverSuccessMsg /> },
  { path: "/auth-twostep-basic", component: <BasicTwosVerify /> },
  { path: "/auth-twostep-cover", component: <CoverTwosVerify /> },
  { path: "/auth-404-basic", component: <Basic404 /> },
  { path: "/auth-404-cover", component: <Cover404 /> },
  { path: "/auth-404-alt", component: <Alt404 /> },
  { path: "/auth-500", component: <Error500 /> },
  { path: "/unauthorized", component: <Cover403 /> },

  { path: "/auth-pass-change-basic", component: <BasicPasswCreate /> },
  { path: "/auth-pass-change-cover", component: <CoverPasswCreate /> },
  { path: "/auth-offline", component: <Offlinepage /> },

  //Billing
  { path: "/billing", component: <Billing /> },
  { path: "/job/:id", component: <JobShare /> },
  { path: "/share-profile/:id", component: <ProfileShare/> },

  //Checkout
  { path: "/pay-now-inv/:encrypt", component: <Checkout /> },
  { path: "/pay-now-inv/:status/:encrypt", component: <Checkout /> },

  //Terms of use
  { path: "/terms-of-use", component: <TermsOfUse /> },
  { path: "/privacy-policy", component: <PrivacyPolicy /> },

  // //Error page
  // {path: "/errorpage",component:<ErrorPage/>},
];

export { authProtectedRoutes, publicRoutes };
