import PrismCode from "../../Components/Common/Prism";
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  Nav,
  Table,
  Spinner,
  UncontrolledDropdown,
  UncontrolledTooltip,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import { get_cookie } from "../../helpers/get_cookie";
import Rating from "react-rating";
import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef,
} from "react";
import imgrating from "../../assets/images/review-rating.png";
import { isEmpty } from "lodash";
import "../../assets/scss/pages/_settings.scss";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import DeleteModal from "../../Components/Common/DeleteModal";
import { CardHeader, Label, Input } from "reactstrap";
//Import actions
import {
  getContacts as onGetContacts,
  addNewContact as onAddNewContact,
  updateContact as onUpdateContact,
  deleteContact as onDeleteContact,
} from "../../store/actions";
import MyProfile from "../SearchWorkforce/ProfileView";
import { Button } from "reactstrap";
//redux
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import GetrosterTableContainer from "../../Components/Common/GetrosterTableContainer";
// Formik
import * as Yup from "yup";
import { useFormik } from "formik";
import Loader from "../../Components/Common/Loader";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { Link } from "react-router-dom";
//reloading the page
import { jsx } from "@emotion/react";
import { ClipLoader } from "react-spinners";
import { api } from "../../config";
import TableContainer from "../../Components/Common/TableContainer";
import ManageTeamDetail from "./ManageTeamDetail";
import classnames from "classnames";
import userDummy from "../../assets/images/users/user-dummy-img.jpg";
import SiteDetailPopup from "../../Components/Common/SiteDetailPopup";
//reloading the page
const override = jsx`
  display: block;
  margin: 0 auto;
  border-color: red;
`;
export const ManageTeam = () => {
  document.title = "Manage Team | Get Rostered";
  const [modal, setModal] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isLoading, setisLoading] = useState(true);
  const [payrollCalendars, setPayrollCalendars] = useState([]);
  const [incometype, setincometype] = useState([]);
  const [payrate, setpayrate] = useState([]);
  const [authUserId, setAuthUserId] = useState();
  const [openProfileId, setOpenProfileId] = useState();
  const [openTeamDetail, setOpenTeamDetail] = useState(false);
  const [xeromodal, setxeromodal] = useState(false);
  const [openWorkforceId, setOpenWorkforceId] = useState(null);
  const [isOpenLoading, setIsOpenLoading] = useState(false);
  const [businessApiDetailsExists, setBusinessApiDetailsExists] = useState(false); // To check if business API details exist
  const [teamdetails, setteamdetails] = useState();
  const [sitedetails, setsitedetails] = useState();
  const [selectedIncomeType, setSelectedIncomeType] = useState("");
  const [employee_type, setEmployeeType] = useState("");
  const [selectedCalendar, setSelectedCalendar] = useState("");
  const [selectedEarningRate, setSelectedEarningRate] = useState("");
  const [cancelweekday, setcancelweekday] = useState();
  const [cancelsaturday, setcancelsaturday] = useState();
  const [cancelsunday, setcancelsunday] = useState();
  const [cancelholiday, setcancelholiday] = useState();
  const [sitelist, setsitelist] = useState();
  const [worktitle, setworktitle] = useState();
  const [editing, setEditing] = useState(false);
  const [editedTitle, setEditedTitle] = useState(worktitle); // Assuming item is accessible
  const [customHoverTab, setcustomHoverTab] = useState("1");
  const [addingRow, setAddingRow] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [profileId, setProfileId] = useState("");
  const [show, setShow] = useState(false);
  const [rating, setRating] = useState(0);
  const [review, setReview] = useState("");
  const [workerId, setWorkerId] = useState("");
  const [reviewId, setReviewId] = useState("");
  const [isLoadingReview, setisLoadingReview] = useState(false);
  const [reviewSpinner, setReviewSpinner] = useState(false);

  const all_permissions = JSON.parse(get_cookie("permissions"));
  const user_id = JSON.parse(get_cookie("authUser")).user_id;
  const business_id = JSON.parse(get_cookie("authUser")).business_id;
  const handleclick = (site_id) => {
    setProfileId(site_id);
    setShowPopup(!showPopup);
  };
  useEffect(() => {
    axios
      .get("/get-user-details")
      .then((res) => {
        console.log(res);
        setAuthUserId(res.id);
        loadTable(res.id);
        console.log(res);
      })
      .then((err) => {
        console.log(err);
      });
  }, []);

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      tog_Company();
      setContact(null);
    } else {
      setModal(true);
      setTag([]);
      setAssignTag([]);
    }
  }, [modal]);

  const toggleTeamDetail = () => {
    setOpenTeamDetail(!openTeamDetail);
  };

  const togglexeromodal = () => {
    setxeromodal(!xeromodal);
  };
  
  const customHovertoggle = (tab) => {
    if (customHoverTab !== tab) {
      setcustomHoverTab(tab);
    }
  };


  const checkBusinessApiDetails = () => {
    axios
      .post("/check-business-api-details", { business_id: business_id })
      .then((res) => {
        setBusinessApiDetailsExists(res.exists); // Set true if business API details exist
      })
      .catch((err) => {
        console.log(err);
      });
  };


  //css stylings for random bg color and default profile pic
  const pastelColors = [
    "#fd99a8", // Plum
    "#FFA07A", // LightSalmon
    "#FFD700", // Gold
    "#90df90", // PaleGreen
    "#8bdddd", // PaleTurquoise
    "#af91e3", // Lavender
    "#f791eb", // LightPink
  ];
  const [contact, setContact] = useState([]);
  const dispatch = useDispatch();

  //react
  const isContactSuccess = true;
  const error = null;
  const [data, setData] = useState([]);
  const [archive, setArchive] = useState([]);
  const [archiveCount, setArchiveCount] = useState([]);
  const [activeCount, setActiveCount] = useState([]);
  const crmcontacts = data;

  const loadTable = (userId) => {
    setisLoading(true);
    const formData = {
      user_id: userId,
      business_id: business_id,
    };
    axios
      .post("/active-employees", formData)
      .then((response) => {
        setData(response.result);
        setArchive(response.archive);
        setActiveCount(response.active_count);
        setArchiveCount(response.archive_count);
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setisLoading(false);
      });
  };
  //input field values


  const fetchPayrollCalendars = () => {
    axios
      .post('/getpayroll-calendars', { business_id: business_id })
      .then((res) => {
        if (res.status === 'success') {
          setPayrollCalendars(res.data); // Set the calendar data
        } else {
          console.error(res.message);
        }
      })
      .catch((err) => {
        console.error('Error fetching payroll calendars:', err);
      });
  };



  const fetchearningrate = () => {
    axios
      .post('/get-ordinaryearning-rate', { business_id: business_id,workforce_id :profileId })
      .then((res) => {
        if (res.status =='success') {
          setpayrate(res.data); // Set the calendar data
          console.log(res.Employee,"res.Employeeres.Employeeres.Employee")
          setincometype(res.Incometypeoptions);
         
        } else {
          console.error(res.message);
        }
      })
      .catch((err) => {
        console.error('Error fetching payroll calendars:', err);
      });
  };


  useEffect(() => {
    checkBusinessApiDetails();
    }, [business_id]);


  

  useEffect(() => {
  fetchPayrollCalendars();
  fetchearningrate();
    
  }, [businessApiDetailsExists]);

  
  
  const [defaultInterest, setDefaultInterest] = useState("N");
  const [activeInterest, setActiveInterest] = useState("N");
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const [uploadedImage, setUploadedImage] = useState("");
  const [open, setOpen] = useState(false);
  const toggleDefaultCanvas = () => {
    setOpen(!open);
  };

  //checkbox code
  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;

    if (name === "default") {
      setDefaultInterest(checked ? "Y" : "N");
    } else if (name === "active") {
      setActiveInterest(checked ? "Y" : "N");
    }
  };
  //react
  useEffect(() => {
    setContact(crmcontacts);
  }, [crmcontacts]);

  useEffect(() => {
    if (!isEmpty(crmcontacts)) {
      setContact(crmcontacts);
      setIsEdit(false);
    }
  }, [crmcontacts]);

  const [isEdit, setIsEdit] = useState(false);
  // Delete Data
  const handleDeleteContact = () => {
    if (contact) {
      dispatch(onDeleteContact(contact._id));
      setDeleteModal(false);
    }
  };
  const onClickDelete = (contact) => {
    setContact(contact);
    setDeleteModal(true);
  };
  //delete Conatct
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteModalMulti, setDeleteModalMulti] = useState(false);

  const [modal_large, setModal_Company] = useState(false);
  function tog_Company() {
    setModal_Company(!modal_large);
  }

  // Add Data
  const handleContactClicks = () => {
    setContact("");
    setIsEdit(false);
    toggle();
  };
  // Date & Time Format

  const dateFormat = () => {
    var d = new Date(),
      months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
    return d.getDate() + " " + months[d.getMonth()] + ", " + d.getFullYear();
  };

  const [site, setsite] = useState("");
  const filteredMenuItemsGeneral = !site.includes("sites");

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      // img: (contact && contact.img) || '',
      name: (contact && contact.name) || "",
      company: "",
      designation: "",
      email: (contact && contact.email) || "",
      phone: (contact && contact.phone) || "",
      lead_score: (contact && contact.lead_score) || "",
      tags: (contact && contact.tags) || [],
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Name"),
      company: Yup.string().required("Please Enter Company"),
      designation: Yup.string().required("Please Enter Designation"),
      email: Yup.string().required("Please Enter Email"),
      phone: Yup.string().required("Please Enter Phone"),
      lead_score: Yup.string().required("Please Enter lead_score"),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateContact = {
          _id: contact ? contact._id : 0,
          // img: values.img,
          name: values.name,
          company: values.company,
          designation: values.designation,
          email: values.email,
          phone: values.phone,
          lead_score: values.lead_score,
          last_contacted: dateFormat(),
          // time: timeFormat(),
          tags: assignTag,
        };
        // update Contact
        dispatch(onUpdateContact(updateContact));
        validation.resetForm();
      } else {
        const newContact = {
          _id: (Math.floor(Math.random() * (30 - 20)) + 20).toString(),
          // img: values["img"],
          name: values["name"],
          company: values["company"],
          designation: values["designation"],
          email: values["email"],
          phone: values["phone"],
          lead_score: values["lead_score"],
          last_contacted: dateFormat(),
          // time: timeFormat(),
          tags: assignTag,
        };
        // save new Contact
        dispatch(onAddNewContact(newContact));
        validation.resetForm();
      }
      toggle();
    },
  });

  // Update Data
  const handleContactClick = useCallback(
    (arg) => {
      const contact = arg;

      setContact({
        _id: contact._id,
        // img: contact.img,
        name: contact.name,
        company: contact.company,
        email: contact.email,
        designation: contact.designation,
        phone: contact.phone,
        lead_score: contact.lead_score,
        last_contacted: contact.date,
        // time: contact.time,
        tags: contact.tags,
      });

      setIsEdit(true);
      toggle();
    },
    [toggle]
  );

  const [order, setOrder] = useState([]);

  const handleOrderClick = useCallback(
    (arg) => {
      const order = arg;
      setOrder({
        _id: order._id,
        orderId: order.orderId,
        customer: order.customer,
        product: order.product,
        orderDate: order.orderDate,
        ordertime: order.ordertime,
        amount: order.amount,
        payment: order.payment,
        status: order.status,
      });

      setIsEdit(true);
      toggle();
    },
    [toggle]
  );
  const [selectedSiteId, setSelectedSiteId] = useState(null);
  // Checked All
  const checkedAll = useCallback(() => {
    const checkall = document.getElementById("checkBoxAll");
    const ele = document.querySelectorAll(".contactCheckBox");

    if (checkall.checked) {
      ele.forEach((ele) => {
        ele.checked = true;
      });
    } else {
      ele.forEach((ele) => {
        ele.checked = false;
      });
    }
    deleteCheckbox();
  }, []);

  // Delete Multiple
  const [selectedCheckBoxDelete, setSelectedCheckBoxDelete] = useState([]);
  const [isMultiDeleteButton, setIsMultiDeleteButton] = useState(false);

  const deleteMultiple = () => {
    const checkall = document.getElementById("checkBoxAll");
    selectedCheckBoxDelete.forEach((element) => {
      dispatch(onDeleteContact(element.value));
      setTimeout(() => {
        toast.clearWaitingQueue();
      }, 3000);
    });
    setIsMultiDeleteButton(false);
    checkall.checked = false;
  };

  const deleteCheckbox = () => {
    const ele = document.querySelectorAll(".contactCheckBox:checked");
    ele.length > 0
      ? setIsMultiDeleteButton(true)
      : setIsMultiDeleteButton(false);
    setSelectedCheckBoxDelete(ele);
  };

  const [deleteId, setDeleteId] = useState("");
  const [index, setIndex] = useState("");
  const [workId, setworkId] = useState("");
  //react
  const [tag, setTag] = useState([]);
  const [assignTag, setAssignTag] = useState([]);

  // Export Modal
  const [isExportCSV, setIsExportCSV] = useState(false);

  //image selection
  const fileInputRef = useRef(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isImageSelected, setIsImageSelected] = useState(false);
  const [searchQuery, setSearchQuery] = useState(null);
  const [isQuery, setIsQuery] = useState(false);
  const handleButtonClick = (event) => {
    event.preventDefault();
    fileInputRef.current.click();
  };

  const handleFileInputChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const objectUrl = URL.createObjectURL(selectedFile);
      setUploadedImage(selectedFile);
      setSelectedImage(objectUrl);
      setIsImageSelected(true);
    }
  };

  console.log(teamdetails, "teamdetails");

  const handleRemoveClick = () => {
    setSelectedImage(null);
    setUploadedImage(null);
    setIsImageSelected(false);
    fileInputRef.current.value = null; // Clear the input field value
  };

  const handleChangeClick = (event) => {
    event.preventDefault();
    fileInputRef.current.click();
  };
  const itemsPerPage = 10; // Number of items to display per page
  const [currentPage, setCurrentPage] = useState(1);

  // Calculate the index range for the current page
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  // Slice the data array to get the items for the current page
  const currentItems = data.slice(startIndex, endIndex);

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const [payRateWeekDay, setPayRateWeekDay] = useState();
  const [payRateSaturday, setPayRateSaturday] = useState();
  const [payRateSunday, setPayRateSunday] = useState();
  const [payRatePublicHoliday, setPayRatePublicHoliday] = useState();
  const [modal_assignModal, setmodal_assignModal] = useState(false);
  const [modal_assignModalArchive, setmodal_assignModalArchive] =
    useState(false);
  const [userProfile, setUserProfile] = useState([]);
  const [userProfiles, setUserProfiles] = useState([]);
  const [workerName, setWorkerName] = useState("");

  const [userProfileImage, setUserProfileImage] = useState([]);
  const handleEdit = (index) => {
    const updatedProductCategory = [...sitedetails];
    updatedProductCategory[index].isEditing = true;
    setsitedetails(updatedProductCategory);
  };

  function tog_assignModal() {
    setmodal_assignModal(!modal_assignModal);
  }
  function tog_assignModalArchive() {
    setmodal_assignModalArchive(!modal_assignModalArchive);
  }

  const manageJobColumn = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "full_name",
        Cell: ({ row }) => (
          <div
            onClick={() => {
              // handleRowClick(row.original)
            }}
            // style={{ color: '#ff0599', cursor: 'pointer' }}
          >
            {row.original.full_name}
          </div>
        ),
        filterable: true,
      },
      {
        Header: "Sites",
        accessor: "site_names",
        accessor: (row) => {
          const sites =
            row.site_names !== null &&
            row.site_names !== undefined &&
            row.site_names !== ""
              ? `${row.site_names}`
              : "-";
          return <span>{sites}</span>;
        },
        filterable: false,
      },
      {
        Header: "Work Title",
        accessor: "work_title",
        accessor: (row) => {
          const workTitle =
            row.work_title !== null &&
            row.work_title !== undefined &&
            row.work_title !== ""
              ? `${row.work_title}`
              : "-";
          return <span>{workTitle}</span>;
        },
        filterable: false,
      },
      {
        Header: "Contact",
        // accessor: "mobile",
        accessor: (row) => {
          console.log(row.mobile, "row.mobile");
          const mobile =
            row.mobile !== null && row.mobile !== "null"
              ? `${row.mobile}`
              : "-";
          return mobile;
        },
        filterable: false,
      },

      {
        Header: "Weekday",
        // accessor: "pay_rate_week_day",
        accessor: (row) => {
          const weekDay =
            row.pay_rate_week_day !== null
              ? `$${row.pay_rate_week_day}`
              : "$00.00";
          return weekDay;
        },
        filterable: true,
      },
      {
        Header: "Saturday",
        // accessor: "pay_rate_saturday",
        accessor: (row) => {
          const saturday =
            row.pay_rate_saturday !== null
              ? `$${row.pay_rate_saturday}`
              : "$00.00";
          return saturday;
        },
        filterable: true,
      },
      {
        Header: "Sunday",
        // accessor: "pay_rate_sunday",
        accessor: (row) => {
          const sunday =
            row.pay_rate_sunday !== null ? `$${row.pay_rate_sunday}` : "$00.00";
          return sunday;
        },
        filterable: true,
      },
      {
        Header: "Public Holiday",
        // accessor: "pay_rate_public_holiday",
        accessor: (row) => {
          const publicHoliday =
            row.pay_rate_public_holiday !== null
              ? `$${row.pay_rate_public_holiday}`
              : "$00.00";
          return publicHoliday;
        },
        filterable: true,
      },
      {
        Header: "Action",
        Cell: (cellProps) => {
          const site_id = cellProps.row.original.id;
          const statusName = cellProps.row.original.status_name;
          const profilePic = cellProps.row.original.initial.s3Url;
          console.log(cellProps.row.original, authUserId, "cell data");
          return (
            <div className="d-flex gap-3">
              <button
                className="btn btn-sm btn-soft-success copy-list"
                onClick={() => {
                  setOpenWorkforceId(site_id);
                  toggleTeamDetail();
                }}
              >
                <i className="bx bx-show-alt fs-12 pt-1" id="copytooltip"></i>
              </button>
              <UncontrolledTooltip placement="top" target="copytooltip">
                View
              </UncontrolledTooltip>
              {all_permissions?.includes("edit_workforce_member") && (
                <>
                  <button
                    className="btn btn-sm btn-soft-warning edit-list"
                    /*onClick={() => getteamdetails(site_id)}*/
                    onClick={() => {
                      handleclick(site_id);
                    }}
                  >
                    <i
                      className="bx bxs-pencil fs-12 pt-1"
                      id="edittooltip"
                    ></i>
                  </button>
                  <UncontrolledTooltip placement="top" target="edittooltip">
                    Edit
                  </UncontrolledTooltip>
                </>
              )}
              <button
                className="btn btn-sm btn-soft-danger copy-list"
                onClick={() => {
                  setOpenWorkforceId(site_id);
                  setUserProfile(profilePic);
                  tog_assignModal();
                }}
              >
                <i
                  className="bx bxs-archive-in fs-12 pt-1"
                  id="deletetooltip"
                ></i>
              </button>
              <UncontrolledTooltip placement="top" target="deletetooltip">
                Archive
              </UncontrolledTooltip>
              {cellProps.row.original.id !== authUserId && (
                <button
                  className="btn btn-sm btn-soft-info review-list"
                  onClick={() => {
                    handleShow(site_id);
                  }}
                >
                  <i
                    className="bx bxs-star-half fs-12 pt-1"
                    id="ratingTooltip"
                  ></i>
                </button>
              )}

              <UncontrolledTooltip placement="top" target="ratingTooltip">
                Rate & Review
              </UncontrolledTooltip>
            </div>
            //       <div className="d-flex gap-3">
            //   <button
            //     className="btn btn-sm btn-soft-success copy-list"
            //     onClick={() => {
            //       setOpenWorkforceId(site_id);
            //       toggleTeamDetail();
            //     }}
            //   >
            //     <i className="bx bx-show-alt fs-12 pt-1" id="copytooltip"></i>
            //   </button>
            //   <UncontrolledTooltip placement="top" target="copytooltip">
            //     View
            //   </UncontrolledTooltip>

            //   {all_permissions?.includes("edit_workforce_member") && (
            //     <>
            //       <button
            //         className="btn btn-sm btn-soft-warning edit-list"
            //         onClick={() => handleclick(site_id)}
            //       >
            //         <i className="bx bxs-pencil fs-12 pt-1" id="edittooltip"></i>
            //       </button>
            //       <UncontrolledTooltip placement="top" target="edittooltip">
            //         Edit
            //       </UncontrolledTooltip>
            //     </>
            //   )}

            //   <button
            //     className="btn btn-sm btn-soft-danger copy-list"
            //     onClick={() => {
            //       setOpenWorkforceId(site_id);
            //       setUserProfile(profilePic);
            //       tog_assignModal();
            //     }}
            //   >
            //     <i className="bx bxs-archive-in fs-12 pt-1" id="deletetooltip"></i>
            //   </button>
            //   <UncontrolledTooltip placement="top" target="deletetooltip">
            //     Archive
            //   </UncontrolledTooltip>
            //   <img
            //         src={imgrating}
            //         alt="Rating"
            //         id="ratingTooltip"  // Set an ID to associate with the tooltip
            //         style={{
            //           cursor: 'pointer',
            //           width: '30px',    // Set the width to match the icons
            //           padding: '0',     // Ensure no extra padding
            //           margin: '0',      // Ensure no extra margin
            //           objectFit: 'contain', // Ensure the image scales correctly
            //         }}
            //         // onClick={handleShow}
            //       />
            //       <UncontrolledTooltip placement="top" target="ratingTooltip">
            //         Rate & Review
            //       </UncontrolledTooltip>
            // </div>
          );
        },
      },
    ],
    []
  );

  const manageArchiveColumn = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "full_name",
        Cell: ({ row }) => (
          <div
            onClick={() => {
              // handleRowClick(row.original)
            }}
            // style={{ color: '#ff0599', cursor: 'pointer' }}
          >
            {row.original.full_name}
          </div>
        ),
        filterable: true,
      },
      {
        Header: "Sites",
        accessor: "site_names",
        accessor: (row) => {
          const sites =
            row.site_names !== null &&
            row.site_names !== undefined &&
            row.site_names !== ""
              ? `${row.site_names}`
              : "-";
          return <span>{sites}</span>;
        },
        filterable: false,
      },
      {
        Header: "Work Title",
        accessor: "work_title",
        accessor: (row) => {
          const workTitle =
            row.work_title !== null &&
            row.work_title !== undefined &&
            row.work_title !== ""
              ? `${row.work_title}`
              : "-";
          return <span>{workTitle}</span>;
        },
        filterable: false,
      },
      {
        Header: "Contact",
        // accessor: "mobile",
        accessor: (row) => {
          console.log(row.mobile, "row.mobile");
          const mobile =
            row.mobile !== null && row.mobile !== "null"
              ? `${row.mobile}`
              : "-";
          return mobile;
        },
        filterable: false,
      },

      {
        Header: "Weekday",
        // accessor: "pay_rate_week_day",
        accessor: (row) => {
          const weekDay =
            row.pay_rate_week_day !== null
              ? `$${row.pay_rate_week_day}`
              : "$00.00";
          return weekDay;
        },
        filterable: true,
      },
      {
        Header: "Saturday",
        // accessor: "pay_rate_saturday",
        accessor: (row) => {
          const saturday =
            row.pay_rate_saturday !== null
              ? `$${row.pay_rate_saturday}`
              : "$00.00";
          return saturday;
        },
        filterable: true,
      },
      {
        Header: "Sunday",
        // accessor: "pay_rate_sunday",
        accessor: (row) => {
          const sunday =
            row.pay_rate_sunday !== null ? `$${row.pay_rate_sunday}` : "$00.00";
          return sunday;
        },
        filterable: true,
      },
      {
        Header: "Public Holiday",
        // accessor: "pay_rate_public_holiday",
        accessor: (row) => {
          const publicHoliday =
            row.pay_rate_public_holiday !== null
              ? `$${row.pay_rate_public_holiday}`
              : "$00.00";
          return publicHoliday;
        },
        filterable: true,
      },
      {
        Header: "Action",
        Cell: (cellProps) => {
          const site_id = cellProps.row.original.id;
          const statusName = cellProps.row.original.status_name;
          const profilePic = cellProps.row.original.initial.s3Url;
          return (
            <div className="d-flex gap-3">
              <button
                className="btn btn-sm btn-soft-success copy-list"
                onClick={() => {
                  setOpenWorkforceId(site_id);
                  toggleTeamDetail();
                }}
              >
                <i className="bx bx-show-alt fs-12 pt-1" id="copytooltip"></i>
              </button>
              <UncontrolledTooltip placement="top" target="copytooltip">
                View
              </UncontrolledTooltip>
              {/*{all_permissions?.includes("edit_workforce_member") && (
                <>
                  <button
                    className="btn btn-sm btn-soft-warning edit-list"
                    onClick={() => getteamdetails(site_id)}
                  >
                    <i
                      className="bx bxs-pencil fs-12 pt-1"
                      id="edittooltip"
                    ></i>
                  </button>
                  <UncontrolledTooltip placement="top" target="edittooltip">
                    Edit
                  </UncontrolledTooltip>
                </>
              )}*/}
              <button
                className="btn btn-sm btn-soft-danger copy-list"
                onClick={() => {
                  setOpenWorkforceId(site_id);
                  setUserProfile(profilePic);
                  tog_assignModalArchive();
                }}
              >
                <i
                  className="bx bxs-archive-out fs-12 pt-1"
                  id="viewtooltip"
                ></i>
              </button>
              <UncontrolledTooltip placement="top" target="viewtooltip">
                Unarchive
              </UncontrolledTooltip>
            </div>
          );
        },
      },
    ],
    []
  );

  // const getAddressBySite = (selectedSite) => {
  //   const site = sitelist?.find((site) => site.site_name === selectedSite);
  //   return site ? `${site.address}, ${site.suburb}, ${site.postcode}` : "";
  // };

  const getAddressBySite = (selectedSite) => {
    const site = sitelist?.find((site) => site.site_name === selectedSite);
    if (!site) return "";

    const addressParts = [site.address, site.suburb, site.postcode].filter(
      (part) => part
    );
    return addressParts.join(", ");
  };

  const getTimezoneBySite = (selectedSite) => {
    const site = sitelist?.find((site) => site.site_name === selectedSite);
    return site ? site.timezone : "";
  };

  const searchList = (inputVal) => {
    setisLoading(true);
    const formData = {
      search: inputVal,
      user_id: authUserId,
      business_id: business_id,
    };
    if (inputVal?.trim() !== "") {
      axios.post("/filter-active-team", formData).then((res) => {
        setData(res);
        setIsQuery(true);
        setisLoading(false);
      });
    } else {
      setIsQuery(false);
      setisLoading(false);
      loadTable(authUserId);
    }
  };
  const addToArchive = () => {
    const formData = {
      user_id: authUserId,
      business_id: business_id,
      workforce_profile_id: openWorkforceId,
    };
    axios
      .post("/archive-member", formData)
      .then((res) => {
        console.log(res);
        if (res.success === true) {
          toast.success(res.message);
          setmodal_assignModal(false);
          loadTable(authUserId);
        } else {
          toast.error(res.message);
          setmodal_assignModal(true);
        }
      })
      .then((err) => {
        console.log(err);
      });
  };
  const unarchiveMember = () => {
    const formData = {
      user_id: authUserId,
      business_id: business_id,
      workforce_profile_id: openWorkforceId,
    };
    axios
      .post("/unarchive-team-member", formData)
      .then((res) => {
        console.log(res);
        if (res.success === true) {
          toast.success(res.message);
          setmodal_assignModalArchive(false);
          loadTable(authUserId);
        } else {
          toast.error(res.message);
          setmodal_assignModalArchive(true);
        }
      })
      .then((err) => {
        console.log(err);
      });
  };


  const handleSyncWithXero = async () => {
    try {

      const formData = {
        business_id: business_id,
        Paycalendar:selectedCalendar.value,
        income_type:selectedIncomeType.value,
        earning_rate:selectedEarningRate.value,
      };

      const response = await axios.post("/sync-employees",formData);
      if (response.status == 200) {
        // Handle success, maybe show a toast or update UI
        toast.success("Employees synced with Xero successfully!");
      } else {
        // Handle any other responses
        toast.danger("Failed to sync employees with Xero.");
      }
    } catch (error) {
      // Handle error
      console.error("Error syncing with Xero:", error);
      toast.danger("Error syncing employees with Xero.");
    }
  };




      // Handle calendar selection
      const handleCalendarChange = (selectedOption) => {

        setSelectedCalendar(selectedOption);
      };
        // Handle calendar selection
      const handleearningrate = (selectedOption) => {
          setSelectedEarningRate(selectedOption);
        };

            // Handle calendar selection
      const handleincometype = (selectedOption) => {
              setSelectedIncomeType(selectedOption);
        };


  // Review Modal
  const handleShow = (workforce_profile_id) => {
    setisLoadingReview(true);
    setShow(true);
    console.log(workforce_profile_id, "WORKFORCEPROFILEID");
    const formData = {
      user_id: user_id,
      business_id: business_id,
      worforce_id: workforce_profile_id,
      business_id: business_id,
    };
    axios
      .post("/get-review-datas", formData)
      .then((response) => {
        setUserProfiles(response.workforce_pic);
        setWorkerName(
          response.workforce_data.first_name +
            " " +
            response.workforce_data.last_name
        );
        setWorkerId(response.workforce_data.id);
        if (response.data) {
          setReviewId(response.data.id);
          setRating(response.data.rating);
          setReview(response.data.review);
        } else {
          setReviewId(null);
          setRating(0);
          setReview("");
        }
        console.log(
          response.workforce_data.rating,
          "response.workforce_data.rating"
        );
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setisLoadingReview(false);
      });
  };
  const handleClose = () => {
    setShow(false);
    setUserProfiles(null);
    setRating(0);
    setReview("");
    setWorkerName("");
  };

  const handleRatingChange = (value) => {
    setRating(value);
  };
  const handleReviewChange = (event) => {
    setReview(event.target.value);
  };

  const handleSubmitReview = () => {
    setReviewSpinner(true);
    const formData = {
      user_id: user_id,
      worforce_id: workerId,
      business_id: business_id,
      rating: rating,
      review: review,
      review_id: reviewId ? reviewId : null,
    };
    axios
      .post("/save-review-data", formData)
      .then((res) => {
        if (res.success === true) {
          toast.success(res.message);
          setShow(false);
          loadTable(authUserId);
          setUserProfiles(null);
          setRating(0);
          setReview("");
          setWorkerName("");
        } else {
          toast.error(res.message);
          setShow(true);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setReviewSpinner(false);
      });
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Manage Team" />


          <Row className="mt-3">
    
  </Row>

<Row>

  <Col md={3}>
  <Nav pills className="nav nav-pills custom-hover-nav-tabs mt-3">
        
            <NavItem
              style={{
                border: "1px solid #865ce2",
                borderTopLeftRadius: "7px",
                borderBottomLeftRadius: "7px",
              }}
            >
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({
                  active: customHoverTab === "1",
                })}
                onClick={() => {
                  customHovertoggle("1");
                }}
              >
                <i className="bx bx-bolt-circle nav-icon nav-tab-position"></i>
                <h5 className="nav-titl nav-tab-position m-0">
                  Active ({activeCount})
                </h5>
              </NavLink>
            </NavItem>
            <NavItem
              style={{
                border: "1px solid #865ce2",
                borderTopRightRadius: "7px",
                borderBottomRightRadius: "7px",
                borderLeft: "none",
              }}
            >
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({
                  active: customHoverTab === "2",
                })}
                onClick={() => {
                  customHovertoggle("2");
                }}
              >
                <i className="bx bx-archive nav-icon nav-tab-position m-0"></i>
                <h5 className="nav-titl nav-tab-position">
                  Archived ({archiveCount})
                </h5>
              </NavLink>
            </NavItem>
          </Nav>
  </Col>

  <Col md={9}>
  <Col className="d-flex justify-content-end mt-3">
      <Button color="primary" onClick={togglexeromodal}>
        Sync with Xero
      </Button>
    </Col>
  </Col>

</Row>


<div>

<Modal
  isOpen={xeromodal}
  toggle={togglexeromodal}
  style={{ minWidth: "50%", borderLeft: 0,borderRadius:10 }}
  centered
  scrollable={true}
  size="md"
  modalClassName="zoomIn"
  id="offcanvasExample"
>
<ModalHeader className="text-start">
  <h4 className="w-100 d-flex align-items-center">
    Add Employees to Xero  
    <i className="bx bx-info-circle mt-1" style={{ marginLeft: '8px', color: '#865ce2' }} />
    <span style={{ marginLeft: '8px', fontSize: '14px', color: 'grey',marginTop:5 }}>
      These employees are added on the basis of employee type
    </span>
  </h4>
</ModalHeader>

  
  <ModalBody
    className="grey-overall-bg p-4"
    style={{ overflowY: "auto", overflowX: "hidden" }} // Adjust overflow settings
  >
    {businessApiDetailsExists && (
      <div className="row g-4 mb-4">
        <div className="col-md-4 d-flex flex-column align-items-center">
          <h6 className="text-center mb-3" style={{ fontSize: "16px", fontWeight: "bold" }}>Select Calendar</h6>
          <Select
            options={payrollCalendars}
            value={selectedCalendar}
            onChange={handleCalendarChange}
            placeholder="Select a payroll calendar"
            className="custom-select"
            menuPortalTarget={document.body} // Ensure dropdown renders outside the modal
            styles={{
              menuPortal: (base) => ({ ...base, zIndex: 9999 }), // Ensure the dropdown is above the modal
              control: (base) => ({
                ...base,
            
                borderRadius: '10px', // Set border radius
                border: 'none', // Remove default border
                boxShadow: 'none', // Remove shadow for a flat look
              }),
              menu: (base) => ({
                ...base,
                borderRadius: '10px', // Apply border radius to the dropdown menu
                overflow: 'hidden', // Ensure no overflow
              }),
              option: (provided, state) => ({
                ...provided,
                backgroundColor: state.isFocused ? '#a285e0' : 'white', // Highlight on focus
                color: 'black', // Text color
              }),
            }} 
          />
        </div>
        <div className="col-md-4 d-flex flex-column align-items-center">
          <h6 className="text-center mb-3" style={{ fontSize: "16px", fontWeight: "bold" }}>Select Pay Rate</h6>
          <Select
            options={payrate}
            value={selectedEarningRate}
            onChange={handleearningrate}
            placeholder="Select an earning rate"
            className="custom-select"
            menuPortalTarget={document.body} // Ensure dropdown renders outside the modal
            styles={{
              menuPortal: (base) => ({ ...base, zIndex: 9999 }), // Ensure the dropdown is above the modal
              control: (base) => ({
                ...base,
              
                borderRadius: '10px', // Set border radius
                border: 'none', // Remove default border
                boxShadow: 'none', // Remove shadow for a flat look
              }),
              menu: (base) => ({
                ...base,
                borderRadius: '10px', // Apply border radius to the dropdown menu
                overflow: 'hidden', // Ensure no overflow
              }),
              option: (provided, state) => ({
                ...provided,
                backgroundColor: state.isFocused ? '#a285e0' : 'white', // Highlight on focus
                color: 'black', // Text color
              }),
            }} 
          />
        </div>
        <div className="col-md-4 d-flex flex-column align-items-center">
          <h6 className="text-center mb-3" style={{ fontSize: "16px", fontWeight: "bold" }}>Select Income Type</h6>
          <Select
            options={incometype}
            value={selectedIncomeType}
            onChange={handleincometype}
            placeholder="Select an income type"
            className="custom-select"
            menuPortalTarget={document.body} // Ensure dropdown renders outside the modal
            styles={{
              menuPortal: (base) => ({ ...base, zIndex: 9999 }), // Ensure the dropdown is above the modal
              control: (base) => ({
                ...base,
              
                borderRadius: '10px', // Set border radius
                border: 'none', // Remove default border
                boxShadow: 'none', // Remove shadow for a flat look
              }),
              menu: (base) => ({
                ...base,
                borderRadius: '10px', // Apply border radius to the dropdown menu
                overflow: 'hidden', // Ensure no overflow
              }),
              option: (provided, state) => ({
                ...provided,
                backgroundColor: state.isFocused ? '#a285e0' : 'white', // Highlight on focus
                color: 'black', // Text color
              }),
            }} 
          />
        </div>
      </div>
    )}

    <Row className="d-flex justify-content-end">
      <Col xs="auto">
        <Button color="primary" onClick={handleSyncWithXero} className="px-5 py-2">
          Sync with Xero
        </Button>
      </Col>
    </Row>
  </ModalBody>
</Modal>



</div>

          
          
          <Row className="mt-3">
            <Col lg={12}>
              <TabContent
                activeTab={customHoverTab}
                className="text-muted mt-0"
              >
                <TabPane tabId="1" id="custom-hover-customere">
                  <Card>
                    <CardBody className="pt-0">
                      <div>
                        {!isLoading ? (
                          <>
                            {data.length ? (
                              <TableContainer
                                columns={manageJobColumn}
                                data={data || []}
                                isGlobalFilter={true}
                                isAddUserList={false}
                                customPageSize={100}
                                className="custom-header-css"
                                divClass="table-responsive table-card mb-3"
                                tableClass="align-middle table-nowrap"
                                theadClass="table-light"
                                handleContactClick={handleContactClicks}
                                isContactsFilter={true}
                                SearchPlaceholder="Search by name and contact number "
                              />
                            ) : (
                              data.length === 0 && (
                                <p
                                  style={{
                                    textAlign: "center",
                                    marginTop: "10px",
                                  }}
                                >
                                  No records found
                                </p>
                              )
                            )}
                          </>
                        ) : (
                          <CardBody className="text-center">
                            <Spinner color="primary"> Loading... </Spinner>
                          </CardBody>
                        )}
                      </div>
                      <ToastContainer closeButton={false} limit={1} />
                    </CardBody>
                  </Card>
                </TabPane>
                <TabPane tabId="2" id="custom-hover-customere">
                  <Card>
                    <CardBody className="pt-0">
                      <div>
                        {!isLoading ? (
                          <>
                            {archive.length ? (
                              <TableContainer
                                columns={manageArchiveColumn}
                                data={archive || []}
                                isGlobalFilter={true}
                                isAddUserList={false}
                                customPageSize={100}
                                className="custom-header-css"
                                divClass="table-responsive table-card mb-3"
                                tableClass="align-middle table-nowrap"
                                theadClass="table-light"
                                handleContactClick={handleContactClicks}
                                isContactsFilter={true}
                                SearchPlaceholder="Search by name and contact number "
                              />
                            ) : (
                              archive.length === 0 && (
                                <p
                                  style={{
                                    textAlign: "center",
                                    marginTop: "10px",
                                  }}
                                >
                                  No records found
                                </p>
                              )
                            )}
                          </>
                        ) : (
                          <CardBody className="text-center">
                            <Spinner color="primary"> Loading... </Spinner>
                          </CardBody>
                        )}
                      </div>
                      <ToastContainer closeButton={false} limit={1} />
                    </CardBody>
                  </Card>
                </TabPane>
              </TabContent>
              <Modal
                isOpen={openTeamDetail}
                toggle={toggleTeamDetail}
                style={{ minWidth: "50%", borderLeft: 0 }}
                centered
                scrollable={true}
                size="lg"
                modalClassName="zoomIn"
                id="offcanvasExample"
              >
                <ModalBody
                  className="grey-overall-bg p-0"
                  style={{ overflowX: "hidden" }}
                >
                  <ManageTeamDetail
                    UserId={authUserId}
                    workforceId={openWorkforceId}
                    toggle={toggleTeamDetail}
                  />
                </ModalBody>                                                                                         
              </Modal>
            </Col>
          </Row>
          <SiteDetailPopup
            showPopup={showPopup}
            profileId={profileId}
            handleclick={() => handleclick()}
            selectedIncomeType={selectedIncomeType}
            setSelectedIncomeType={setSelectedIncomeType}
            selectedCalendar={selectedCalendar}
            setSelectedCalendar={setSelectedCalendar}
            selectedEarningRate={selectedEarningRate}
            employeeType={employee_type}
            setSelectedEarningRate={setSelectedEarningRate}
            setEmployeeType={setEmployeeType}
          />
          <Modal
            size="md"
            isOpen={modal_assignModal}
            toggle={() => {
              tog_assignModal();
            }}
            centered
          >
            <ModalHeader toggle={tog_assignModal}></ModalHeader>
            <ModalBody className="text-center">
              <Row>
                <Col lg={4}>
                  {/*<img
                            src={userDummy} 
                            alt="user-img"
                            style={{ height: "9rem", width: "9rem" }}
                            className=" img-thumbnail avatar-xxs rounded-circle"
                          />*/}
                  {userProfile ? (
                    <img
                      src={`${userProfile}`}
                      alt="user-img"
                      style={{ height: "9rem", width: "9rem" }}
                      className=" img-thumbnail avatar-xxs rounded-circle"
                    />
                  ) : (
                    <img
                      src={userDummy}
                      alt="user-img"
                      style={{ height: "9rem", width: "9rem" }}
                      className=" img-thumbnail avatar-xxs rounded-circle"
                    />
                  )}
                </Col>
                <Col lg={8}>
                  {/* fghfh */}
                  <div className="confirmationText">
                    <h5 className="text-left">
                      Please confirm to archive this team member
                    </h5>
                    <p className="subText">Confirm to proceed</p>
                    <div className="d-flex flex-wrap gap-2">
                      <Button
                        className="w-lg cancelButton"
                        color="primary"
                        // outline
                        onClick={tog_assignModal}
                        // className="btn btn-talent btn-sm mt-auto"
                      >
                        Cancel
                      </Button>
                      <Button
                        className="w-lg confirmButton"
                        color="primary"
                        // style={styles.confirmButton}
                        onClick={addToArchive}
                      >
                        Confirm
                      </Button>
                    </div>
                  </div>
                </Col>
              </Row>
            </ModalBody>
          </Modal>
          <Modal
            size="md"
            isOpen={modal_assignModalArchive}
            toggle={() => {
              tog_assignModalArchive();
            }}
            centered
          >
            <ModalHeader toggle={tog_assignModalArchive}></ModalHeader>
            <ModalBody className="text-center">
              <Row>
                <Col lg={4}>
                  {/*<img
                            src={userDummy}
                            alt="user-img"
                            style={{ height: "9rem", width: "9rem" }}
                            className=" img-thumbnail avatar-xxs rounded-circle"
                          />*/}
                  {userProfile ? (
                    <img
                      src={`${userProfile}`}
                      alt="user-img"
                      style={{ height: "9rem", width: "9rem" }}
                      className=" img-thumbnail avatar-xxs rounded-circle"
                    />
                  ) : (
                    <img
                      src={userDummy}
                      alt="user-img"
                      style={{ height: "9rem", width: "9rem" }}
                      className=" img-thumbnail avatar-xxs rounded-circle"
                    />
                  )}
                </Col>
                <Col lg={8}>
                  {/* fghfh */}
                  <div className="confirmationText">
                    <h5 className="text-left">
                      Please confirm to unarchive this team member
                    </h5>
                    <p className="subText">Confirm to proceed</p>
                    <div className="d-flex flex-wrap gap-2">
                      <Button
                        className="w-lg cancelButton"
                        color="primary"
                        // outline
                        onClick={tog_assignModalArchive}
                        // className="btn btn-talent btn-sm mt-auto"
                      >
                        Cancel
                      </Button>
                      <Button
                        className="w-lg confirmButton"
                        color="primary"
                        // style={styles.confirmButton}
                        onClick={unarchiveMember}
                      >
                        Confirm
                      </Button>
                    </div>
                  </div>
                </Col>
              </Row>
            </ModalBody>
          </Modal>
          <Modal size="lg" isOpen={show} centered>
            <ModalBody>
              <Row>
                <Col md={6} className="d-flex flex-column align-items-center">
                  {userProfiles ? (
                    <img
                      src={`${userProfiles}`}
                      alt="user-img"
                      style={{
                        height: "7rem",
                        width: "7rem",
                      }}
                      className="img-thumbnail avatar-xxs rounded-circle"
                    />
                  ) : (
                    <img
                      src={userDummy}
                      alt="user-img"
                      style={{
                        height: "7rem",
                        width: "7rem",
                      }}
                      className="img-thumbnail avatar-xxs rounded-circle"
                    />
                  )}
                  <h4 style={{ color: "#865ce2", marginTop: "1rem" }}>
                    {workerName}
                  </h4>

                  {/* Align label and rating in a single row */}
                  <div className="d-flex align-items-center justify-content-center">
                    <Rating
                      className="mb-2"
                      initialRating={rating}
                      onChange={handleRatingChange}
                      emptySymbol="mdi mdi-star-outline text-muted"
                      fullSymbol="mdi mdi-star text-warning"
                    />
                  </div>
                </Col>

                <Col md={6} className="mt-1">
                  <Input
                    type="textarea"
                    id="reviewTextarea"
                    rows="8" // Sets the number of visible text lines
                    placeholder="Write your review here..."
                    value={review} // Set value to state
                    onChange={handleReviewChange} // Attach handler function
                  />
                </Col>
              </Row>
              <Row className="mt-3">
                <Col className="d-flex justify-content-center">
                  <Button
                    className="me-2 w-lg cancelButton"
                    color="primary"
                    onClick={() => {
                      handleClose();
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="w-lg confirmButton text-white"
                    color="primary"
                    onClick={() => {
                      handleSubmitReview();
                    }}
                    disabled={reviewSpinner}
                  >
                    {reviewSpinner ? (
                      <>
                        <Spinner size="sm" className="me-2" />
                        Submitting...
                      </>
                    ) : (
                      "Submit Review"
                    )}
                  </Button>
                </Col>
              </Row>
              {isLoadingReview && (
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    background: "rgba(255, 255, 255, 0.8)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    zIndex: 9999,
                  }}
                >
                  <Loader />
                </div>
              )}
            </ModalBody>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default ManageTeam;
