import React, { useEffect, useState } from "react";
import axios from "axios";
import Select from "react-select";
import { get_cookie } from "../../../helpers/get_cookie";
import { toast } from 'react-toastify';
import { CardHeader, CardBody, Card } from "reactstrap";
import xeroimage from '../../../assets/images/Xero.png'
import xerologo from '../../../assets/images/xerologo.png'


export const Xero = () => {
  const [authUserId, setAuthUserId] = useState();
  const [businessApiDetailsExists, setBusinessApiDetailsExists] = useState(false); // To check if business API details exist
  const [organizations, setOrganizations] = useState([]);
  const [selectedOrganization, setSelectedOrganization] = useState(null);
  const [isConnected, setIsConnected] = useState(false); // Track if any organization is connected
  const businessId = JSON.parse(get_cookie("authUser")).business_id;
  const [payrollCalendars, setPayrollCalendars] = useState([]);
  const [selectedCalendar, setSelectedCalendar] = useState(null);

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderColor: provided.borderColor,
      "&:hover": {
        borderColor:  provided.borderColor,
      },
      minHeight: "35px",
      border: "none",
      borderRadius: "30px",
      boxShadow: state.isFocused
         ? "0 0 0 1px #7F4BDA"
        :  "0 0 0 1px #7F4BDA",
      padding: "5px 10px",
      
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#7F4BDA" : provided.backgroundColor,
      color: state.isSelected ? "white" : provided.color,
      "&:hover": {
        backgroundColor: "#e8e6f7",
        color: "black",
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "black",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      paddingRight: "10px",
      padding: 0,
      color: "#7F4BDA", // Set your desired color here
      "&:hover": {
        color: "#7527CE", // Optional: change color on hover
      },
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
  };


  // Check if business ID exists in business_api_details
  const checkBusinessApiDetails = () => {
    axios
      .post("/check-business-api-details", { business_id: businessId })
      .then((res) => {
        setBusinessApiDetailsExists(res.exists); // Set true if business API details exist
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Call the check function on component load
  useEffect(() => {
    checkBusinessApiDetails();
  }, [businessId]);
  // Fetch user details (authUserId)


  
  useEffect(() => {
    axios
      .get("/get-user-details")
      .then((res) => {
        setAuthUserId(res.id); // Ensure you access the correct property
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // Fetch organizations
  useEffect(() => {
    if (businessApiDetailsExists) {
      axios
        .post("/get-connections", { business_id: businessId })
        .then((response) => {
          // toast.success("Organizations retrieved successfully!", { theme: "light" });
          setOrganizations(response.data); // Set organizations data
  
          // Set the first connection as selected if connected
          const firstConnection = response.data.find(org => org.tenantId === response.first_connection.tenant_id);
          if (firstConnection) {
            setIsConnected(true);
            setSelectedOrganization({
              value: firstConnection.tenantId,
              label: firstConnection.tenantName,
            });
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [businessApiDetailsExists]);
  

  // Handle organization selection change
  const handleOrganizationChange = (selectedOption) => {
    setSelectedOrganization(selectedOption);
  };



  const fetchPayrollCalendars = () => {
    axios
      .post('/getpayroll-calendars', { business_id: businessId })
      .then((res) => {
        if (res.status === 'success') {
          setPayrollCalendars(res.data); // Set the calendar data
        } else {
          console.error(res.message);
        }
      })
      .catch((err) => {
        console.error('Error fetching payroll calendars:', err);
      });
  };

  useEffect(() => {
    fetchPayrollCalendars();
  }, [businessApiDetailsExists]);



    // Handle calendar selection
    const handleCalendarChange = (selectedOption) => {
      setSelectedCalendar(selectedOption);
    };

  // Handle connect click
  const handleConnectClick = () => {
    axios
      .get(`/connect-xero?business_id=${businessId}`) // Pass the business_id in the query string
      .then((response) => {
        const { url } = response;
        if (url) {
          window.location.href = url; // Redirect to Xero authorization URL
        } else {
          toast.error("Failed to get authorization URL.", { theme: "light" });
        }
      })
      .catch((error) => {
        console.error(error);
        toast.error("Failed to initiate Xero connection.", { theme: "light" });
      });
  };

  // Handle update connection status
  const handleUpdateConnection = () => {
    if (!selectedOrganization) {
      toast.error("Please select an organization to update.", { theme: "light" });
      return;
    }

    const tenantId = selectedOrganization.value; // Get tenantId from selected option

    axios
      .post("/update-organisation", { business_id: businessId, tenantId, connected: 'Y' })
      .then((response) => {
        toast.success("Connection status updated successfully.", { theme: "light" });
        setIsConnected(true); // Set connected to true after a successful connection
      })
      .catch((error) => {
        console.error(error);
        toast.error("Failed to update connection status.", { theme: "light" });
      });
  };

  // Handle disconnect click
  const handleDisconnectClick = () => {
    if (!selectedOrganization) {
      toast.error("Please select an organization to disconnect.", { theme: "light" });
      return;
    }

    const tenantId = selectedOrganization.value;

    axios
      .post("/disconnect-organisation", { business_id: businessId, tenantId, connected: 'N' })
      .then((response) => {
        toast.success("Disconnected successfully.", { theme: "light" });
        setIsConnected(false); // Allow re-selection after disconnection
        setSelectedOrganization(null); // Reset the selected organization
      })
      .catch((error) => {
        console.error(error);
        toast.error("Failed to disconnect.", { theme: "light" });
      });
  };

  // Disconnect Xero
  const DisconnectXero = () => {
    axios
      .post(`/disconnect-xero?business_id=${businessId}`) // Pass the business_id in the query string
      .then((response) => {
        console.log(response);
        setIsConnected(false); // Update connection status after disconnection
        setSelectedOrganization(null); // Reset selected organization
        checkBusinessApiDetails();
        
      })
      .catch((error) => {
        console.error(error);
        toast.error("Failed to initiate Xero disconnection.", { theme: "light" });
      });
  };

  

  const organizationOptions = organizations.map((org) => ({
    value: org.tenantId,
    label: org.tenantName	,
  }));

  // Conditional rendering based on business API details existence
  return (
    <div>
      {businessApiDetailsExists ? (
        // Xero Callback content
        <Card>
          <CardHeader className="d-flex justify-content-between align-items-center">
            <h5 className="mb-0 text-primary">Connected to Xero</h5>
            <button
              className="btn btn-outline-danger"
              onClick={DisconnectXero} // Add your disconnect function here
            >
              Disconnect Xero
            </button>
          </CardHeader>
          <CardBody className="ps-4">
          
          <div className="text-center">
              <img src={xeroimage} alt="Connect to Xero" className="img-fluid mb-0" style={{ maxWidth: '500px' }} />
            </div>
            <hr className="calendar-hr"></hr>
            <div>
              <h5>Account Configurations</h5>
            </div>
            <div >
                <button
                  className={`btn btn-${!isConnected ? 'secondary' : 'success'} mt-3`}
                  onClick={handleDisconnectClick}
                  disabled={!isConnected} // Enable only if an organization is connected
                >
                  Change Connection
                </button>
              </div>

            <div className="hstack gap-3 align-items-center mb-3  mt-3">
              
              <Select
              options={organizationOptions}
              onChange={handleOrganizationChange}
              placeholder="Select Organization"
              value={selectedOrganization}
              isDisabled={isConnected} // Disable when an organization is connected
              styles={customStyles}
            />

             
                <button
                  className={`btn btn-${isConnected ? 'secondary' : 'primary'} `}
                  onClick={handleUpdateConnection}
                  disabled={isConnected} // Disable when an organization is connected
                >
                  {isConnected ? 'Connected' : 'Update Connection Status'}
                </button>

            </div>
            <hr className="calendar-hr mt-3"></hr>
          <div>
          <p className="text-start  mt-1 text-muted ps-0 pt-1">
            Select the organization from the dropdown. If needed, change the connection and then continue with the following steps in Xero:
          </p>
          <ul className="text-start text-muted">
            <li>1. Go to Payroll Settings.</li>
            <li>2. Go to Frequencies.</li>
            <li>3. Add or edit the payroll calendar.</li>
            <li>4. Then go to Talentpool to add the employees in Xero.</li>
          </ul>
        </div>
          </CardBody>
        </Card>
      ) : (
        // Xero Connect content
        <Card>
          <CardHeader className="d-flex justify-content-between align-items-center text-start">
            <h5 className="mb-0 text-primary">Connect Xero</h5>
          </CardHeader>
          <CardBody>
            <div className="text-center">
              <img src={xeroimage} alt="Connect to Xero" className="img-fluid mb-4" style={{ maxWidth: '500px' }} />
            </div>
            <p className="text-start p-1 ms-3 text-muted">
              Connect XERO, and Gentrostered will sync Employees timesheets to your XERO account.
              When you click connect, a XERO window will open. Follow these quick steps:
            </p>
            <ul className="text-start text-muted">
              <li>1. Log in to your XERO account.</li>
              <li>2. Select the organization you want to sync with Onexfort.</li>
              <li>3. Authorize the connection to Gentrostered.</li>
              <li>4. Complete XERO account configuration.</li>
            </ul>
            <div className="text-start ms-3 ">
              <button 
                className="btn btn-light mt-4 d-flex align-items-center px-3"
                style={{
                  border: '1px solid #ced4da', // Adding border
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // Adding shadow
                  borderRadius: '5px' // Optional: Slightly rounded corners
                }} 
                onClick={handleConnectClick} 
                disabled={isConnected}
              >
                <img src={xerologo} alt="Xero logo" style={{ width: '40px', marginRight: '10px', borderRadius: '30px' }} />
                {isConnected ? 'Connected with Xero' : 'Connect with Xero'}
              </button>
            </div>
          </CardBody>
        </Card>
      )}
    </div>
  );
};
