import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import axios from "axios";
import { api } from "../../config";
import img13 from "../../assets/images/small/userimage.png";
import * as PusherPushNotifications from "@pusher/push-notifications-web";
import { beampusher } from "../../config";

//import images
import avatar1 from "../../assets/images/users/avatar-1.jpg";

const ProfileDropdown = () => {
  const { user } = useSelector((state) => ({
    user: state.Profile.user,
  }));

  const [userName, setUserName] = useState("Admin");
  const [profileImage, setProfileImage] = useState("");

  useEffect(() => {
    if (sessionStorage.getItem("authUser")) {
      const obj = JSON.parse(sessionStorage.getItem("authUser"));
      setUserName(
        process.env.REACT_APP_DEFAULTAUTH === "fake"
          ? obj.username === undefined
            ? user.first_name
              ? user.first_name
              : obj.data.first_name
            : "Admin" || "Admin"
          : process.env.REACT_APP_DEFAULTAUTH === "firebase"
          ? obj.providerData[0].email
          : "Admin"
      );
    }
  }, [userName, user]);
  useEffect(() => {
    axios
      .get("/get-user-details")
      .then((res) => {
        console.log(res.profile_picture_url, "profile_picture_url123");
        setUserName(res.name);
        setProfileImage(res.profile_picture_url);
      })
      .then((err) => {
        console.log(err);
      });
  }, []);

  //Dropdown Toggle
  const [isProfileDropdown, setIsProfileDropdown] = useState(false);
  const toggleProfileDropdown = () => {
    setIsProfileDropdown(!isProfileDropdown);
  };

  const [gbl, setGbl] = useState("");
  const [employeeDetails, setEmployeeDetails] = useState("");

  // const loadSettings = () => {
  //     axios.get('/profile-settings/data')
  //         .then(response => {
  //             setGbl(response.userdetail);
  //             setEmployeeDetails(response.employeedetail);
  //         })
  //         .catch(error => {
  //             console.log(error);
  //         })
  // }

  //*backend response*
  // useEffect(() => {
  //     loadSettings();
  // }, []);

  // Define your array of colors
  const colors = [
    "#fd99a8", // Plum
    "#FFA07A", // LightSalmon
    "#FFD700", // Gold
    "#90df90", // PaleGreen
    "#8bdddd", // PaleTurquoise
    "#af91e3", // Lavender
    "#f791eb", // LightPink
    "#add8e6", // LightBlue
    "#ffcccb", // LightCoral
    "#ffb6c1", // LightPink
  ];

  // Function to assign a color based on index
  function getColor(index) {
    return colors[index % colors.length];
  }

  const handleLogout = () => {
    // localhost 
    // const beamsClient = new PusherPushNotifications.Client({
    //   instanceId: "d41554a5-9f54-45af-9c6d-1f481b36b0f2",
    // });

    // devbusiness
    if (('serviceWorker' in navigator)) {
    const beamsClient = new PusherPushNotifications.Client({
      instanceId: beampusher.INSTANCE_ID,
    });
    
    // Stop Pusher Beams client
    beamsClient
      .stop()
      .then(() => {
        // Perform additional logout actions if needed
        window.location.href = process.env.PUBLIC_URL + "/logout";
      })
      .catch((error) => {
        console.error("Error stopping beamsClient:", error);
        // Still proceed with logout even if stopping beamsClient fails
        window.location.href = process.env.PUBLIC_URL + "/logout";
      });
    }
  };

  return (
    <React.Fragment>
      <Dropdown
        isOpen={isProfileDropdown}
        toggle={toggleProfileDropdown}
        className="ms-sm-0 header-item topbar-user"
      >
        <DropdownToggle tag="button" type="button" className="btn">
          <span className="d-flex align-items-center">
            {/* {gbl && gbl.image ? (
                            <img
                                className="rounded-circle header-profile-user"
                                src={api.WEB_URL + "user-uploads/avatar/" + gbl.image}
                                alt="Header Avatar"
                                style={{ width: '38px' }}
                            />
                        ) : ( */}
            {profileImage ? (
              // Render the profile image if available
              <img
                src={profileImage}
                alt="Profile"
                style={{ width: "38px", height: "38px", borderRadius: "50%" }}
              />
            ) : (
              // Render initials inside a colored circle if profile image is not available
              <div
                style={{
                  width: "38px",
                  height: "38px",
                  backgroundColor: getColor(userName.length), // Assign color based on username length
                  borderRadius: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "18px",
                  color: "#fff",
                }}
              >
                {
                  userName
                    .split(" ") // Split the name into words
                    .slice(0, 2) // Take only the first two words
                    .map((word) => word.charAt(0).toUpperCase()) // Get the first character of each word
                    .join("") // Concatenate the initials together
                }
              </div>
            )}
            <span style={{ marginLeft: "5px" }}>{userName}</span>
            <span className="text-start ms-xl-2">
              <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">
                {gbl.name}{" "}
                <i
                  className="ri-arrow-down-s-fill"
                  style={{ verticalAlign: "bottom" }}
                ></i>
              </span>
              {/* <span className="d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text">Founder</span> */}
            </span>
          </span>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <h6 className="dropdown-header">Welcome!</h6>

          <DropdownItem href={"/business_settings"}>
            <span className="badge bg-soft-success text-success mt-1 float-end">
              New
            </span>
            <i className="mdi mdi-cog-outline text-muted fs-16 align-middle me-1"></i>
            <span className="align-middle">Settings</span>
          </DropdownItem>

          {/* <DropdownItem href={process.env.PUBLIC_URL + "/logout"}>
            <i className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i>{" "}
            <span className="align-middle" data-key="t-logout">
              Logout
            </span>
          </DropdownItem> */}
          <DropdownItem onClick={handleLogout}>
            <i className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i>{" "}
            <span className="align-middle" data-key="t-logout">
              Logout
            </span>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default ProfileDropdown;
