import React, { useEffect, useState, useCallback, useMemo } from "react";

export const Footer = () => {
  return (
    <div className="d-flex footer-job-share text-muted justify-content-between px-4 align-items-center">
      <div className="text-start">
        &copy; {new Date().getFullYear()} Get Rostered.
      </div>
      <div
        className="text-end align-items-center"
        style={{ cursor: "pointer" }}
      >
        <a
          href="https://getrostered.app/terms-of-use"
          target="_blank"
          rel="noopener noreferrer"
          className="text-primary"
          style={{ cursor: "pointer", marginRight: "10px" , textDecoration:"underline" }}
        >
          Terms & Conditions
        </a>
        <a
          href="https://getrostered.app/privacy-policy"
          target="_blank"
          rel="noopener noreferrer"
          className="text-muted"
          style={{ cursor: "pointer", marginRight: "10px" }}
        >
          {" "}
          Privacy policy
        </a>
      </div>
    </div>
  );
};
